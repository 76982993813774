import React from 'react'

const Blogcard = () => {
    return (
        <>

            <div className="container1">
                <div className="card mt-3">
                    <div className="card-headers">
                    <img src="/blog1 (3).png" alt="rover" />
                    </div>
                    <div className="card-body">

                        <h5 className='text-start fw-bold'>
                            <span style={{ color: "#7F3E98" }}>  Fault <br />
                                Management
                            </span>
                        </h5>
                        <p className='text-start fs-6'>
                            Ensure optimal performance with real-time monitoring, proactive management, and quick issue resolution for network infrastructure.
                        </p>
                        {/* <div className="user">
                            <button className="service-btn" style={{ background: "#7F3E98" }}>Read More</button>
                        </div> */}
                    </div>
                </div>
                <br />
                <div className="card mt-3">
                    <div className="card-headers">
                    
                        <img src="/blog1 (4).png" alt="rover" />
                    </div>
                    <div className="card-body">

                        <h5 className='text-start fw-bold'>
                            <span style={{ color: "#7F3E98" }}>   Network Infrastructure Monitoring</span>

                        </h5>
                        <p className='text-start fs-6 fs-6'>
                            Ensure optimal performance with real-time monitoring, proactive management, and quick issue resolution for network infrastructure.
                        </p>
                        {/* <div className="user">
                            <button className="service-btn" style={{ background: "#7F3E98" }}>Read More</button>
                        </div> */}
                    </div>
                </div><br />
                <div className="card mt-3">
                    <div className="card-headers">
                        <img src="/blog1 (5).png" alt="rover" />
                    </div>
                    <div className="card-body">

                        <h5 className='text-start fw-bold'>
                            <span style={{ color: "#7F3E98" }}>      Configuration Management</span>
                        </h5>
                        <p className='text-start fs-6'>
                            Streamline network setup and maintenance with automated configuration management for consistent, efficient system performance.
                        </p>
                        {/* <div className="user">
                            <button className="service-btn" style={{ background: "#7F3E98" }}>Read More</button>
                        </div> */}
                    </div>
                </div>
            </div><br />
            <div className='container1'>
                <div className=" card">
                    <div className="card-headers">
                        <img src="/blog1 (1).png" alt="rover" />
                    </div>
                    <div className="card-body">

                        <h5 className='text-start fw-bold'>
                            <span style={{ color: "#7F3E98" }}>    Security <br />
                                Management</span>
                        </h5>
                        <p className='text-start fs-6'>
                            Protect your network with proactive security management, real-time threat detection, and comprehensive risk mitigation strategies.
                        </p>
                        {/* <div className="user">
                            <button className="service-btn" style={{ background: "#7F3E98" }}>Read More</button>
                        </div> */}
                    </div>
                </div>
                <div className=" card mt-3">
                    <div className="card-headers">
                        <img src="/blog1 (2).png" alt="rover" />
                    </div>
                    <div className="card-body">

                        <h5 className='text-start fw-bold'>
                            <span style={{ color: "#7F3E98" }}>  Performance Management </span>
                        </h5>
                        <p className='text-start fs-6'>
                            Optimize network performance with continuous monitoring, analysis, and proactive adjustments for seamless operations and reliability.
                        </p>
                        {/* <div className="user">
                            <button className="service-btn" style={{ background: "#7F3E98" }}>Read More</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blogcard