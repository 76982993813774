import React from 'react'
import Header from '../components/Header'
import Blogcard from '../components/Blogcard'
import { useMediaQuery } from 'react-responsive'
import Demo from '../components/Navbar'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

const StaffArugment = () => {
    const cardData = [
        {
            image: "/blog3 (1).png", // Replace with your image URLs
            title: "IT Department Outsourcing",
            description:
                "Enhance efficiency and reduce costs by outsourcing IT department services with expert management and support.",
        },
        {
            image: "/blog3 (2).png",
            title: "Spotcomm Managed Team",
            description:
                "Leverage Spotcomm's expert managed team for reliable, efficient network solutions and proactive IT support.",
        },
        {
            image: "/blog3 (3).png",
            title: "Staff Consultation",
            description:
                "Get expert staff consultation to optimize performance, streamline processes, and enhance your team's productivity.",
        },
        // {
        //     image: "/blog3 (4).png",
        //     title: "IT Recruiting",
        //     description:
        //         "Find top-tier IT talent with our expert recruiting services, tailored to your company's needs.",
        // },
    ];
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
    }
    const Tablet = ({ children }) => {
        const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
        return isTablet ? children : null
    }
    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ minWidth: 20, maxWidth: 991 })
        return isMobile ? children : null
    }
    const Default = ({ children }) => {
        const isNotMobile = useMediaQuery({ minWidth: 768 })
        return isNotMobile ? children : null
    }
    return (
        <div>
            <Header />
            <section id="heros" className="heros1 section dark-background">

                <img src="/staff.png" alt="" />

                <div className="container text-start">
                    <div className="row justify-content-start">
                        <div className="col-lg-12" data-aos="fade-right" data-aos-delay="200">
                            <h2 className='text-light profh2 text-start'> Spotcomm Staff <br /> Augmentation</h2> <br />
                            <p className='text-light text-start'>
                                Experience seamless team augmentation with skilled, experienced experts to <br />
                                take your businesses to new heights.

                            </p>
                        </div>
                    </div>
                </div>

            </section> 
            <section id="bg2" class="bg3 section dark-background" style={{ background: "#F1EEF0" }}>


<div class="container text-center" >
    <div class="row justify-content-center">
        <div class="col-lg-10 col-sm-12">
            <br />
            <h5 className=' text-center p-5 mx-auto' style={{ border: "2px solid #7F3E98 ", borderRadius: "20px" }}>Spotcomm is your Global We streamline staff  <span className='' style={{ color: "#7F3E98" }}>augmentation</span>   for your business, enabling seamless talent integration and a competitive advantage in the digital landscape.</h5>
        </div>
    </div>
</div>


</section>

           
            {/* <h2 className='text-start fs-3  w-75 mx-auto' data-aos="fade-right" data-aos-delay="100">We streamline staff  <span className='' style={{ color: "#7F3E98" }}>augmentation</span>   for your business, enabling seamless talent integration and a competitive advantage in the digital landscape.</h2> */}
            {/* <Blogcard /><br /><br /> */}
            <section className="services-section1 container">
                <div className='container'>
                    <div className="services-grid">
                        {cardData.map((card, index) => (
                            <div className="service-card1 " key={index}>
                                <img src={card.image} alt={cardData.title} />
                                <div className="card-content1">
                                    <h3 className="card-title1 cmp-teaser__title-link">{card.title}</h3>
                                    <p className="card-description1">{card.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <h2 className='text-center fs-1 fw-bold'>Benefits of Our <span className='' style={{ color: "#7F3E98" }}> IT Staff Augmentation Services </span>
            </h2> <br />
            {/* <p className='text-center'>Benefits of Our IT Staff Augmentation Services
            </p><br /><br /> */}
            <div className="teaser teaser--full-size aem-GridColumn aem-GridColumn--default--12">
                <div className="cmp-teaser cmp-teaser--1095483950    " >
                    <div
                        className="cmp-teaser__content"
                        style={{}}
                    >
                        <div className="cmp-teaser__content-wrapper">
                            <h2 className="cmp-teaser__title   " style={{ color: "#7F3E98" }}>

                                Lower Operational Costs

                            </h2>
                            {/* <div className="cmp-teaser__description">
                                <p>
                                    Reduce your company spending by eliminating the costs of office space, equipment, and taxes. Pay for what drives the most value for your company.


                                </p>
                            </div> */}
                            <div className="cmp-teaser__description">
                                <p className='text-start fs-4'>
                                Reduce your company spending by eliminating the costs of office space, equipment, and taxes. Pay for what drives the most value for your company.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="cmp-teaser__image">
                        <img src="/staff (2).png" />
                    </div>
                </div>
            </div>
            <div className="teaser teaser--full-size teaser--image-left aem-GridColumn aem-GridColumn--default--12" >
                <div className="cmp-teaser cmp-teaser--638358011    ">
                    <div
                        className="cmp-teaser__content"
                        style={{}}
                    >
                        <div className="cmp-teaser__content-wrapper">
                            <h2 className="cmp-teaser__title   " style={{ color: "#7F3E98" }}>

                                Access to Vetted Talent

                            </h2>
                            <div className="cmp-teaser__description">
                                <p className='text-start fs-4'>
                                    Spotcomm offers you immediate access to a vast talent pool at affordable costs. Amplify your team with certified software specialists.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="cmp-teaser__image">
                        <img src="/staff (3).png" />
                    </div>
                </div>
            </div>
            <div className="teaser teaser--full-size aem-GridColumn aem-GridColumn--default--12" >
                <div className="cmp-teaser cmp-teaser--718471742    ">
                    <div
                        className="cmp-teaser__content"
                        style={{}}
                    >
                        <div className="cmp-teaser__content-wrapper">
                            <h2 className="cmp-teaser__title   " style={{ color: "#7F3E98" }}>

                                Full Control

                            </h2>
                            <div className="cmp-teaser__description">
                                <p className='text-start fs-4'>
                                    We encourage you to cherry-pick your team members and participate in the screening process. Manage your projec3t success and stay updated on all changes.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="cmp-teaser__image">
                        <img src="/staff (4).png" />
                    </div>
                </div>
            </div>
            <div className="teaser teaser--full-size teaser--image-left aem-GridColumn aem-GridColumn--default--12" >
                <div
                    className="cmp-teaser cmp-teaser-2010868465    "
                    id="insights"
                >
                    <div
                        className="cmp-teaser__content"
                        style={{}}
                    >
                        <div className="cmp-teaser__content-wrapper">
                            <h2 className="cmp-teaser__title   " style={{ color: "#7F3E98" }}>

                                Fewer Legal Hassles

                            </h2>
                            <div className="cmp-teaser__description">
                                <p className='text-start fs-4'>
                                    Within our IT augmentation services, we remain the official employers of your hires. Our company takes care of all legal responsibilities and documentation.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="cmp-teaser__image">
                        <img src="/staff (6).png" />
                    </div>
                </div>
            </div>
            <div className="teaser teaser--full-size aem-GridColumn aem-GridColumn--default--12" >
                <div className="cmp-teaser cmp-teaser--718471742    ">
                    <div
                        className="cmp-teaser__content"
                        style={{}}
                    >
                        <div className="cmp-teaser__content-wrapper">
                            <h2 className="cmp-teaser__title   " style={{ color: "#7F3E98" }}>

                                No Recruitment Hassles

                            </h2>
                            <div className="cmp-teaser__description">
                                <p className='text-start fs-4'>
                                    Our IT augmentation services save your company the hassle of searching and recruiting vetted development talent. We take over the screening process and send you approved CVs.


                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="cmp-teaser__image">
                        <img src="/staff (5).png" />
                    </div>
                </div>
            </div>
            <div className="teaser teaser--full-size teaser--image-left aem-GridColumn aem-GridColumn--default--12" >
                <div
                    className="cmp-teaser cmp-teaser-2010868465    "
                    id="insights"
                >
                    <div
                        className="cmp-teaser__content"
                        style={{}}
                    >
                        <div className="cmp-teaser__content-wrapper">
                            <h2 className="cmp-teaser__title   " style={{ color: "#7F3E98" }}>

                                Aggressive Deadlines

                            </h2>
                            <div className="cmp-teaser__description">
                                <p className='text-start fs-4'>
                                    Ramp up your technical capacity, when chasing a stringent deadline. Our team of developers dedicates their efforts to delivering the project in time.


                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="cmp-teaser__image">
                        <img src="/staff (1).png" />
                    </div>
                </div>
            </div>
            <Demo />
        </div>
    )
}

export default StaffArugment