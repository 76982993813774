import React from 'react'
import Header from '../components/Header'
import VideoCard from '../components/Videocard';
import Demo from '../components/Navbar';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { useMediaQuery } from 'react-responsive'

const NetworkAudit = () => {
    const caseStudies = [
        { title: "Minimum Security Baselines", image: "/netaudit1.png" },
        { title: "Wireless and OS Security", image:"/audit2.webp" },
        { title: "Firewall Rule Set Review", image: "/netaudit2.png" },
        // { title: "Cooperative Computing Case Study", image: "/case1.png"  },
        { title: "Access Controls Assessment", image: "/netaudit3.png" },
        { title: "Device Configuration Review", image: "/netaudit4.png" },
        { title: "Network Segmentation  Analysis", image: "/audit2.webp" },
        // { title: "SIEM and IPS Deployment Evaluation", image: "/case7.png" },
    ];
    const services = [

        { title: "5G Networks ", images: "/audits1.png" },
        { title: "Advanced Security Measures", images: "/audits5.png" },
        { title: "Edge Computing", images: "/audits3.png" },
        // { title: "Cooperative Computing Case Study", image: "/case1.png"  },
        { title: "Simpler Network Management", images: "/audits6.png" },
        { title: "Artificial Intelligence & Machine Learning", images: "/audits4.png" },
        // { title: "New Wi-Fi 6 & Wi-Fi 7 Protocols", images: "/audits2.png" },
        { title: "New Wi-Fi 6 & Wi-Fi 7 Protocols", images: "/audits7.png" },


    ];
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
    }
    const Tablet = ({ children }) => {
        const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
        return isTablet ? children : null
    }
    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ minWidth: 20, maxWidth: 991 })
        return isMobile ? children : null
    }
    const Default = ({ children }) => {
        const isNotMobile = useMediaQuery({ minWidth: 768 })
        return isNotMobile ? children : null
    }
    return (
        <div> <Header />
            <section id="heros" class="heros1 section dark-background">

                <img src="/audit.png" alt="" />

                <div class="container text-center">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <h2 className='profh2'>Network Audit   </h2> <br />
                            <h4>Spotcomm: Uncover and enhance your network’s potential with our expert audit services.</h4>
                            {/* <img src="/button.svg" alt="play icon" className='mt-4' style={{ width: "80px", height: "80px", left: "40%" }} /> */}
                        </div>
                    </div>
                </div>

            </section>
            <section
                id="testimonials-section sect-paddings"
                className="testimonials-section  at-bg-cover d-flex  align-items-center paragraph paragraph--type--quote paragraph--view-mode--default"
                style={{ background: "url(/feature2.png)" }}
            >
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">
                        <div
                            className="col-12 col-lg-10 at-maxw-1075"
                        // style={{ background: "#362358" , fontFamily: "proxima-nova" ,lineHeight: "1.5" }}
                        >
                            <blockquote className="text-start ">
                                <div className="svg-holder">
                                    <svg
                                        width={107}
                                        height={71}
                                        viewBox="0 0 107 71"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M107 51.8969C107 57.2033 104.965 61.7668 100.895 65.5875C97.039 69.1958 92.2192 71 86.4354 71C78.5095 71 71.8689 68.2407 66.5135 62.722C61.3724 57.2033 58.8018 50.0927 58.8018 41.3901C58.8018 25.4709 65.014 14.1151 77.4384 7.32287C86.4354 2.44096 93.7187 0 99.2883 0C101.645 0 102.823 0.84903 102.823 2.54709C102.823 4.03288 101.538 5.09417 98.967 5.73094C81.1872 10.4006 72.2973 19.2093 72.2973 32.157C72.2973 37.8879 73.7968 42.7698 76.7958 46.8027C77.2242 39.5859 81.8298 35.9776 90.6126 35.9776C95.3253 35.9776 99.1812 37.5695 102.18 40.7534C105.393 43.725 107 47.4395 107 51.8969ZM47.8769 51.8969C47.8769 57.2033 45.8418 61.7668 41.7718 65.5875C37.9159 69.1958 33.2032 71 27.6336 71C19.4935 71 12.8529 68.2407 7.71171 62.722C2.57057 56.991 0 49.7743 0 41.0718C0 25.3647 6.21221 14.1151 18.6366 7.32287C27.6336 2.44096 34.8098 0 40.1652 0C42.5215 0 43.6997 0.84903 43.6997 2.54709C43.6997 4.03288 42.4144 5.09417 39.8438 5.73094C22.2783 10.4006 13.4955 19.3154 13.4955 32.4753C13.4955 37.994 14.995 42.7698 17.994 46.8027C18.4224 39.5859 22.9209 35.9776 31.4895 35.9776C36.2022 35.9776 40.0581 37.5695 43.0571 40.7534C46.2703 43.725 47.8769 47.4395 47.8769 51.8969Z"
                                            fill="#161616"
                                        />
                                    </svg>
                                </div>
                                <h4 className="fw-normal mt-md-5 mt-3 mb-4 at-lh-1pt7">
                                    <p className='fs-6'>
                                    Network auditing is a vital process that provides organizations with a precise view of their network architecture. Our Network Auditing Services offer an in-depth analysis of your network's efficiency, capacity, security, maintenance requirements, and expansion potential. As a trusted Network Audit Service Provider, we bring a wealth of internal expertise, spanning both technical and financial aspects, including supplier selection, hardware choices, and operator decisions.
                                    </p>
                                </h4>

                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className='network ' style={{ background: "#fff", color: "#000" }} >
                
                  <div class="description">
                                  <p className='text-dark'>Network auditing is a vital process that provides organizations with a precise view of their network architecture. Our Network Auditing Services offer an in-depth analysis of your network's efficiency, capacity, security, maintenance requirements, and expansion potential. As a trusted Network Audit Service Provider, we bring a wealth of internal expertise, spanning both technical and financial aspects, including supplier selection, hardware choices, and operator decisions.</p>
                                </div>
                <p className='text-dark fw-700 fs-5'>
                </p>
            </div> */}
            <section id="heros" class="cyber3 section dark-background">

                <img src="/cyber3.png" alt="" />

                <div class="container">
                    <div class="row ">
                        <div class="col-lg-12">
                            <h2 className='text-center'>Key Aspects of Our Network Auditing Services
                            </h2> 
                        </div>
                    </div>
                </div>

            </section>
            <div className="case-studies-grid">
                {caseStudies.map((study, index) => (
                    <div className="case-studys" key={index}>
                        <div
                            className="case-study-image"
                            style={{ backgroundImage: `url(${study.image})` }}
                        ></div>
                        <div className="case-study-overlay">
                            <h2 className='text-light fs-5'>{study.title}</h2>
                        </div>
                    </div>
                ))}
            </div>
            <div className="teaser teaser--full-size teaser--image-left aem-GridColumn aem-GridColumn--default--12" >
                <div className="cmp-teaser cmp-teaser--638358011    ">
                    <div
                        className="cmp-teaser__content"
                        style={{background: "rgb(34 34 57)" , color: "#fff"}}
                    >
                        <div className="cmp-teaser__content-wrapper">
                            <h2 className="cmp-teaser__title  ">
                                <div
                                    className=" fs-1 fw-bold"
                                   
                                >
                                    Access to Vetted Talent
                                </div>
                            </h2>
                            <div className="cmp-teaser__description">
                                <p className='fs-4'>
                                    Spotcomm offers you immediate access to a vast talent pool at affordable costs. Amplify your team with certified software specialists.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="cmp-teaser__image">
                        <img src="/staff (3).png" />
                    </div>
                </div>
            </div><br />
            <div className='apps '>

                {/* <VideoCard
                /> */}
                <VideoCard
                    title="Firewall Audits"
                    videoSrc="/7.mp4 "
                    thumbnail="/networkaudit (1).png"
                /> <br />
                <VideoCard
                    title="Network Architecture Audit
"
                    videoSrc="/7.mp4"
                    thumbnail="/networkaudit (2).png"
                /> <br />
                <VideoCard
                    title="Server Audits"
                    videoSrc="/7.mp4"
                    thumbnail="/networkaudit (3).png"
                />
            </div>

            <section className="services4 ">
                <div className='container'> 
                    <h2 className='text-start fs-1 fw-bold ' >What are the factors driving the growth of the Internet Security Audit Market?

                    </h2><br />
                    <p className='para text-start'>Growing demand for below applications around the world has had a direct impact on the growth of the Internet Security Audit
                    </p>
                    <br /><br />
                    <div className="services3-grid3 ">
                        {services.map((demo, index) => (
                            <div key={index} className="service3-item " style={{flexDirection: "column"}}>
                                <img src={demo.images} /> <br />
                               <h4>{demo.title}</h4> 
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Demo />
        </div>
    )
}

export default NetworkAudit