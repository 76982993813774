import React from 'react'
import Header from '../components/Header'
import "./engineer.css"
import Demo from '../components/Navbar'
import { useMediaQuery } from 'react-responsive'
const Engineer = () => {
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
    }
    const Tablet = ({ children }) => {
        const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
        return isTablet ? children : null
    }
    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ minWidth: 100, maxWidth: 991 })
        return isMobile ? children : null
    }
    const Default = ({ children }) => {
        const isNotMobile = useMediaQuery({ minWidth: 768 })
        return isNotMobile ? children : null
    }

    return (
        <div>


            <div>
                <Header />
                <Desktop>
                   
                    <section id="heros" class="heros1 section dark-background" data-aos="fade-right" data-aos-delay="500">

                        <img src="/engineer.png" alt="" />

                        <div class="container text-end" >
                            <div class="row justify-content-end">
                                <div class="col-lg-7">
                                    <h2 className=''>RESIDENT ENGINEER CONSULTATION
                                    </h2>
                                    <p className='text-light'>Nail the mission, advance the line, accelerate success.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </section>

                </Desktop>
                <Mobile>
                    <section id="heros" className="heros1 section dark-background" style={{ height: "80vh" }}>

                        <img src="/engineermob.png" alt="" />
                        {/* <img src="/button.svg" alt="play icon" className='d-flex align-items-center ' style={{
                            width: "100px", height: "100px", top: "65%"
                        }} /> */}


                    </section>

                </Mobile>
                <div className='engineer'> <br />
                    <h1 className='text-center ' style={{ color: "#7F3E98" }}>Materializing your vision</h1>
                    <h3 className='text-center '>Strengthening Your Team </h3>
                    <section id="bg2" class="bg3 section dark-background">


                        <div class="container text-center" >
                            <div class="row justify-content-center">
                                <div class="col-lg-10 col-sm-12">

                                    <p className=' text-center p-3 mx-auto w-75' style={{ border: "2px solid #7F3E98 ", borderRadius: "50px" }}>Spotcomm Resident Engineering Services streamlines your solutions, services, and delivery process in one proven, comprehensive methodology.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div><br />
                <section id="heros" className=" " style={{ background: "#626B70" }}> <br />
                    <div className='container'>
                        <div className=' row  ' id='cyber'>
                            <div className='col-lg-6   ' data-aos="fade-right" data-aos-delay="100">

                                <h2 className=' text-light fs-1'>Your triumph, our people and services.
                                </h2><br />
                                <p className='  text-light' > Spotcomm solidifies your network with extensive infrastructure, cloud, collaboration, data, security, and analytics services. We streamline your operations, bolster your application strategy, and fortify your digital environment with our Expert Resident Engineer.</p>
                                <br />
                            </div>

                            <div className='col-lg-6 ' data-aos="fade-up" data-aos-delay="100"><br />
                                <img src='/education3.png' className='w-75 mx-auto d-flex justify-content-center' />
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                </section>

                <div className="mosaic" style={{ background: "#e7e2e2" }}>
                    <div
                        data-cmp-data-layer='{"block-how-to-reinvent-software-and-platforms":{"@type":"cio-sites/components/rad/mosaic","analytics-module-name":"mosaic-1","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                        id="block-how-to-reinvent-software-and-platforms"
                        className="rad-component__title--large-text rad-mosaic rad-mosaic--6-cards rad-spacing-vertical-md"
                    >
                        <h3 className="rad-mosaic__heading">
                            What <span className='' style={{ color: "#7F3E98" }}> We Do </span>
                        </h3>
                        <div
                            className="rad-mosaic__grid"
                            data-template-zone="block-how-to-reinvent-software-and-platforms"
                        >
                            {/* vertical with image or stat card */}
                            <button
                                aria-haspopup="dialog"
                                aria-controls="mosaic-card-item0-00190b0d2ff871a2f02591219e405afa0704cb5eff5dbda70377ffd67d516c9f"
                                className="rad-mosaic__card rad-mosaic__image-card rad-mosaic__card--vertical"
                                data-cmp-clickable="" style={{ background: "rgb(34 34 57)", color: "#fff" }}
                                data-analytics-asset-id="card-a1d20d555b"
                                data-analytics-asset-pos={1}
                                data-cmp-data-layer='{"mosaic-card-item0-00190b0d2ff871a2f02591219e405afa0704cb5eff5dbda70377ffd67d516c9f":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/software-platforms","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-1","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                                data-analytics-skim-click=""
                            >
                                <div className="rad-mosaic__card-description">
                                    <div className="rad-mosaic__card-description-eyebrow fs-2">L1 to L3 Engineers</div>
                                    <h4 className="rad-mosaic__card-description-title fs-5 fw-normal">
                                        Our team provides comprehensive network support with skilled L1, L2, and L3 Network Engineers, ensuring seamless deployment, configuration, troubleshooting, and optimization of your network infrastructure.
                                    </h4>
                                </div>

                                <div className="rad-mosaic__image-card-image">
                                    <div
                                        data-cmp-is="image"
                                        data-cmp-dmimage=""
                                        data-cmp-src="https://preview1.assetsadobe.com/is/image/accenture/1306372406?ts=1726612011714&wid=625&fit=constrain&dpr=off"
                                        data-asset-id="841c1d8e-12f6-4942-9596-cbae40d8e5cb"
                                        data-cmp-filereference="/content/dam/accenture/final/images/photography/person/general-people/1306372406.jpeg"
                                        id="image-928d66528f"
                                        data-cmp-data-layer='{"radimage-928d66528f":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-2"}}'
                                        data-cmp-hook-image="imageV3"
                                        className="cmp-image
     "
                                        itemScope=""
                                        itemType="http://schema.org/ImageObject"
                                    >
                                        <img
                                            src="/res1.png"
                                            loading="lazy"
                                            className="cmp-image__image"
                                            itemProp="contentUrl"
                                            width={6720}
                                            height={4480}
                                            alt=""
                                        />
                                    </div>
                                </div>

                            </button>
                            {/* no image*/}
                            {/* vertical with image or stat card */}
                            {/* no image*/}
                            {/* no image*/}
                            {/* no image*/}
                            {/* vertical with image or stat card */}
                            {/* no image*/}
                            <button
                                aria-haspopup="dialog"
                                aria-controls="mosaic-card-item1-71d46931db4225cf862dfd7290bc3914267308bdee8210df82d65d1b859f88c5"
                                className="rad-mosaic__card rad-mosaic__card--no-image"
                                data-cmp-clickable="" style={{ background: "#fefefe" }}
                                data-analytics-asset-id="card-aecf452fb3"
                                data-analytics-asset-pos={2}
                                data-cmp-data-layer='{"mosaic-card-item1-71d46931db4225cf862dfd7290bc3914267308bdee8210df82d65d1b859f88c5":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/software-platforms","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-2","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                                data-analytics-skim-click=""
                            >
                                <div className="rad-mosaic__card-description">
                                    <div className="rad-mosaic__card-description-eyebrow fs-2">
                                        Hassle Free Compliance


                                    </div>
                                    <h4 className="rad-mosaic__card-description-title fs-5 fw-normal">
                                        Our team of experienced professionals provides a hassle-free compliance services experience to the clients. We have worked on over 200,000 issues and all of them resolved so far.

                                    </h4>
                                </div>

                            </button>

                            <div
                                className="rad-mosaic__card rad-mosaic__image-card rad-mosaic__card--vertical"
                                data-analytics-asset-id="card-ccdc36c5c3"
                                data-analytics-asset-pos={3} style={{ background: "rgb(34 34 57)", color: "#fff" }}
                                data-cmp-data-layer='{"mosaic-card-item2-cd1b49480d1159b0f3091ead9a522c995b89ddee95bb37c4a63e966fcc9d7185":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/software-platforms","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-3","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                            >
                                <div className="rad-mosaic__card-description">
                                    <div className="rad-mosaic__card-description-eyebrow fs-2">
                                        Resource Skill Enhancement

                                    </div>
                                    <h4 className="rad-mosaic__card-description-title fs-5">
                                        Resource Skill Enhancement programs for Periodic training and monitoring of manpower for skill enhancement and quality maintenance to serve your network.

                                    </h4>
                                </div>
                                <div className="rad-mosaic__image-card-image">
                                    <div
                                        data-cmp-is="image"
                                        data-cmp-dmimage=""
                                        data-cmp-src="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-afs-index-mosaic-image1?ts=1721844847908&wid=625&fit=constrain&dpr=off"
                                        data-asset-id="a0f4780f-06bf-48a7-a917-91808fd9bb5a"
                                        data-cmp-filereference="/content/dam/accenture/final/accenture-com/imagery-2/Accenture-afs-index-mosaic-image1.jpg"
                                        id="image-9f04d566f9"
                                        data-cmp-data-layer='{"radimage-9f04d566f9":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-3"}}'
                                        data-cmp-hook-image="imageV3"
                                        className="cmp-image
     "
                                        itemScope=""
                                        itemType="http://schema.org/ImageObject"
                                    >
                                        <img
                                            src="/res2.png"
                                            loading="lazy"
                                            className="cmp-image__image"
                                            itemProp="contentUrl"
                                            width={3840}
                                            height={2160}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* no image*/}
                            {/* no image*/}
                            {/* no image*/}
                            {/* vertical with image or stat card */}
                            {/* no image*/}
                            {/* vertical with image or stat card */}
                            {/* no image*/}
                            <button
                                aria-haspopup="dialog"
                                aria-controls="mosaic-card-item3-b32eda2f820783063905b54a22a178518af318606d4ba93c9402c0650cea40be"
                                className="rad-mosaic__card rad-mosaic__card--no-image"
                                data-cmp-clickable=""
                                data-analytics-asset-id="card-e58df3adab" style={{ background: "#fefefe" }}
                                data-analytics-asset-pos={4}
                                data-cmp-data-layer='{"mosaic-card-item3-b32eda2f820783063905b54a22a178518af318606d4ba93c9402c0650cea40be":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/software-platforms","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-4","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                                data-analytics-skim-click=""
                            >
                                <div className="rad-mosaic__card-description">
                                    <div className="rad-mosaic__card-description-eyebrow fs-2">
                                        Data-Driven Insights
                                    </div>
                                    <h4 className="rad-mosaic__card-description-title fs-5">
                                        Spotcomm Global leverages data-driven insights to empower your business, providing actionable analytics, informed decision-making, and tailored strategies that drive efficiency, growth, and long-term success.
                                    </h4>
                                </div>

                            </button>



                            <button
                                aria-haspopup="dialog"
                                aria-controls="mosaic-card-item3-b32eda2f820783063905b54a22a178518af318606d4ba93c9402c0650cea40be"
                                className="rad-mosaic__card rad-mosaic__card--no-image"
                                data-cmp-clickable=""
                                data-analytics-asset-id="card-e58df3adab" style={{ background: "rgb(34 34 57)", color: "#fff" }}
                                data-analytics-asset-pos={4}
                                data-cmp-data-layer='{"mosaic-card-item3-b32eda2f820783063905b54a22a178518af318606d4ba93c9402c0650cea40be":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/resident-engineering","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-4","analytics-template-zone":"block-resident-engineering"}}'
                                data-analytics-skim-click=""
                            >
                                <div className="rad-mosaic__card-description">
                                    <div className="rad-mosaic__card-description-eyebrow fs-2">
                                        Expert On-Site Solutions
                                    </div>
                                    <h4 className="rad-mosaic__card-description-title fs-5">
                                        Spotcomm Global’s Resident Engineering services provide on-site expertise, ensuring seamless operations, rapid troubleshooting, and tailored support for sustained success in complex environments.
                                    </h4>
                                </div>
                            </button>

                            <button
                                aria-haspopup="dialog"
                                aria-controls="mosaic-card-item1-71d46931db4225cf862dfd7290bc3914267308bdee8210df82d65d1b859f88c5"
                                className="rad-mosaic__card rad-mosaic__card--no-image"
                                data-cmp-clickable=""
                                data-analytics-asset-id="card-aecf452fb3" style={{ background: "#fefefe" }}
                                data-analytics-asset-pos={2}
                                data-cmp-data-layer='{"mosaic-card-item1-71d46931db4225cf862dfd7290bc3914267308bdee8210df82d65d1b859f88c5":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/resident-engineering","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-2","analytics-template-zone":"block-resident-engineering"}}'
                                data-analytics-skim-click=""
                            >
                                <div className="rad-mosaic__card-description">
                                    <div className="rad-mosaic__card-description-eyebrow fs-2">
                                        Dedicated Engineering Support
                                    </div>
                                    <h4 className="rad-mosaic__card-description-title fs-5 fw-normal">
                                        With a focus on operational excellence, Spotcomm Global offers Resident Engineering services designed to ensure your infrastructure is maintained, optimized, and future-ready.
                                    </h4>
                                </div>
                            </button>






                        </div>
                    </div>
                </div>

                <div className=" responsivegrid aem-GridColumn aem-GridColumn--default--12">
                    <div id="container-eb9fb998a4" className="cmp-container cmp-container--1563461404 " data-type="simple">
                        <div className="responsive-image-banner">

                            <div className="cmp-responsive-image-banner cmp-responsive-image-banner--1487958357">
                                <div className="cmp-responsive-image-banner__top-overlay">
                                    <div className="cmp-responsive-image-banner__title d-flex align-items-center"><p>Current and Future Trends </p>
                                    </div>

                                </div>
                                <div className="cmp-responsive-image-banner__top-overlay-box" />
                                <div className="cmp-responsive-image-banner__content">
                                    <div className="cmp-responsive-image-banner__section">

                                        <img className="cmp-responsive-image-banner__image" src="/engineer2 (4).png" alt="cloud-energy" />
                                        <div className="cmp-responsive-image-banner__text-overlay"><p className='w-100'>AI Integration<span style={{ fontSize: '18.0px' }} /></p>
                                        </div>
                                        <div className="cmp-responsive-image-banner__bottom-overlay-box" />

                                    </div>
                                    <div className="cmp-responsive-image-banner__section" target="_self">

                                        <img className="cmp-responsive-image-banner__image" src="/engineer2 (2).png" alt="cloud-energy" />
                                        <div className="cmp-responsive-image-banner__text-overlay "><p className='w-100'>Cloud and Multi-Cloud Management<span style={{ fontSize: '18.0px' }} /></p>
                                        </div>
                                        <div className="cmp-responsive-image-banner__bottom-overlay-box" />

                                    </div>
                                    <div className="cmp-responsive-image-banner__section" target="_self">

                                        <img className="cmp-responsive-image-banner__image" src="/engineer2 (3).png" alt="cloud-energy" />
                                        <div className="cmp-responsive-image-banner__text-overlay"><p className='w-100'>5G and Edge Computing<span style={{ fontSize: '18.0px' }} /></p>
                                        </div>
                                        <div className="cmp-responsive-image-banner__bottom-overlay-box" />

                                    </div>

                                </div >
                                <div className="cmp-responsive-image-banner__content d-flex justify-content-center">
                                    <div className="cmp-responsive-image-banner__section">

                                        <img className="cmp-responsive-image-banner__image" src="/engineer2 (1).png" alt="cloud-energy" />
                                        <div className="cmp-responsive-image-banner__text-overlay"><p className='w-100'>Security Focus<span style={{ fontSize: '18.0px' }} /></p>
                                        </div>
                                        <div className="cmp-responsive-image-banner__bottom-overlay-box" />

                                    </div>
                                    <div className="cmp-responsive-image-banner__section" target="_self">

                                        <img className="cmp-responsive-image-banner__image" src="/engineer2 (5).png" alt="cloud-energy" />
                                        <div className="cmp-responsive-image-banner__text-overlay"><p className='w-100'>Software-Defined Networking (SDN)<span style={{ fontSize: '18.0px' }} /></p>
                                        </div>
                                        <div className="cmp-responsive-image-banner__bottom-overlay-box" />

                                    </div>
                                    {/* <div className="cmp-responsive-image-banner__section" target="_self">
                                        <a href="">
                                            <img className="cmp-responsive-image-banner__image" src="/engineer2 (5).png" alt="cloud-energy" />
                                            <div className="cmp-responsive-image-banner__text-overlay"><p className='w-100'>MEDICAL SCIENCES<span style={{ fontSize: '18.0px' }} /></p>
                                            </div>
                                            <div className="cmp-responsive-image-banner__bottom-overlay-box" />
                                       
                                    </div> */}

                                </div >
                            </div >
                        </div >


                    </div >
                </div>
                <Demo />

            </div>

        </div>
    )
}

export default Engineer