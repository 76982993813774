import React from 'react'
import { TiSocialFacebook, TiSocialLinkedin } from "react-icons/ti";
import "./about.css"
import Header from './Header'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { useMediaQuery } from 'react-responsive';
import 'swiper/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from 'react-router-dom';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation, Scrollbar, A11y } from 'swiper/modules';
import Demo from './Navbar';

const About = () => {
    return (
        <div>
            <>
                <Header />
             
  
               



                {/* BODY START */}
              

                <main id="mainContent">
                    {" "}
                    <div
                        data-elementor-type="wp-page"
                        data-elementor-id={470}
                        className="elementor elementor-470"
                    >
                        <header
                            className="elementor-section elementor-top-section elementor-element elementor-element-f419ae2 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default section__indent-def section__outdent-def"
                            data-id="f419ae2"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                        >
                            <div className="elementor-container elementor-column-gap-no">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-946bdb2"
                                    data-id="946bdb2"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-2b0cc49 elementor-widget elementor-widget-quadron-page-hero-section"
                                            data-id="2b0cc49"
                                            data-element_type="widget"
                                            data-widget_type="quadron-page-hero-section.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <div className="section--no-padding section">
                                                    <div className="subpage-header">
                                                        <div className="subpage-header__bg">
                                                            <div className="container">
                                                                <div className="subpage-header__block">
                                                                    {/* <div className="subpage-header__caption">
                                                                        Spotcomm
                                                                    </div> */}
                                                                    <h1 className="subpage-header__title ">
                                                                        About Spotcomm
                                                                    </h1>
                                                                    <div className="subpage-header__line" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-d3b6c0b elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default section__indent-def section__outdent-def"
                            data-id="d3b6c0b"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                        >
                            <div className="elementor-container elementor-column-gap-no">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ea33ff7"
                                    data-id="ea33ff7"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-35533c1 elementor-widget elementor-widget-quadron-about-us-three-section"
                                            data-id="35533c1"
                                            data-element_type="widget"
                                            data-widget_type="quadron-about-us-three-section.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <div className="section--no-padding section--pr">
                                                    <div className="section--bg-wrapper-03 section__indent-left" />
                                                    <div className="section__indent-05">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="section-heading">
                                                                        <div className="description">
                                                                            <i />
                                                                            About
                                                                        </div>
                                                                        <h4 className="title title-lg">Who We Are</h4>
                                                                    </div>
                                                                    <p>
                                                                        <strong className="base-color-01">
                                                                            Founded with a mission to revolutionize the way businesses approach IT
                                                                        </strong>
                                                                    </p>
                                                                    <p>
                                                                        Spotcomm Global has
                                                                        become a trusted partner for companies looking to optimize their network infrastructure, enhance
                                                                        cybersecurity, and ensure seamless digital operations. With a team of highly skilled professionals
                                                                        and industry experts, we bring decades of experience in technology and network management to
                                                                        our clients, ensuring they stay ahead in a rapidly evolving digital landscape.
                                                                    </p>

                                                                    <p>
                                                                        <span className="extra-large-text">Spotcomm</span>
                                                                    </p>
                                                                </div>
                                                                <div className="divider divider__lg d-block d-sm-none" />
                                                                <div className="col-sm-5 offset-lg-1">
                                                                    <a
                                                                        href="https://fb.watch/x8KBJdVF8u/"
                                                                        className="videolink video-popup offset-top5"
                                                                        target='_blanke'
                                                                    >
                                                                        <img
                                                                            width={472}
                                                                            height={535}
                                                                            decoding="async"
                                                                            src="/about1.png"
                                                                            alt=""
                                                                            data-lazy-src="https://ninetheme.com/themes/quadron/wp-content/plugins/quadron-elementor-addons/assets/front/img/videolink_img01.jpg"
                                                                        />
                                                                       
                                                                        <div className="videolink__icon" />
                                                                    </a>
                                                                    <div className="extra-block01" />
                                                                </div>
                                                            </div>
                                                            <div className="divider divider__48 d-none d-lg-block d-xl-block" />
                                                            <div className="box-counter-list">
                                                                <div className="row justify-default">
                                                                    <div className="col-sm-6 col-md-4">
                                                                        <div className="box-counter">
                                                                            <div className="box-counter__title">
                                                                                Deliver Services
                                                                            </div>
                                                                            <div className="box-counter__value">
                                                                                <span
                                                                                    data-num={6}
                                                                                    className="value animate-number viz"
                                                                                >
                                                                                    2
                                                                                </span>{" "}
                                                                                <span className="value">,</span>
                                                                                <span
                                                                                    data-num={500}
                                                                                    className="value animate-number viz"
                                                                                >
                                                                                    500+
                                                                                </span>{" "}

                                                                            </div>
                                                                            <p>
                                                                            Experience efficient and timely deliveries tailored to meet your needs, ensuring reliability every step of the way.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 col-md-4">
                                                                        <div className="box-counter">
                                                                            <div className="box-counter__title">
                                                                                Satisfied
                                                                            </div>
                                                                            <div className="box-counter__value">
                                                                                <span
                                                                                    data-num={700}
                                                                                    className="value animate-number viz"
                                                                                >
                                                                                    3000
                                                                                </span>{" "}
                                                                                Clients
                                                                            </div>
                                                                            <p>
                                                                                Trusted by numerous clients for our efficient, secure, and timely delivery solutions.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 col-md-4">
                                                                        <div className="box-counter">
                                                                            <div className="box-counter__title">
                                                                                Covered
                                                                            </div>
                                                                            <div className="box-counter__value">
                                                                                <span
                                                                                    data-num={12}
                                                                                    className="value animate-number viz"
                                                                                >
                                                                                    12
                                                                                </span>{" "}
                                                                                Countries
                                                                            </div>
                                                                            <p>
                                                                                Delivering excellence worldwide, with a presence in 12 countries to meet your demands.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div
                            className="elementor-section elementor-top-section elementor-element elementor-element-975eb2b elementor-section-stretched elementor-section-full_width section-default-top elementor-section-height-default elementor-section-height-default section__indent-def section__outdent-def"
                            data-id="975eb2b"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                        >
                            <div className="elementor-container elementor-column-gap-no">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-14eca89"
                                    data-id="14eca89"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-340c0b7 elementor-section-boxed elementor-section-height-default elementor-section-height-default section__indent-def section__outdent-def"
                                            data-id="340c0b7"
                                            data-element_type="section"
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-3eb3cc2"
                                                    data-id="3eb3cc2"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-91ad893 elementor-widget elementor-widget-quadron-section-heading"
                                                            data-id="91ad893"
                                                            data-element_type="widget"
                                                            data-widget_type="quadron-section-heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="section-heading section-heading_indentg04">
                                                                    <div className="description">
                                                                        <i />
                                                                        Our Leadership
                                                                    </div>
                                                                    <h1 className="title">Meet Our Leadership</h1>
                                                                </div>{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-f18449a nt-structure nt-col-33 elementor-section-boxed elementor-section-height-default elementor-section-height-default section__indent-def section__outdent-def"
                                            data-id="f18449a"
                                            data-element_type="section"
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-9259afd"
                                                    data-id="9259afd"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-eb43e71 elementor-widget elementor-widget-quadron-team-member-item" style={{display: "flex ", justifyContent : "center"}}
                                                            data-id="eb43e71"
                                                            data-element_type="widget"
                                                            data-widget_type="quadron-team-member-item.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="team-item">
                                                                    <div className="team-item__img">
                                                                        <div className="img">
                                                                            <img
                                                                                width={271}
                                                                                height={377}
                                                                                decoding="async"
                                                                                src="/profile (5).png"
                                                                                data-lazy-src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/team_img_06.jpg"
                                                                            />
                                                                           
                                                                        </div>
                                                                        <img
                                                                            width={140}
                                                                            height={231}
                                                                            decoding="async"
                                                                            className="align-img"
                                                                            src="/finger.png"
                                                                            alt="team_img_01_fingersprint.png"
                                                                            data-lazy-src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/team_img_01_fingersprint.png"
                                                                        />

                                                                        <ul className="social-icon">
                                                                            <li className="eael-team-member-social-link">
                                                                                <Link to="https://www.facebook.com/hassam.spotcomm" target="_blank">
                                                                                    <TiSocialFacebook className='text-light fs-3' />
                                                                                </Link>
                                                                            </li>

                                                                            <li className="eael-team-member-social-link">
                                                                                <Link to="https://www.linkedin.com/in/hassamsohailahmed/" target="_blank">
                                                                                    <TiSocialLinkedin className='text-light fs-3' />
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="team-item__description">
                                                                        <div className="team-item__caption">
                                                                            CEO & FOUNDER




                                                                        </div>
                                                                        <h6 className="team-item__title fw-bold">
                                                                            Hassam Sohail Ahmed

                                                                        </h6>
                                                                    </div>
                                                                </div>{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-2e2c7c7"
                                                    data-id="2e2c7c7"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-aeb27ed elementor-widget elementor-widget-quadron-team-member-item"  style={{display: "flex ", justifyContent : "center"}}
                                                            data-id="aeb27ed"
                                                            data-element_type="widget"
                                                            data-widget_type="quadron-team-member-item.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="team-item">
                                                                    <div className="team-item__img">
                                                                        <div className="img">
                                                                            <img
                                                                                width={271}
                                                                                height={377}
                                                                                decoding="async"
                                                                                src="/profile (3).png"
                                                                                alt="team_img_05.jpg"
                                                                                data-lazy-src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/team_img_05.jpg"
                                                                            />
                                                                           
                                                                        </div>
                                                                        <img
                                                                            width={140}
                                                                            height={231}
                                                                            decoding="async"
                                                                            className="align-img"
                                                                            src="/finger.png"
                                                                            alt="team_img_02_fingersprint.png"
                                                                            data-lazy-src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/team_img_02_fingersprint.png"
                                                                        />
                                                                        
                                                                        <ul className="social-icon">
                                                                            <li className="eael-team-member-social-link">
                                                                                <Link to="https://www.facebook.com/basitnaeemspotcomm" target="_blank">
                                                                                    <TiSocialFacebook className='text-light fs-3' />
                                                                                </Link>
                                                                            </li>

                                                                            <li className="eael-team-member-social-link">
                                                                                <Link to="https://www.linkedin.com/in/basitnaeemspotcomm/" target="_blank">
                                                                                    <TiSocialLinkedin className='text-light fs-3' />
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="team-item__description">
                                                                        <div className="team-item__caption">
                                                                            CHIEF FINANCE OFFICER
                                                                        </div>
                                                                        <h6 className="team-item__title fw-bold">
                                                                            Muhammad Basit Naeem
                                                                        </h6>
                                                                    </div>
                                                                </div>{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-dbe603b"
                                                    data-id="dbe603b"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-46d2cd1 elementor-widget elementor-widget-quadron-team-member-item"  style={{display: "flex ", justifyContent : "center"}}
                                                            data-id="46d2cd1"
                                                            data-element_type="widget"
                                                            data-widget_type="quadron-team-member-item.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="team-item">
                                                                    <div className="team-item__img">
                                                                        <div className="img">
                                                                            <img
                                                                                width={271}
                                                                                height={377}
                                                                                decoding="async"
                                                                                src="/profile (4).png"
                                                                                alt="team_img_01.jpg"
                                                                                data-lazy-src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/team_img_01.jpg"
                                                                            />
                                                                            
                                                                        </div>
                                                                        <img
                                                                            width={140}
                                                                            height={231}
                                                                            decoding="async"
                                                                            className="align-img"
                                                                            src="/finger.png"
                                                                            alt="team_img_03_fingersprint.png"
                                                                            data-lazy-src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/team_img_03_fingersprint.png"
                                                                        />
                                                                        <noscript>
                                                                            &lt;img width="140" height="231"
                                                                            decoding="async" class="align-img"
                                                                            src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/team_img_03_fingersprint.png"
                                                                            alt="team_img_03_fingersprint.png"&gt;
                                                                        </noscript>
                                                                        <ul className="social-icon">
                                                                            <li className="eael-team-member-social-link">
                                                                                <Link to="https://www.facebook.com/soaman.spotcomm" target="_blank">
                                                                                    <TiSocialFacebook className='text-light fs-3' />
                                                                                </Link>
                                                                            </li>

                                                                            <li className="eael-team-member-social-link">
                                                                                <Link to="https://www.linkedin.com/in/muhammad-soaman-faruqi-9ba94879/" target="_blank">
                                                                                    <TiSocialLinkedin className='text-light fs-3' />
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="team-item__description">
                                                                        <div className="team-item__caption">
                                                                            CHIEF CUSTOMER OFFICER
                                                                        </div>
                                                                        <h6 className="team-item__title fw-bold">Muhammad Soaman Faruqi</h6>
                                                                    </div>
                                                                </div>{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-959179f"
                                                    data-id="959179f"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-df4c606 elementor-widget elementor-widget-quadron-team-member-item"  style={{display: "flex ", justifyContent : "center"}}
                                                            data-id="df4c606"
                                                            data-element_type="widget"
                                                            data-widget_type="quadron-team-member-item.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="team-item">
                                                                    <div className="team-item__img">
                                                                        <div className="img">
                                                                            <img
                                                                                width={271}
                                                                                height={377}
                                                                                decoding="async"
                                                                                src="/profile (1).png"
                                                                                alt="team_img_04.jpg"
                                                                                data-lazy-src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/team_img_04.jpg"
                                                                            />
                                                                         
                                                                        </div>
                                                                        <img
                                                                            width={140}
                                                                            height={231}
                                                                            decoding="async"
                                                                            className="align-img"
                                                                            src="/finger.png"
                                                                            alt="team_img_03_fingersprint.png"
                                                                            data-lazy-src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/team_img_03_fingersprint.png"
                                                                        />
                                                                      
                                                                        <ul className="social-icon">
                                                                            <li className="eael-team-member-social-link">
                                                                                <Link to="https://www.linkedin.com/in/basitnaeemspotcomm/" target="_blank">
                                                                                    <TiSocialFacebook className='text-light fs-3' />
                                                                                </Link>
                                                                            </li>

                                                                            <li className="eael-team-member-social-link">
                                                                                <Link to="https://www.linkedin.com/in/zaib-imran-332b081b6/" target="_blank">
                                                                                    <TiSocialLinkedin className='text-light fs-3' />
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="team-item__description">
                                                                        <div className="team-item__caption">
                                                                            CHIEF PARTNERSHIP OFFICER
                                                                        </div>
                                                                        <h6 className="team-item__title fw-bold">
                                                                            Muhammad Zaib Imran
                                                                        </h6>
                                                                    </div>
                                                                </div>{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-960b0ff"
                                                    data-id="960b0ff"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-bf520fd elementor-widget elementor-widget-quadron-team-member-item"  style={{display: "flex ", justifyContent : "center"}}
                                                            data-id="bf520fd"
                                                            data-element_type="widget"
                                                            data-widget_type="quadron-team-member-item.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="team-item">
                                                                    <div className="team-item__img">
                                                                        <div className="img">
                                                                            <img
                                                                                width={271}
                                                                                height={377}
                                                                                decoding="async"
                                                                                src="/profile (2).png"
                                                                                alt="team_img_03.jpg"
                                                                                data-lazy-src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/team_img_03.jpg"
                                                                            />
                                                                            <noscript>
                                                                                &lt;img width="271" height="377"
                                                                                decoding="async"
                                                                                src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/team_img_03.jpg"
                                                                                alt="team_img_03.jpg"&gt;
                                                                            </noscript>
                                                                        </div>
                                                                        <img
                                                                            width={140}
                                                                            height={231}
                                                                            decoding="async"
                                                                            className="align-img"
                                                                            src="/finger.png"
                                                                            alt="team_img_02_fingersprint.png"
                                                                            data-lazy-src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/team_img_02_fingersprint.png"
                                                                        />
                                                                        
                                                                        <ul className="social-icon">
                                                                            <li className="eael-team-member-social-link">
                                                                                <Link to="https://www.linkedin.com/in/basitnaeemspotcomm/" target="_blank">
                                                                                    <TiSocialFacebook className='text-light fs-3' />
                                                                                </Link>
                                                                            </li>

                                                                            <li className="eael-team-member-social-link">
                                                                                <Link to="https://www.linkedin.com/in/muhammad-faizan-majeed-0a3676163/" target="_blank">
                                                                                    <TiSocialLinkedin className='text-light fs-3' />
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="team-item__description">
                                                                        <div className="team-item__caption">
                                                                        CHIEF TECHNICAL OFFICER
                                                                        </div>
                                                                        <h6 className="team-item__title fw-bold">
                                                                            Muhammad Faizan Majeed
                                                                        </h6>
                                                                    </div>
                                                                </div>{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="elementor-section elementor-top-section elementor-element elementor-element-ac0e40a elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default section__indent-def section__outdent-def"
                            data-id="ac0e40a"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                        >
                            <div className="elementor-container elementor-column-gap-no">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2f71a49"
                                    data-id="2f71a49"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-eb4e32d elementor-widget elementor-widget-quadron-awards-slider-section"
                                            data-id="eb4e32d"
                                            data-element_type="widget"
                                            data-widget_type="quadron-awards-slider-section.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <div className="section section-default-top nt-awards">
                                                    <div className="container">
                                                        <div className="section-heading section-heading_indentg02">
                                                            <div className="description">
                                                                <i />
                                                                Progress
                                                            </div>
                                                            <h3 className="title">Our progress</h3>
                                                        </div>
                                                        <div
                                                            className="js-award-carusel carusel-award slick-arrow-center js-align-arrow-award js-carousel-options"
                                                          
                                                        >
                                                            <Swiper
                                                                modules={[Autoplay]}
                                                                spaceBetween={30}
                                                                slidesPerView={1}

                                                                loop={true}
                                                                autoplay={{
                                                                    delay: 0, // Continuous autoplay without a delay
                                                                    disableOnInteraction: false, // Keeps autoplay even when interacting
                                                                    pauseOnMouseEnter: false,
                                                                }}
                                                                speed={3000} // Adjust speed for smoothness (in ms)
                                                                breakpoints={{
                                                                    640: {
                                                                        slidesPerView: 1,
                                                                        spaceBetween: 10,
                                                                    },
                                                                    768: {
                                                                        slidesPerView: 2,
                                                                        spaceBetween: 20,
                                                                    },
                                                                    1024: {
                                                                        slidesPerView: 3,
                                                                        spaceBetween: 30,
                                                                    },
                                                                }} >
                                                                <SwiperSlide >
                                                                    <div className="item">
                                                                        <Link
                                                                            
                                                                            className="award"
                                                                        >
                                                                            <div className="award__img">
                                                                                <img
                                                                                 
                                                                                    decoding="async"
                                                                                    src="/2015.webp"
                                                                                    data-lazy-src="https://ninetheme.com/themes/quadron/wp-content/uploads/2019/11/award_img_01-1.png"
                                                                                />
                                                                               
                                                                            </div>
                                                                            <div className="award__description">
                                                                                <div className="award__caption">2015</div>
                                                                                <h4 className="award__title">Idea inception by 3 classmates (Hassam, Soaman, Basit).</h4>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                    </SwiperSlide>
                                                                <SwiperSlide >
                                                                    <div className="item">
                                                                        <Link
                                                                            
                                                                            className="award"
                                                                        >
                                                                            <div className="award__img">
                                                                                <img
                                                                                 
                                                                                    decoding="async"
                                                                                    src="/2017.webp"
                                                                                
                                                                                />
                                                                              
                                                                            </div>
                                                                            <div className="award__description">
                                                                                <div className="award__caption">2017</div>
                                                                                <h4 className="award__title">Company registered. Started as WiFi kiosk provider but pivoted immediately.</h4>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </SwiperSlide>
                                                                <SwiperSlide >
                                                                    <div className="item">
                                                                        <Link
                                                                            
                                                                            className="award"
                                                                        >
                                                                            <div className="award__img">
                                                                                <img
                                                                                 
                                                                                    decoding="async"
                                                                                    src="/2020.webp"
                                                                                    
                                                                                />
                                                                               
                                                                            </div>
                                                                            <div className="award__description">
                                                                                <div className="award__caption">2020</div>
                                                                                <h4 className="award__title">Infrastructure expanded to 500+ wards, 34+ cities & 50+ event centers.</h4>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </SwiperSlide>
                                                                <SwiperSlide >
                                                                    <div className="item">
                                                                        <Link
                                                                            
                                                                            className="award"
                                                                        >
                                                                            <div className="award__img">
                                                                                <img
                                                                                 
                                                                                    decoding="async"
                                                                                    src="/2022.webp"
                                                                                   
                                                                                />
                                                                               
                                                                            </div>
                                                                            <div className="award__description">
                                                                                <div className="award__caption">2022</div>
                                                                                <h4 className="award__title">Entered E-sports industry starting with successful Free-fire, Fifa & Valorant E-sports events.</h4>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </SwiperSlide>
                                                                <SwiperSlide >
                                                                    <div className="item">
                                                                        <Link
                                                                            
                                                                            className="award"
                                                                        >
                                                                            <div className="award__img">
                                                                                <img
                                                                                 
                                                                                    decoding="async"
                                                                                    src="/2023.webp"
                                                                                />
                                                                               
                                                                            </div>
                                                                            <div className="award__description">
                                                                                <div className="award__caption">2023</div>
                                                                                <h4 className="award__title">Entered international market by providing Managed Network Services globally to clients in US, etc.</h4>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </SwiperSlide>
                                                                <SwiperSlide >
                                                                    <div className="item">
                                                                        <Link
                                                                            
                                                                            className="award"
                                                                        >
                                                                            <div className="award__img">
                                                                                <img
                                                                                 
                                                                                    decoding="async"
                                                                                    src="/2024.webp"
                                                                                />
                                                                               
                                                                            </div>
                                                                            <div className="award__description">
                                                                                <div className="award__caption">2024</div>
                                                                                <h4 className="award__title">Spotcomm became a global entity starting operations in the international market.</h4>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </SwiperSlide>
                                                            </Swiper>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                
            </>
            <Demo />

        </div>
    )
}

export default About