import React from 'react'
import Header from '../components/Header'
import Demo from '../components/Navbar'
import "./business.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { useMediaQuery } from 'react-responsive';
import 'swiper/css';
import { Link } from 'react-router-dom';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation, Scrollbar, A11y } from 'swiper/modules';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const services = [
  { title: 'Managed Network Services', icon: '/service1.png', link: '/manage-network' },
  { title: 'Managed LAN/WAN Services', icon: '/service2.png', link: '/managed-lan-wan-services' },
  { title: 'Managed WiFi Services', icon: '/service3.png', link: '/managed-wifi-services' },
  { title: 'Managed Security Services', icon: '/service4.png', link: '/managed-security-services' },
  { title: 'Data Center Management', icon: '/service5.png', link: '/data-center-management' },
  { title: 'Managed NOC Services', icon: '/service6.png', link: '/managed-noc-services' },
  { title: 'SD-WAN Services', icon: '/service7.png', link: '/sd-wan-services' },
  { title: 'White Label VPN Services', icon: '/service8.png', link: '/white-label-vpn-services' },
  { title: 'Managed Infrastructure Services', icon: '/service9.png', link: '/managed-infrastructure-services' }
];

const services2 = [
  { image: '/bus (1).png', title: "Experienced Team" },
  // { image: '/bus.png', title: "Experienced Team" },
  { image: '/bus (2).png', title: "Comprehensive Support" },
  { image: '/bus (3).png', title: "Data-Driven Insights" },
  { image: '/bus (4).png', title: "Innovative Technology" },
  { image: '/bus (5).png', title: "Scalable Solutions" },
  { image: '/bus (6).png', title: "Customized Strategies" },
];
const Businesssolution = () => {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ minWidth: 20, maxWidth: 991 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }

  return (
    <div>
      <Header />
      {/* <Desktop> */}
      <section id="heros" className="heros1 section dark-background" data-aos="fade-right" data-aos-delay="500">

        <img src="/businessbanner.png" alt="" />

        <div className="container text-start" data-aos-delay="100">
          <div className="row justify-content-start">
            <div className="col-lg-8">
              <h2 className='profh2'>Business <br /> Solutions</h2>
            </div>
          </div>
        </div>

      </section>
      {/* </Desktop>
      <Mobile> */}
      {/* <section id="heros" className="heros1 section dark-background" data-aos="fade-right" data-aos-delay="500">

          <img src="/businesssolutionmob.png" alt="" />

          <div className="container text-center" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h2 className='profh2'>Business Solutions</h2>
              </div>
            </div>
          </div>

        </section> */}
      {/* </Mobile> */}

      <section id="bg2" className="bg2 section dark-background" data-aos="fade-right" data-aos-delay="500">

        <img src="/bg.png" alt="" />

        <div className="container text-center" >
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h3 className='fw-bold '>Custom  <span className='' style={{ color: "#7F3E98" }}> Business Solutions  </span> for Optimal Results</h3> <br />
              <p className=' text-center'>Welcome to Spotcomm, where we specialize in delivering customized business solutions designed  to meet the unique needs of your organization. Our mission is to empower your  business by enhancing  efficiency, driving growth, and overcoming challenges with precision and innovation.</p>
            </div>
          </div>
        </div>

      </section>
      <section className="services-section"  >
        <h2 className='text-center'>What Services <span className='' style={{ color: "#7F3E98" }}> We Provide </span></h2>
        <Desktop>
          <div className="services-grid row">
            {services.map((service, index) => (
              <div className="col-lg-2 service mx-auto mt-5" data-aos="fade-right" data-aos-delay="100" key={index}>
                <div
                  className="service-card"
                  style={{ "--image-url": `url(${service.icon})` }}  // Setting the image URL
                >
                  <br />    <br />
                  <div className="service-title fw-bold ">{service.title}</div>
                  {/* <Link to={service2.link} className="service-btn text-light">Read More</Link> */}
                </div>
              </div>
            ))}
          </div>


        </Desktop>
        <Mobile>
          <div className="services-grid row">
            <Swiper modules={[Navigation, A11y, Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}

              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log('slide change')}>
              {services.map((service, index) => (
                <SwiperSlide key={index}>

                  <div className="col-lg-3 service mx-auto mt-5" data-aos="fade-right" data-aos-delay="500" key={index}>
                    <div
                      className="service-card"
                      style={{ "--image-url": `url(${service.icon})` }}  // Setting the image URL
                    >
                      <br />
                      <div className="service-title fw-bold">{service.title}</div>
                      {/* <Link to={service2.link} className="service-btn text-light">Read More</Link> */}
                    </div>
                  </div>


                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Mobile>

        {/* <div className='backround1 w-100'> */}
        {/* <div className="row d-flex justify-content-center " data-aos="fade-right" data-aos-delay="500" >
            <br />
            <div className="services2-grid">
            {services2.map((service2, index) => (
              <div key={index} className="services-item" >
                {service2.image && (
                  <>
                    <img src={service2.image} alt={service2.title} className="services-image" />
                    <h3 className="service-title fw-bold">{service2.title}</h3>
                  </>
                )}
              </div>
            ))}
          </div>
          </div> */}
        {/* <div className="container">
          <div className="row ">
            {services2.map((service2, index) => (
              <div key={index} className="col-lg-4">
       
                <img src={service2.image} alt={service2.title} />

              </div>
              // </div>
            ))}
          </div>

        </div> */}
        {/* <div className='container'>
          <div className="row d-flex justify-content-center">
            {services2.map((service2, index) => (
              <div className="col-lg-4   mt-5 " data-aos="fade-right" data-aos-delay="500" >
                <img src={service2.image} className='mx-auto d-flex w-100' />
                <div className="services-title text-center fs-5 fw-bold mt-3">{service2.title} </div>
              </div>
            ))}
          </div>
        </div> */}

        {/* </div> */}

        <div className="mosaic">
                <div
                    data-cmp-data-layer='{"block-how-to-reinvent-software-and-platforms":{"@type":"cio-sites/components/rad/mosaic","analytics-module-name":"mosaic-1","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                    id="block-how-to-reinvent-software-and-platforms"
                    className="rad-component__title--large-text rad-mosaic rad-mosaic--6-cards rad-spacing-vertical-md"
                >
                    <h3 className="rad-mosaic__heading">
                    Why Choose <span className='' style={{ color: "#7F3E98" }}> Our Solutions? </span>
                    </h3>
                    <div
                        className="rad-mosaic__grid"
                        data-template-zone="block-how-to-reinvent-software-and-platforms"
                    >
                        {/* vertical with image or stat card */}
                        <button
                            aria-haspopup="dialog"
                            aria-controls="mosaic-card-item0-00190b0d2ff871a2f02591219e405afa0704cb5eff5dbda70377ffd67d516c9f"
                            className="rad-mosaic__card rad-mosaic__image-card rad-mosaic__card--vertical" style={{background: "#fefefe"}}
                            data-cmp-clickable=""
                            data-analytics-asset-id="card-a1d20d555b"
                            data-analytics-asset-pos={1}
                            data-cmp-data-layer='{"mosaic-card-item0-00190b0d2ff871a2f02591219e405afa0704cb5eff5dbda70377ffd67d516c9f":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/software-platforms","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-1","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                            data-analytics-skim-click=""
                        >
                            <div className="rad-mosaic__card-description" >
                                <div className="rad-mosaic__card-description-eyebrow fs-2">Comprehensive Support</div>
                                <h4 className="rad-mosaic__card-description-title fs-5 fw-normal">
                                    Spotcomm Global offers comprehensive support with tailored solutions, expert guidance, and round-the-clock assistance to ensure your business thrives in a seamless and efficient manner.
                                </h4>
                            </div>
                            <div className="rad-mosaic__image-card-image">
                                <div
                                    data-cmp-is="image"
                                    data-cmp-dmimage=""
                                    data-cmp-src="https://preview1.assetsadobe.com/is/image/accenture/1306372406?ts=1726612011714&wid=625&fit=constrain&dpr=off"
                                    data-asset-id="841c1d8e-12f6-4942-9596-cbae40d8e5cb"
                                    data-cmp-filereference="/content/dam/accenture/final/images/photography/person/general-people/1306372406.jpeg"
                                    id="image-928d66528f"
                                    data-cmp-data-layer='{"radimage-928d66528f":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-2"}}'
                                    data-cmp-hook-image="imageV3"
                                    className="cmp-image
     "
                                    itemScope=""
                                    itemType="http://schema.org/ImageObject"
                                >
                                    <img
                                        src="/bus (1).png"
                                        loading="lazy"
                                        className="cmp-image__image"
                                        itemProp="contentUrl"
                                        width={6720}
                                        height={4480}
                                        alt=""
                                    />
                                </div>
                            </div>

                        </button>
                        {/* no image*/}
                        {/* vertical with image or stat card */}
                        {/* no image*/}
                        {/* no image*/}
                        {/* no image*/}
                        {/* vertical with image or stat card */}
                        {/* no image*/}
                        <button
                            aria-haspopup="dialog"
                            aria-controls="mosaic-card-item1-71d46931db4225cf862dfd7290bc3914267308bdee8210df82d65d1b859f88c5"
                            className="rad-mosaic__card rad-mosaic__card--no-image" 
                            data-cmp-clickable="" style={{background: "rgb(34 34 57)" , color: "#fff"}}
                            data-analytics-asset-id="card-aecf452fb3"
                            data-analytics-asset-pos={2}
                            data-cmp-data-layer='{"mosaic-card-item1-71d46931db4225cf862dfd7290bc3914267308bdee8210df82d65d1b859f88c5":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/software-platforms","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-2","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                            data-analytics-skim-click=""
                        >
                            <div className="rad-mosaic__card-description">
                                <div className="rad-mosaic__card-description-eyebrow fs-2">
                                Innovative Technology
                                </div>
                                <h4 className="rad-mosaic__card-description-title fs-5 fw-normal">
                                Spotcomm Global delivers cutting-edge, innovative technology solutions, empowering businesses with advanced tools, seamless integration, and transformative strategies to drive growth and efficiency in a digital world.
                                </h4>
                            </div>

                        </button>

                        <div
                            className="rad-mosaic__card rad-mosaic__image-card rad-mosaic__card--vertical"
                            data-analytics-asset-id="card-ccdc36c5c3"
                            data-analytics-asset-pos={3} style={{background: "#fefefe"}}
                            data-cmp-data-layer='{"mosaic-card-item2-cd1b49480d1159b0f3091ead9a522c995b89ddee95bb37c4a63e966fcc9d7185":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/software-platforms","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-3","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                        >
                            <div className="rad-mosaic__card-description">
                                <div className="rad-mosaic__card-description-eyebrow fs-2">
                                Customized Strategies
                                </div>
                           
                                <h4 className="rad-mosaic__card-description-title fs-5">
                                Spotcomm Global crafts customized strategies tailored to your business needs, delivering personalized solutions, expert insights, and measurable results to drive sustainable growth and lasting success.

                                </h4>
                            </div>
                            <div className="rad-mosaic__image-card-image">
                                <div
                                    data-cmp-is="image"
                                    data-cmp-dmimage=""
                                    data-cmp-src="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-afs-index-mosaic-image1?ts=1721844847908&wid=625&fit=constrain&dpr=off"
                                    data-asset-id="a0f4780f-06bf-48a7-a917-91808fd9bb5a"
                                    data-cmp-filereference="/content/dam/accenture/final/accenture-com/imagery-2/Accenture-afs-index-mosaic-image1.jpg"
                                    id="image-9f04d566f9"
                                    data-cmp-data-layer='{"radimage-9f04d566f9":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-3"}}'
                                    data-cmp-hook-image="imageV3"
                                    className="cmp-image
     "
                                    itemScope=""
                                    itemType="http://schema.org/ImageObject"
                                >
                                    <img
                                        src="/bus (2).png"
                                        loading="lazy"
                                        className="cmp-image__image"
                                        itemProp="contentUrl"
                                        width={3840}
                                        height={2160}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        {/* no image*/}
                        {/* no image*/}
                        {/* no image*/}
                        {/* vertical with image or stat card */}
                        {/* no image*/}
                        {/* vertical with image or stat card */}
                        {/* no image*/}
                        <button
                            aria-haspopup="dialog"
                            aria-controls="mosaic-card-item3-b32eda2f820783063905b54a22a178518af318606d4ba93c9402c0650cea40be"
                            className="rad-mosaic__card rad-mosaic__card--no-image"
                            data-cmp-clickable=""  style={{background: "rgb(34 34 57)" , color: "#fff"}}
                            data-analytics-asset-id="card-e58df3adab"
                            data-analytics-asset-pos={4}
                            data-cmp-data-layer='{"mosaic-card-item3-b32eda2f820783063905b54a22a178518af318606d4ba93c9402c0650cea40be":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/software-platforms","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-4","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                            data-analytics-skim-click=""
                        >
                            <div className="rad-mosaic__card-description">
                                <div className="rad-mosaic__card-description-eyebrow fs-2 text-light">
                                Data-Driven Insights
                                </div>
                                <h4 className="rad-mosaic__card-description-title fs-5 text-light">
                                Spotcomm Global leverages data-driven insights to empower your business, providing actionable analytics, informed decision-making, and tailored strategies that drive efficiency, growth, and long-term success.
                                </h4>
                            </div>

                        </button>
                      
                        {/* <button
                            aria-haspopup="dialog"
                            aria-controls="mosaic-card-item4-aa57b5e5c5d2e8449e5f1ac0799677df3dc741090ea58ad9c21dc7c658aefede"
                            className="rad-mosaic__card rad-mosaic__card--no-image"
                            data-cmp-clickable=""
                            data-analytics-asset-id="card-cec043784c"
                            data-analytics-asset-pos={5}
                            data-cmp-data-layer='{"mosaic-card-item4-aa57b5e5c5d2e8449e5f1ac0799677df3dc741090ea58ad9c21dc7c658aefede":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/software-platforms","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-5","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                            data-analytics-skim-click=""
                        >
                            <div className="rad-mosaic__card-description">
                                <div className="rad-mosaic__card-description-eyebrow fs-2">
                                Scalable Solutions
                                </div>
                                <h4 className="rad-mosaic__card-description-title fs-5">
                                Spotcomm Global provides scalable solutions designed to grow with your business, ensuring flexibility, seamless integration, and efficiency to meet evolving demands and achieve lasting success.
                                </h4>
                            </div>

                        </button> */}
                         <button
                            aria-haspopup="dialog"
                            aria-controls="mosaic-card-item0-00190b0d2ff871a2f02591219e405afa0704cb5eff5dbda70377ffd67d516c9f"
                            className="rad-mosaic__card rad-mosaic__image-card rad-mosaic__card--vertical"
                            data-cmp-clickable=""
                            data-analytics-asset-id="card-a1d20d555b" style={{background: "#fefefe"}}
                            data-analytics-asset-pos={1}
                            data-cmp-data-layer='{"mosaic-card-item0-00190b0d2ff871a2f02591219e405afa0704cb5eff5dbda70377ffd67d516c9f":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/software-platforms","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-1","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                            data-analytics-skim-click=""
                        >
                            <div className="rad-mosaic__card-description">
                                <div className="rad-mosaic__card-description-eyebrow fs-2">
                                Scalable Solutions
                                </div>
                                <h4 className="rad-mosaic__card-description-title fs-5">
                                Spotcomm Global provides scalable solutions designed to grow with your business, ensuring flexibility, seamless integration, and efficiency to meet evolving demands and achieve lasting success.
                                </h4>
                            </div>
                            <div className="rad-mosaic__image-card-image">
                                <div
                                    data-cmp-is="image"
                                    data-cmp-dmimage=""
                                    data-cmp-src="https://preview1.assetsadobe.com/is/image/accenture/1306372406?ts=1726612011714&wid=625&fit=constrain&dpr=off"
                                    data-asset-id="841c1d8e-12f6-4942-9596-cbae40d8e5cb"
                                    data-cmp-filereference="/content/dam/accenture/final/images/photography/person/general-people/1306372406.jpeg"
                                    id="image-928d66528f"
                                    data-cmp-data-layer='{"radimage-928d66528f":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-2"}}'
                                    data-cmp-hook-image="imageV3"
                                    className="cmp-image
     "
                                    itemScope=""
                                    itemType="http://schema.org/ImageObject"
                                >
                                    <img
                                        src="/bus (3).png"
                                        loading="lazy"
                                        className="cmp-image__image"
                                        itemProp="contentUrl"
                                        width={6720}
                                        height={4480}
                                        alt=""
                                    />
                                </div>
                            </div>

                        </button>
                        {/* no image*/}
                        {/* vertical with image or stat card */}
                        {/* no image*/}
                        {/* vertical with image or stat card */}
                        {/* no image*/}
                        {/* no image*/}
                        {/* no image*/}    <button
                            aria-haspopup="dialog"
                            aria-controls="mosaic-card-item0-00190b0d2ff871a2f02591219e405afa0704cb5eff5dbda70377ffd67d516c9f"
                            className="rad-mosaic__card rad-mosaic__image-card rad-mosaic__card--vertical"
                            data-cmp-clickable="" style={{background: "rgb(34 34 57)" , color: "#fff"}}
                            data-analytics-asset-id="card-a1d20d555b"
                            data-analytics-asset-pos={1}
                            data-cmp-data-layer='{"mosaic-card-item0-00190b0d2ff871a2f02591219e405afa0704cb5eff5dbda70377ffd67d516c9f":{"@type":"cio-sites/components/rad/mosaic","xdm:linkURL":"/ae-en/industries/software-platforms","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"mosaic card clicked","analytics-module-name":"mosaic-card-pagination-1","analytics-template-zone":"block-how-to-reinvent-software-and-platforms"}}'
                            data-analytics-skim-click=""
                        >
                            <div className="rad-mosaic__card-description">
                                <div className="rad-mosaic__card-description-eyebrow fs-2">Experienced Team</div>
                                <h4 className="rad-mosaic__card-description-title fs-5">
                                Spotcomm Global boasts an experienced team of industry experts, delivering innovative solutions, personalized support, and strategic guidance to help your business thrive in a competitive landscape.
                                </h4>
                            </div>
                            <div className="rad-mosaic__image-card-image">
                                <div
                                    data-cmp-is="image"
                                    data-cmp-dmimage=""
                                    data-cmp-src="https://preview1.assetsadobe.com/is/image/accenture/1306372406?ts=1726612011714&wid=625&fit=constrain&dpr=off"
                                    data-asset-id="841c1d8e-12f6-4942-9596-cbae40d8e5cb"
                                    data-cmp-filereference="/content/dam/accenture/final/images/photography/person/general-people/1306372406.jpeg"
                                    id="image-928d66528f"
                                    data-cmp-data-layer='{"radimage-928d66528f":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-2"}}'
                                    data-cmp-hook-image="imageV3"
                                    className="cmp-image
     "
                                    itemScope=""
                                    itemType="http://schema.org/ImageObject"
                                >
                                    <img
                                        src="/bus (4).png"
                                        loading="lazy"
                                        className="cmp-image__image"
                                        itemProp="contentUrl"
                                        width={6720}
                                        height={4480}
                                        alt=""
                                    />
                                </div>
                            </div>

                        </button>
                      
             
                     
                      
                   
                    </div>
                </div>
            </div>
      </section>
      <Demo />
    </div>
  )
}

export default Businesssolution