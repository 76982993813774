import React, { useState } from 'react'

const Videocard = ({ title, videoSrc, thumbnail }) => {
    const [isHovered, setIsHovered] = useState(false);
    const videoRef = React.useRef(null);

    // const handleMouseEnter = () => {
    //     setIsHovered(true);
    //     videoRef.current.play();
    // };

    // const handleMouseLeave = () => {
    //     setIsHovered(false);
    //     videoRef.current.pause();
    //     videoRef.current.currentTime = 0; // Reset video to the start
    // };
    return (
        <div>
            
            <div
                className="video-card "
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
            >
                <video
                    ref={videoRef}
                    className={`video `}
                    src={videoSrc}
                    poster={thumbnail}
                    muted
                    loop
                />
                <div className="overlay">
                    <h2 className='text-center fs-3'>{title}</h2>
                    {/* <div className="play-button ">
                        <svg viewBox="0 0 24 24">
                            <path d="M8 5v14l11-7z" />
                        </svg>
                    </div> */}
                </div>
            </div>
           
        </div>
    )
}

export default Videocard