import React , {useState} from 'react'
import "./cyber.css"
import "./case.css"

const Case = () => {
    const blogs = [
        {
          id: 1,
          title: "Cloud Innovation",
          topic: "cloud",
          industry: "technology",
          contentType: "blog",
          image: "https://via.placeholder.com/400x200",
          summary: "Innovations in cloud technology...",
          link: "#",
        },
        {
          id: 2,
          title: "Cybersecurity Trends",
          topic: "cybersecurity",
          industry: "finance",
          contentType: "case-study",
          image: "https://via.placeholder.com/400x200",
          summary: "Key trends in cybersecurity...",
          link: "#",
        },
        {
          id: 3,
          title: "Data AI Revolution",
          topic: "data-ai",
          industry: "technology",
          contentType: "research-report",
          image: "https://via.placeholder.com/400x200",
          summary: "Revolutionizing industries with AI...",
          link: "#",
        },
        // Add more blog objects as needed
      ];
    
      // State to store filter values
      const [filters, setFilters] = useState({
        topic: "",
        industry: "",
        contentType: "",
      });
    
      // Filter handler
      const handleFilterChange = (category, value) => {
        setFilters((prev) => ({ ...prev, [category]: value }));
      };
    
      // Filter blogs based on selected filters
      const filteredBlogs = blogs.filter((blog) => {
        return (
          (!filters.topic || blog.topic === filters.topic) &&
          (!filters.industry || blog.industry === filters.industry) &&
          (!filters.contentType || blog.contentType === filters.contentType)
        );
      });
    return (
        <div>
            <>
            <section className="p-4 bg-white shadow">
        <h2 className="text-xl font-semibold mb-4">Filters</h2>
        <div className="flex flex-wrap gap-4">
          {/* Topic Filter */}
          <div>
            <label className="block text-sm font-medium mb-1">Topic</label>
            <select
              onChange={(e) => handleFilterChange("topic", e.target.value)}
              className="p-2 border rounded"
            >
              <option value="">All Topics</option>
              <option value="cloud">Cloud</option>
              <option value="cybersecurity">Cybersecurity</option>
              <option value="data-ai">Data & AI</option>
            </select>
          </div>

          {/* Industry Filter */}
          <div>
            <label className="block text-sm font-medium mb-1">Industry</label>
            <select
              onChange={(e) => handleFilterChange("industry", e.target.value)}
              className="p-2 border rounded"
            >
              <option value="">All Industries</option>
              <option value="technology">Technology</option>
              <option value="finance">Finance</option>
            </select>
          </div>

          {/* Content Type Filter */}
          <div>
            <label className="block text-sm font-medium mb-1">Content Type</label>
            <select
              onChange={(e) => handleFilterChange("contentType", e.target.value)}
              className="p-2 border rounded"
            >
              <option value="">All Types</option>
              <option value="blog">Blog</option>
              <option value="case-study">Case Study</option>
              <option value="research-report">Research Report</option>
            </select>
          </div>
        </div>
      </section>

      {/* Blog Grid */}
      <section className="p-4">
        <h2 className="text-xl font-semibold mb-4">Results</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredBlogs.length > 0 ? (
            filteredBlogs.map((blog) => (
              <div
                key={blog.id}
                className="border rounded shadow-lg overflow-hidden bg-white"
              >
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="w-full h-40 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-lg font-bold">{blog.title}</h3>
                  <p className="mt-2 text-sm text-gray-600">{blog.summary}</p>
                  <a
                    href={blog.link}
                    className="text-blue-500 hover:underline mt-4 block"
                  >
                    Read More
                  </a>
                </div>
              </div>
            ))
          ) : (
            <p>No results found for the selected filters.</p>
          )}
        </div>
      </section>
                {/* Mirrored from www.accenture.com/us-en/insights-index by HTTrack Website Copier/3.x [XR&CO'2014], Tue, 21 Jan 2025 10:50:25 GMT */}
                {/* Added by HTTrack */}
                <meta httpEquiv="content-type" content="text/html;charset=utf-8" />
                {/* /Added by HTTrack */}
                <meta charSet="UTF-8" />
                <title>What We Think | Business &amp; Technology Insights | Accenture</title>
                <meta name="template" content="rad-template" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta
                    name="google-site-verification"
                    content="KhlpxClRRelKnb69m3_cQX5HFx3VzpOoG8S--eOI_fE"
                />
                <link rel="preconnect" href="http://cdnssl.clicktale.net/" crossOrigin="" />
                <link rel="preconnect" href="http://cdn.cookielaw.org/" crossOrigin="" />
                <link rel="preconnect" href="http://assets.adobedtm.com/" crossOrigin="" />
                <link rel="preconnect" href="http://api.company-target.com/" crossOrigin="" />
                <link rel="preconnect" href="http://dpm.demdex.net/" crossOrigin="" />
                <link rel="preconnect" href="http://play.vidyard.com/" crossOrigin="" />
                <link rel="preconnect" href="http://somni.accenture.com/" crossOrigin="" />
                <link rel="preconnect" href="http://accenture.demdex.net/" crossOrigin="" />
                <link rel="preconnect" href="http://ml314.com/" crossOrigin="" />
                <link rel="preconnect" href="http://cm.everesttech.net/" crossOrigin="" />
                <link rel="preconnect" href="http://target.accenture.com/" crossOrigin="" />
                <link rel="preconnect" href="http://cdnjs.cloudflare.com/" crossOrigin="" />
                <link rel="preconnect" href="http://idsync.rlcdn.com/" crossOrigin="" />
                <link rel="dns-prefetch" href="http://cdn.cookielaw.org/" />
                <link rel="dns-prefetch" href="http://assets.adobedtm.com/" />
                <link rel="dns-prefetch" href="http://api.company-target.com/" />
                <link rel="dns-prefetch" href="http://dpm.demdex.net/" />
                <link rel="dns-prefetch" href="http://play.vidyard.com/" />
                <link rel="dns-prefetch" href="http://somni.accenture.com/" />
                <link rel="dns-prefetch" href="http://accenture.demdex.net/" />
                <link rel="dns-prefetch" href="http://ml314.com/" />
                <link rel="dns-prefetch" href="http://cm.everesttech.net/" />
                <link rel="dns-prefetch" href="http://target.accenture.com/" />
                <link rel="dns-prefetch" href="http://cdnjs.cloudflare.com/" />
                <link rel="dns-prefetch" href="http://idsync.rlcdn.com/" />
                <link rel="dns-prefetch" href="http://cdnssl.clicktale.net/" />
                <link
                    rel="preload"
                    as="font"
                    type="font/woff2"
                    crossOrigin=""
                    href="https://www.accenture.com/etc.clientlibs/cio-sites/clientlibs/clientlib-rad/resources/fonts/graphik-medium-web.woff2"
                />
                <link
                    rel="preload"
                    as="font"
                    type="font/woff2"
                    crossOrigin=""
                    href="https://www.accenture.com/etc.clientlibs/cio-sites/clientlibs/clientlib-rad/resources/fonts/graphik-regular-web.woff2"
                />
                <meta
                    name="title"
                    content="What We Think | Business & Technology Insights | Accenture"
                />
                <meta
                    name="description"
                    content="Accenture thought leadership offers business and technology insights on key market forces & technologies to set your company on the path to value. Learn more."
                />
                <meta
                    name="keywords"
                    content="business insights, technology insights, thought leadership, technology innovation, research insights, innovation insights, what we think, business thought leadership, technology thought leadership, accenture foresight app"
                />
                <meta
                    itemProp="name"
                    content="What We Think | Business & Technology Insights | Accenture"
                />
                <meta
                    itemProp="description"
                    content="Accenture thought leadership offers business and technology insights on key market forces & technologies to set your company on the path to value. Learn more."
                />
                <meta
                    itemProp="image"
                    content="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-What-we-think-gradient-hero?qlt=85&ts=1732105356397&$auto-jpg$&dpr=off"
                />
                <meta name="sniply-options" content="block" />
                <meta name="robots" content="follow,index" />
                <link rel="canonical" href="insights-index.html" />
                <meta property="fb:app_id" content={1391700191159700} />
                <meta
                    property="og:title"
                    content="What We Think | Business & Technology Insights"
                />
                <meta
                    property="og:description"
                    content="Accenture thought leadership offers business and technology insights on key market forces & technologies to set your company on the path to value."
                />
                <meta
                    property="og:image"
                    content="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-What-we-think-gradient-hero%3Asocial-thumbnail-landscape?ts=1732105356525&dpr=off"
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:title"
                    content="What We Think | Business & Technology Insights"
                />
                <meta
                    name="twitter:description"
                    content="Accenture thought leadership offers business and technology insights on key market forces & technologies to set your company on the path to value. Learn more."
                />
                <meta
                    name="twitter:image"
                    content="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-What-we-think-gradient-hero%3Asocial-thumbnail-landscape?ts=1732105356555&dpr=off"
                />
                <link
                    rel="stylesheet"
                    href="../etc.clientlibs/cio-sites/clientlibs/clientlib-base.lc-99e6138fc3579fe6a02605a8fc47cd6b-lc.min.css"
                    type="text/css"
                />
                <link
                    rel="stylesheet"
                    href="../etc.clientlibs/cio-sites/clientlibs/clientlib-rad.lc-bc89da7ef3583d519dab7419da3faff1-lc.min.css"
                    type="text/css"
                />
                <div role="region" aria-label="skip navigation">
                    <a href="#main" className="cmp-skip-link">
                        Skip to main content
                    </a>
                    <a href="#footer" className="cmp-skip-link">
                        Skip to footer
                    </a>
                </div>
                <div className="root  responsivegrid">
                    
                    <main className=" responsivegrid">
                        <div
                            id="main"
                            data-barba="container"
                            data-barba-namespace="container-11081cdd5d"
                            data-pagedata='{"pageData":{"pageTagging":{"careers":{"careerLevel":"","pageCategory":"","skill1":"","skill2":""},"blogs":{"blogName":"","blogTopic":""},"theme":"","growthPriority":"n/a","contentFormat":"n/a","contentType":"n/a","primaryBuyerFunction":"","universal-topic":"","universal-industry":"","universal-contentFormat":"Accenture.com Page","universal-font":"","universal-globalizationMode":"","universal-imagery":"","universal-moduleTypes":"","universal-subSegments":"","universal-pageType":"Navigation","universal-markets":"","universal-entity1":"","universal-entity2":"","universal-entity3":"Cross-Corporate Communications","universal-entity4":"","universal-audience":"","universal-channel":"","universal-ecosystemPartners":"","universal-valueThemes":"","universal-language":" English (United States)","universal-country":"","universal-buyerFunction":"","universal-auditing":"","universal-capabilities":"","universal-clientTopicsIssues":"","universal-corporate":"","universal-contentType":"","universal-filterTypes":"","entityL1":"","entityL2":"","entityL3":"","entityL4":"","industry":""},"authorInfo":{"author":""},"target":{"blockDynamicContent":false},"pageProperties":{"contentDate":"2023-08-16T16:00:00.000Z","siteId":"acn","templateName":"atp:miscellaneous","publishDate":"2024-11-20T12:22:36.712Z","guid":"eddbafc30125fcd9947575a1988da0c4","countryLanguage":"us-en","siteBranch":"insights-index","subFolder":"","tertiaryFolder":"","pageName":"acn:page:insights-index"},"analytics-module-name":"radicalaccenturedesign-4","@type":"cio-sites/components/page-components/radicalaccenturedesign"}}'
                            data-pageid="page-7ef187d00c"
                            className="cmp-container"
                        >
                            <div className="articlehero">
                                <div className="rad-hero-text rad-spacing-vertical-md">
                                    <div className="rad-hero-text__text-content">
                                        <h1 className="rad-hero-text__headline">Stay ahead of change</h1>
                                        <p className="rad-hero-text__subheader">
                                            Explore our research, insights, and examples of real client
                                            impact, designed to help you embrace the key forces of change
                                            and get to value faster.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="filtercardv2 filtercard">
                                {/* Filter Card Component v2*/}
                                <div
                                    className="rad-smart-filter-2"
                                    data-template-zone="block-filtercard"
                                    data-disable-link-conversion="false"
                                    data-current-locale="us-en"
                                    data-resource-path="/content/acom/us-en/insights-index/jcr:content/root/container_main/filtercardv2"
                                    data-current-geo-tag='{"tagPath":"/content/cq:tags/universal/country-codes/core-multi-market/us-en","tagTitle":"US-EN","tagId":"universal:country-codes/core-multi-market/us-en"}'
                                >
                                    <div className="rad-smart-filter-2__filter-mobile-controls">
                                        <div className="rad-smart-filter-2__filter-mobile-controls-header">
                                            <button className="rad-button rad-button--ghost rad-smart-filter-2__filter-mobile-back">
                                                <div
                                                    className="rad-button__icon-left rad-icon rad-icon__chevron-left rad-icon--compact"
                                                    aria-hidden="true"
                                                />
                                                <div className="rad-button__text">Back</div>
                                            </button>
                                            <button
                                                disabled=""
                                                className="rad-button rad-button--ghost rad-smart-filter-2__filter-mobile-clear-all"
                                            >
                                                <div className="rad-button__text">Clear all</div>
                                            </button>
                                        </div>
                                        <div className="rad-smart-filter-2__filter-mobile-controls-filters">
                                            <button
                                                className="rad-smart-filter-2__filter-mobile-controls-filters-heading"
                                                aria-expanded="false"
                                                id="rad-smart-filter-2__mobile-filters-topic-heading"
                                                data-filter-category="topic"
                                            >
                                                <span className="rad-smart-filter-2__filter-mobile-controls-filter-heading-text">
                                                    Topic
                                                    <span className="rad-smart-filter-2__filter-mobile-controls-filters-heading-count" />
                                                </span>
                                            </button>
                                            <div
                                                className="rad-smart-filter-2__filter-mobile-controls-filters-list-wrapper"
                                                data-filter-category="topic"
                                            >
                                                <div
                                                    className="rad-smart-filter-2__filter-mobile-controls-filters-list"
                                                    aria-labelledby="rad-smart-filter-2__mobile-filters-topic-heading"
                                                    aria-multiselectable="true"
                                                    role="listbox"
                                                >
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:topics-capabilities/cloud"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Cloud</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:topics-capabilities/cybersecurity"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Cybersecurity</span>
                                                        </span>
                                                    </button>
                                                  
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:topics-capabilities/emerging-technology"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">
                                                                Emerging Technology
                                                            </span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:topics-capabilities/finance-&-risk-management"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">
                                                                Finance &amp; Risk Management
                                                            </span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:topics-capabilities/infrastructure-and-capital-projects"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">
                                                                Infrastructure and Capital Projects
                                                            </span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:topics-capabilities/learning"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Learning</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:topics-capabilities/marketing-&-experience"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">
                                                                Marketing &amp; Experience
                                                            </span>
                                                        </span>
                                                    </button>
                                             
                                                </div>
                                            </div>
                                            <button
                                                className="rad-smart-filter-2__filter-mobile-controls-filters-heading"
                                                aria-expanded="false"
                                                id="rad-smart-filter-2__mobile-filters-industry-heading"
                                                data-filter-category="industry"
                                            >
                                                <span className="rad-smart-filter-2__filter-mobile-controls-filter-heading-text">
                                                    Industry
                                                    <span className="rad-smart-filter-2__filter-mobile-controls-filters-heading-count" />
                                                </span>
                                            </button>
                                            <div
                                                className="rad-smart-filter-2__filter-mobile-controls-filters-list-wrapper"
                                                data-filter-category="industry"
                                            >
                                                <div
                                                    className="rad-smart-filter-2__filter-mobile-controls-filters-list"
                                                    aria-labelledby="rad-smart-filter-2__mobile-filters-industry-heading"
                                                    aria-multiselectable="true"
                                                    role="listbox"
                                                >
                                                   
                                                   
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:industries-sub-segments-targeted/high-tech"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">High Tech</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:industries-sub-segments-targeted/industrial"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Industrial</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:industries-sub-segments-targeted/insurance"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Insurance</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:industries-sub-segments-targeted/life-sciences"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Life Sciences</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:industries-sub-segments-targeted/natural-resources"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">
                                                                Natural Resources
                                                            </span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:industries-sub-segments-targeted/private-equity"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Private Equity</span>
                                                        </span>
                                                    </button>
                                                  
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:industries-sub-segments-targeted/utilities"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Utilities</span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <button
                                                className="rad-smart-filter-2__filter-mobile-controls-filters-heading"
                                                aria-expanded="false"
                                                id="rad-smart-filter-2__mobile-filters-content type-heading"
                                                data-filter-category="content type"
                                            >
                                                <span className="rad-smart-filter-2__filter-mobile-controls-filter-heading-text"   onChange={(e) => handleFilterChange("contentType", e.target.value)}>
                                                    Content Type
                                                    <span className="rad-smart-filter-2__filter-mobile-controls-filters-heading-count" />
                                                </span>
                                            </button>
                                            <div
                                                className="rad-smart-filter-2__filter-mobile-controls-filters-list-wrapper"
                                                data-filter-category="content type"
                                            >
                                                <div
                                                    className="rad-smart-filter-2__filter-mobile-controls-filters-list"
                                                    aria-labelledby="rad-smart-filter-2__mobile-filters-content type-heading"
                                                    aria-multiselectable="true"
                                                    role="listbox"
                                                >
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:filter-types/blog"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Blog</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:filter-types/case-study"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Case Study</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:filter-types/live-interview"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Live Interview</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:filter-types/news-article"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">News Article</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:filter-types/perspective"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Perspective</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:filter-types/podcast"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Podcast</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="rad-chip"
                                                        data-tag-id="universal:filter-types/research-report"
                                                        role="option"
                                                        aria-selected="false"
                                                        aria-disabled="false"
                                                    >
                                                        <span className="rad-chip__inner">
                                                            <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                            <span className="rad-chip__text">Research Report</span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rad-smart-filter-2__filter-mobile-controls-footer">
                                            <button className="rad-button rad-button--primary rad-smart-filter-2__filter-mobile-show-results">
                                                <div className="rad-button__text">Show results</div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="rad-smart-filter-2__sticky-header">
                                        <button
                                            className="rad-smart-filter-2__filter-mobile-toggle-button"
                                            aria-expanded="false"
                                        >
                                            Filter by topics
                                            <span className="rad-smart-filter-2__filter-mobile-toggle-button-count" />
                                        </button>
                                        <div className="rad-smart-filter-2__filter-toggle-button-container">
                                            <button
                                                className="rad-smart-filter-2__filter-toggle-button"
                                                aria-expanded="false"
                                                id="rad-smart-filter-2__filters-topic-heading"
                                                data-filter-category="topic"
                                            >
                                                Topic
                                                <span className="rad-smart-filter-2__filter-toggle-button-count" />
                                            </button>
                                            <button
                                                className="rad-smart-filter-2__filter-toggle-button"
                                                aria-expanded="false"
                                                id="rad-smart-filter-2__filters-industry-heading"
                                                data-filter-category="industry"
                                            >
                                                Industry
                                                <span className="rad-smart-filter-2__filter-toggle-button-count" />
                                            </button>
                                            <button
                                                className="rad-smart-filter-2__filter-toggle-button"
                                                aria-expanded="false"
                                                id="rad-smart-filter-2__filters-content type-heading"
                                                data-filter-category="content type"
                                            >
                                                Content Type
                                                <span className="rad-smart-filter-2__filter-toggle-button-count" />
                                            </button>
                                        </div>
                                        <div className="rad-smart-filter-2__filter-list-wrapper">
                                            <div
                                                className="rad-smart-filter-2__filter-list"
                                                aria-labelledby="rad-smart-filter-2__filters-topic-heading"
                                                aria-multiselectable="true"
                                                role="listbox"
                                                data-filter-category="topic"
                                            >
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:topics-capabilities/cloud"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Cloud</span>
                                                    </span>
                                                </button>
                                              
                                               
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:topics-capabilities/strategic-managed-services"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">
                                                            Strategic Managed Services
                                                        </span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:topics-capabilities/strategy"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Strategy</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:topics-capabilities/supply-chain"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Supply Chain</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:topics-capabilities/sustainability"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Sustainability</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:topics-capabilities/talent-&-organization"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">
                                                            Talent &amp; Organization
                                                        </span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:topics-capabilities/technology-transformation"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">
                                                            Technology Transformation
                                                        </span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div
                                                className="rad-smart-filter-2__filter-list"
                                                aria-labelledby="rad-smart-filter-2__filters-industry-heading"
                                                aria-multiselectable="true"
                                                role="listbox"
                                                data-filter-category="industry"
                                            >
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:industries-sub-segments-targeted/aerospace-&-defense"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">
                                                            Aerospace &amp; Defense
                                                        </span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:industries-sub-segments-targeted/automotive"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Automotive</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:industries-sub-segments-targeted/banking"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Banking</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:industries-sub-segments-targeted/capital-markets"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Capital Markets</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:industries-sub-segments-targeted/chemicals"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Chemicals</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:industries-sub-segments-targeted/communications-&-media"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">
                                                            Communications &amp; Media
                                                        </span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:industries-sub-segments-targeted/consumer-goods-&-services"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">
                                                            Consumer Goods &amp; Services
                                                        </span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:industries-sub-segments-targeted/energy"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Energy</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:industries-sub-segments-targeted/health"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Health</span>
                                                    </span>
                                                </button>
                                               
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:industries-sub-segments-targeted/utilities"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Utilities</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div
                                                className="rad-smart-filter-2__filter-list"
                                                aria-labelledby="rad-smart-filter-2__filters-content type-heading"
                                                aria-multiselectable="true"
                                                role="listbox"
                                                data-filter-category="content type"
                                            >
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:filter-types/blog"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Blog</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:filter-types/case-study"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Case Study</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:filter-types/live-interview"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Live Interview</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:filter-types/news-article"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">News Article</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:filter-types/perspective"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Perspective</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:filter-types/podcast"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Podcast</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className="rad-chip"
                                                    data-tag-id="universal:filter-types/research-report"
                                                    role="option"
                                                    aria-selected="false"
                                                    aria-disabled="false"
                                                >
                                                    <span className="rad-chip__inner">
                                                        <span className="rad-icon rad-icon__check rad-icon--compact" />
                                                        <span className="rad-chip__text">Research Report</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="rad-smart-filter-2__filter-controls">
                                                <button className="rad-button rad-button--primary rad-smart-filter-2__filter-show-results">
                                                    <div className="rad-button__text">Show results</div>
                                                </button>
                                                <button className="rad-button rad-button--ghost rad-smart-filter-2__filter-clear-all">
                                                    <div className="rad-button__text">Clear all</div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rad-smart-filter-2__results-and-sort">
                                        <div className="rad-dropdown rad-smart-filter-2__sort">
                                            <button
                                                className="rad-dropdown__category"
                                                aria-expanded="false"
                                            >
                                                <span className="rad-dropdown__category-title">
                                                    Sort by{" "}
                                                    <span className="rad-dropdown__category-title-selected-item">
                                                        Editor's picks
                                                    </span>
                                                </span>
                                                <span className="rad-icon rad-icon__chevron-down rad-icon--compact" />
                                            </button>
                                            <ul className="rad-dropdown__list">
                                                <li>
                                                    <button
                                                        className="rad-dropdown__list-item rad-dropdown__list-item--selected"
                                                        data-order-by-id="jcr:content/metadata/rank"
                                                        data-sort-order="asc"
                                                    >
                                                        Editor's picks
                                                        <span className="rad-icon rad-icon__check rad-icon--compact rad-list-item__icon" />
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="rad-dropdown__list-item"
                                                        data-order-by-id="jcr:content/metadata/date"
                                                        data-sort-order="desc"
                                                    >
                                                        Latest
                                                        <span className="rad-icon rad-icon__check rad-icon--compact rad-list-item__icon" />
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="rad-smart-filter-2__cards">
                                        <div className="rad-smart-filter-2__cards-list" />
                                        <button
                                            disabled=""
                                            className="rad-button rad-button--secondary rad-smart-filter-2__cards-load-more"
                                        >
                                            <div className="rad-button__text">Load more</div>
                                        </button>
                                    </div>
                                  
                                </div>
                            </div>
                          
                        </div>
                    </main>
                   
                </div>
                {/* customfooter */}
                {/* Mirrored from www.accenture.com/us-en/insights-index by HTTrack Website Copier/3.x [XR&CO'2014], Tue, 21 Jan 2025 10:51:01 GMT */}
            </>

        </div>
    )
}

export default Case