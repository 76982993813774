import React, { useRef, useState, useCallback } from 'react'
import Header from '../components/Header'
import "./business.css"
import Demo from '../components/Navbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { useMediaQuery } from 'react-responsive';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation, Scrollbar, A11y } from 'swiper/modules';
import { Link } from 'react-router-dom';

const CaseStudies = () => {
    const caseStudies = [
        { title: "ARY Digital Network Case Study", image: "/case4.png" },
        { title: "Bar B Q Tonight Case Study", image: "/case2.png" },
        { title: "Cooperative Computing Case Study", image: "/case4.png" },
        // { title: "Cooperative Computing Case Study", image: "/case1.png"  },
        { title: "Garena Free Fire Case Study", image: "/case5.png" },
        { title: "Dreamworld Resort Case Study", image: "/case6.png" },
        { title: "Family Choice of New York (FCNY) Case Study", image: "/case7.png" },
        { title: "British Council Case Study", image: "/case1.png" }
    ];

    const blogData = [
        {
            to: "/britishcouncil",
            imgSrc: "/case (1).png",
            alt: "Systems Limited MEA partnered with Alfardan Group Perfecting the art of financial consolidation",
            label: "CASE STUDY",
            title: `British Council  Case Study`,
        },
        {
            to: "/bbq",
            imgSrc: "/case (2).png",
            alt: "Transforming IT service management for one of the largest telecoms in Australia",
            label: "CASE STUDY",
            title: `Bar BBQ Tonight  Case Study`,
        },
        {
            to: "/cc",
            imgSrc: "/case12 (3).png",
            alt: "BOP implements digital banking technology to meet the changing needs of its clients",
            label: "CASE STUDY",
            title: "Cooperative Computing Case Study",
        },
        {
            to: "/ary",
            imgSrc: "/case12 (2).png",
            alt: "U Bank is fostering economic development through innovative and user-friendly banking",
            label: "CASE STUDY",
            title: "ARY Digital Network Case Study",
        },
        {
            to: "/freefire",
            imgSrc: "/case12 (4).png",
            alt: "Khushhali Microfinance Bank enhances its digital banking with Temenos and Systems Limited",
            label: "CASE STUDY",
            title: "Garena Free Fire Case Study",
        },
        {
            to: "/dreamworld",
            imgSrc: "/case12 (5).png",
            alt: "Bank of Khyber brings digital transformation across 190+ branches with Systems Limited & Temenos",
            label: "CASE STUDY",
            title: "Dreamworld Resort Case Study",
        },
        {
            to: "/familychoice",
            imgSrc: "/case12 (1).png",
            alt: "An Indonesian bank collaborates with Systems Limited to enhance financial inclusion in its services",
            label: "CASE STUDY",
            title: "Family Choice of New York (FCNY) Case Study",
        },
    ];

    const [currentPage, setCurrentPage] = useState(0);
    const cardsPerPage = 13;

    // Calculate paginated data
    const paginatedData = [];
    for (let i = 0; i < blogData.length; i += cardsPerPage) {
        paginatedData.push(blogData.slice(i, i + cardsPerPage));
    }

    // const handlePageChange = (pageIndex) => {
    //     setCurrentPage(pageIndex);
    // };

    return (
        <div>

            <Header />
            <section id="heros" class="heros1 section dark-background">

                <img src="/case.png" alt="" />

                <div class="container text-center" >
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <h2 className='profh2'>Case Studies</h2>
                            <h4>Real Solutions, Real Results</h4>
                        </div>
                    </div>
                </div>

            </section>
            <section
                id="testimonials-section sect-paddings"
                className="testimonials-section  at-bg-cover d-flex  align-items-center paragraph paragraph--type--quote paragraph--view-mode--default"
                style={{ background: "url(/feature2.png)" }}
            >
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">
                        <div
                            className="col-12 col-lg-10 at-maxw-1075"
                        // style={{ background: "#362358" , fontFamily: "proxima-nova" ,lineHeight: "1.5" }}
                        >
                            <blockquote className="text-start ">
                                <div className="svg-holder">
                                    <svg
                                        width={107}
                                        height={71}
                                        viewBox="0 0 107 71"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M107 51.8969C107 57.2033 104.965 61.7668 100.895 65.5875C97.039 69.1958 92.2192 71 86.4354 71C78.5095 71 71.8689 68.2407 66.5135 62.722C61.3724 57.2033 58.8018 50.0927 58.8018 41.3901C58.8018 25.4709 65.014 14.1151 77.4384 7.32287C86.4354 2.44096 93.7187 0 99.2883 0C101.645 0 102.823 0.84903 102.823 2.54709C102.823 4.03288 101.538 5.09417 98.967 5.73094C81.1872 10.4006 72.2973 19.2093 72.2973 32.157C72.2973 37.8879 73.7968 42.7698 76.7958 46.8027C77.2242 39.5859 81.8298 35.9776 90.6126 35.9776C95.3253 35.9776 99.1812 37.5695 102.18 40.7534C105.393 43.725 107 47.4395 107 51.8969ZM47.8769 51.8969C47.8769 57.2033 45.8418 61.7668 41.7718 65.5875C37.9159 69.1958 33.2032 71 27.6336 71C19.4935 71 12.8529 68.2407 7.71171 62.722C2.57057 56.991 0 49.7743 0 41.0718C0 25.3647 6.21221 14.1151 18.6366 7.32287C27.6336 2.44096 34.8098 0 40.1652 0C42.5215 0 43.6997 0.84903 43.6997 2.54709C43.6997 4.03288 42.4144 5.09417 39.8438 5.73094C22.2783 10.4006 13.4955 19.3154 13.4955 32.4753C13.4955 37.994 14.995 42.7698 17.994 46.8027C18.4224 39.5859 22.9209 35.9776 31.4895 35.9776C36.2022 35.9776 40.0581 37.5695 43.0571 40.7534C46.2703 43.725 47.8769 47.4395 47.8769 51.8969Z"
                                            fill="#161616"
                                        />
                                    </svg>
                                </div>
                                <h4 className="fw-normal mt-md-5 mt-3 mb-4 at-lh-1pt7">
                                    <p className='fs-6'>
                                        At Spotcomm, we take pride in delivering tailored network solutions that empower businesses to thrive in a connected world. Our case studies showcase the challenges we’ve tackled and the tangible results we’ve achieved for our clients across diverse industries. Each story highlights how our expertise in managed network services has driven success, whether it’s improving operational efficiency, boosting network security, or enabling seamless scalability.
                                    </p>
                                </h4>

                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className='background ' style={{ background: "#362358" , fontFamily: "proxima-nova" ,lineHeight: "1.5" }} >
                <div className='row text-center'>
                    <div className='col-lg-12 col-sm-12 '>
                        <p className='text-center para mx-auto text-light   fw-bold' style={{fontSize: "23px"} }>At Spotcomm, we take pride in delivering tailored network solutions that empower businesses to thrive in a connected world. Our case studies showcase the challenges we’ve tackled and the tangible results we’ve achieved for our clients across diverse industries. Each story highlights how our expertise in managed network services has driven success, whether it’s improving operational efficiency, boosting network security, or enabling seamless scalability.
                        </p>
                    </div>
                </div>

            </div> <br /><br /> */}
            {/* <h2 className='h2 text-center fw-bold'> <span className='' style={{ color: "#7F3E98" }}>Case Studies </span>  By Category</h2><br />

                <div className="case-studies-grid">
                    {caseStudies.map((study, index) => (
                        <div className="case-study " key={index}>
                            <div
                                className="case-study-image"
                                style={{ backgroundImage: `url(${study.image})` }}
                            ></div>
                            <div className="case-study-overlay">
                                <h2 className='text-light'>{study.title}</h2>
                            </div>
                        </div>
                    ))}
                </div> */}

            <div style={{ background: "#E1E0E0" }}>
                <div className="views-element-container">
                    <div
                        className="js-view-dom-id-9ad579cf0bc69401542353bf258e3a4847dc8630674e11364396bfa2bddb22e5"
                        data-once="ajax-pager"
                    >
                        {/* Career hero banner  */}
                        {/* <section id="services-hero" className="services-hero-section">
                            <div
                                className="img-banner img-banner-h100vh img-banner-industries-telco"
                                style={{
                                    backgroundImage:
                                        "url(/themes/custom/sysltd/assets/images/blog-details/bg-banner-blog.webp)"
                                }}
                            />
                            <div className="container">
                                <div
                                    className="row text-white"
                                    data-anijs="if: load, on: window, do: fadeInUpSmall animated, before: scrollReveal"
                                >
                                 
                                    <div className="col-md-12 text-start">
                                        <div className="services-hero services-hero--mobilefull">
                                            <div className="services-hero-display mb-0">
                                                <h2>  Case Studies By Category</h2>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}
                        <br /> <br />
                        <div className="rad-header rad-header__headline-medium">
                            <div
                                className="rad-header__wrapper"
                                data-analytics-template-zone="block-reinvent-with-cybersecurity"
                            >
                                <div className="rad-header__text-container d-flex justify-content-center mx-auto">
                                    <h2 className="rad-header__headline text-dark text-center d-flex justify-content-center mx-auto">

                                        <span className="mx-3">Case Studies  </span> 
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <section id="blog-section" className="blog-section blog-landing-page" >
                            <div className="container">
                                <div className="row">
                                    <Swiper
                                        modules={[Autoplay]}
                                        spaceBetween={30}
                                        slidesPerView={1}

                                        loop={true}
                                        autoplay={{
                                            delay: 0, // Continuous autoplay without a delay
                                            disableOnInteraction: false, // Keeps autoplay even when interacting
                                            pauseOnMouseEnter: false,
                                        }}
                                        speed={3000} // Adjust speed for smoothness (in ms)
                                        breakpoints={{
                                            640: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 20,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 30,
                                            },
                                        }} >
                                        <div className="container services-section1">
                                            <div className="services-grid " >
                                                {paginatedData[currentPage].map((blog, index) => (

                                                    <SwiperSlide key={index}>

                                                        <a
                                                            to={blog.to}
                                                            className="col-lg-4 blog-col-anch"
                                                            key={index}
                                                        > <br />

                                                            <div key={index} className=" service-card1 case  bg-white" data-aos="fade-right" data-aos-delay="100">
                                                                <img src={blog.imgSrc} />
                                                                <div className='p-2'>
                                                                    <h6 className="blog-label text-center mx-auto mt-3">{blog.label}</h6>
                                                                    <h4 className="blog-title fs-6 text-center mt-3">{blog.title}</h4>
                                                                    <p className='text-start'>{blog.description}</p>
                                                                    <Link to={blog.to}>  <button className="btns21  
                 
                 d-block mx-auto" >Read More</button></Link>
                                                                </div>
                                                            </div>

                                                            {/* <div className="">
                                                                <img
                                                                    src={blog.imgSrc}
                                                                    className="mx-auto"
                                                                    alt={blog.alt}
                                                                />
                                                            </div>
                                                            <h6 className="blog-label text-center mx-auto mt-3">{blog.label}</h6>
                                                            <h4 className="blog-title fs-6 text-center mt-3">{blog.title}</h4>
                                                            <Link to={blog.to}> <div className="btn12 text-center p-1 text-light mx-auto mt-3" style={{ background: "#7F3E98", border: "none", borderRadius: "10px" }}>
                                                                READ MORE<span className="arrow-right">&nbsp;</span>
                                                            </div></Link> */}
                                                        </a>
                                                    </SwiperSlide>
                                                ))}
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>

                            </div>
                        </section>

                        {/* <div className="container services-section1">
                            <div className="services-grid " >
                                {solutions.map((service, index) => (
                                    <div key={index} className=" service-card1  " data-aos="fade-right" data-aos-delay="100">
                                        <img src={service.imgSrc} alt={service.title} />
                                        <div className='p-2'>
                                            <h3 className='text-start'>{service.title}</h3>
                                            <p className='text-start'>{service.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>

            <Demo />
        </div>
    )
}

export default CaseStudies