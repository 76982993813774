import React from "react";
import "./ServicesSection.css";

const services = [
  {
    title: "Proven Track Record",
    description: "Trusted by thousands, our proven record showcases consistent success and unmatched reliability every time.",
    image: "/cloud (2).png", // Replace with your image path
  },
  {
    title: "Cloud Options",
    description: "Flexible cloud options tailored to your needs. Ensure seamless performance, security, and scalability anytime.",
    image: "/cloud (3).png", // Replace with your image path
  },
  {
    title: "One Umbrella Solution",
    description: "Comprehensive one-in-one solutions to address critical requirements, streamline efficiency, and ensure success.",
    image: "/cloud (4).png", // Replace with your image path
  },
  {
    title: "Smooth On-Prem to Cloud Migration Services",
    description: "Inclusive on-prem to cloud migration services ensuring seamless data transfer with minimal downtime and optimized performance.",
    image: "/cloud (6).png", // Replace with your image path
  },
  {
    title: "24/7 Monitoring Services",
    description: "Round-the-clock 24/7 monitoring services ensuring robust security, minimizing downtime, and peace of mind for businesses.",
    image: "/cloud (5).png", // Replace with your image path
  },
  {
    title: "Expert Consultation Services",
    description: "Industry-leading expert consultation services offering tailored strategies, innovative solutions, and actionable insights for success.",
    image: "/cloud (1).png", // Replace with your image path
  },
];

const ServicesSection = () => {
  return (
    <section className="services-section1 container">
      {/* <h2>
        Why choose <span className="highlight">Spotcomm Cloud Matrix</span> Services
      </h2> */}
      <div className="services-grid">
        {services.map((service, index) => (
          <div className="service-card1" key={index}>
            <img src={service.image} alt={service.title} /> <br />
            <h3 className="text-start fw-bold mx-3" style={{  color:"#7F3E98"}}>{service.title}</h3>
            <p className="text-start mx-3">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
