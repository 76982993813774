import React from 'react'
import "./cyber.css"
import Demo from './Navbar'
import Header from './Header'

const CyberSecurity = () => {
    return (
        <div>
            <>


                <meta
                    name="title"
                    content="Cybersecurity Consulting Services & Strategies | Accenture"
                />
                <meta
                    name="description"
                    content="Accenture offers cybersecurity consulting services & strategy to embed security across your ecosystem to reduce risk in the future by securing today. Learn more."
                />
                <meta
                    name="keywords"
                    content="cybersecurity consulting, cybersecurity consulting services, cybersecurity services, cybersecurity strategies, cybersecurity solutions, cybersecurity consulting solutions"
                />
                <meta
                    itemProp="name"
                    content="Cybersecurity Consulting Services & Strategies | Accenture"
                />
                <meta
                    itemProp="description"
                    content="Accenture offers cybersecurity consulting services & strategy to embed security across your ecosystem to reduce risk in the future by securing today. Learn more."
                />

                <meta name="sniply-options" content="block" />



                <div className="root  responsivegrid">
                    <Header />
                    {/* <section id="heros" class="heros1 section dark-background" data-aos="fade-right" data-aos-delay="500">

                        <img src="/cyber.png" alt="" />

                        <div class="container text-light" >
                            <div class="row justify-content-center">
                                <div class="col-lg-8">
                                    <h2 className='profh2'>Cyber Security</h2>
                                </div>
                            </div>
                        </div>

                    </section> */}
                    <section className="banner-area banner-area-three jarallax">
                        <div className="d-table" bis_skin_checked={1}>
                            <div className="d-table-cell" bis_skin_checked={1}>
                                <div className="container" bis_skin_checked={1}>
                                    <div className="banner-text" bis_skin_checked={1}>
                                        {/* <span
                                            className="wow animate__ animate__fadeInDown animated"
                                            data-wow-delay="1s"
                                            style={{
                                                visibility: "visible",
                                                animationDelay: "1s",
                                                animationName: "fadeInDown"
                                            }}
                                        >
                                            All Research up to Blockchain Interoperability is completed
                                        </span> */}
                                        <h1
                                            className="wow animate__ animate__fadeInLeft animated"
                                            data-wow-delay="1s"
                                            style={{
                                                visibility: "visible",
                                                animationDelay: "1s",
                                                animationName: "fadeInLeft"
                                            }}
                                        >
                                            Optimized Cyber Defense for Critical Infrastructure
                                        </h1>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="banner-shape-1" bis_skin_checked={1}>
                            <img src="/cybersec.png" alt="Image" />
                        </div> */}
                        <div className="banner-shape-2" bis_skin_checked={1}>
                            <img src="/cyberse.png" alt="Image" />
                        </div>
                        <div className="banner-shape-3" bis_skin_checked={1}>
                            <img src="/cyberse2.png" alt="Image" />
                        </div>
                    </section>


                    <main className=" responsivegrid">
                        <div
                            id="main"
                            data-barba="container"
                            data-barba-namespace="container-22711df645"
                            data-pagedata='{"pageData":{"pageTagging":{"careers":{"careerLevel":"","pageCategory":"","skill1":"","skill2":""},"blogs":{"blogName":"","blogTopic":""},"theme":"","growthPriority":"n/a","contentFormat":"n/a","contentType":"n/a","primaryBuyerFunction":"","entityL1":"","entityL2":"","entityL3":"","entityL4":"","industry":"","universal-topic":"Cybersecurity","universal-industry":"","universal-font":"","universal-contentFormat":"Accenture.com Page","universal-globalizationMode":"","universal-imagery":"","universal-moduleTypes":"","universal-subSegments":"","universal-pageType":"Navigation","universal-markets":"United States","universal-entity1":"Services","universal-entity2":"Technology","universal-entity3":"Security","universal-entity4":"","universal-audience":"","universal-channel":"","universal-ecosystemPartners":"","universal-valueThemes":"Reducing risk and building a safer organization through security","universal-language":" English (United States)","universal-country":"","universal-buyerFunction":"Security (CISO) Risk (CRO) Compliance (CCO)","universal-auditing":"","universal-capabilities":"","universal-clientTopicsIssues":"","universal-corporate":"","universal-contentType":"Capabilities","universal-filterTypes":""},"authorInfo":{"author":""},"target":{"blockDynamicContent":false},"pageProperties":{"contentDate":"2024-12-05T16:00:00.000Z","siteId":"acn","templateName":"atp:miscellaneous","publishDate":"2025-01-07T17:35:03.252Z","guid":"1a927a44c36aa293f526f23ece8339a8","countryLanguage":"us-en","siteBranch":"services","subFolder":"","tertiaryFolder":"","pageName":"acn:services:cybersecurity"},"analytics-module-name":"radicalaccenturedesign-4","@type":"cio-sites/components/page-components/radicalaccenturedesign"}}'
                            data-pageid="page-cc637de9f2"
                            className="cmp-container"
                        >

                            <div className="heroimage d-flex align-items-center">
                                <div
                                    className="rad-hero-image rad-spacing-vertical-md d-flex align-items-center"
                                    id="heroimage-008b702c27"
                                    data-cmp-data-layer='{"heroimage-008b702c27":{"@type":"cio-sites/components/rad/heroimage","analytics-module-name":"heroimage-1","analytics-template-zone":"hero"}}'
                                >
                                    <div className="rad-hero-image__photo">
                                        <div
                                            data-cmp-is="image"
                                            data-cmp-dmimage=""
                                            data-cmp-src="https://dynamicmedia.accenture.com/is/content/accenture/Accenture-security-index-illustration-hero-image-1?ts=1736271299639&&fmt=webp-alpha&dpr=off"
                                            data-asset-id="59d85b27-9f3c-41a3-b883-eff96d195023"
                                            data-cmp-filereference="/content/dam/accenture/final/images/illustrations/Accenture-security-index-illustration-hero-image.svg"
                                            id="image-8776df4ecb"
                                            data-cmp-data-layer='{"radimage-8776df4ecb":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Accenture-security-index-illustration-hero-image.svg","analytics-module-name":"radimage-1"}}'
                                            data-cmp-hook-image="imageV3"
                                            className="cmp-image
     "
                                            itemScope=""
                                            itemType="http://schema.org/ImageObject"
                                        >
                                            <img
                                                src="/cyber25.png"
                                                loading="lazy"
                                                className="cmp-image__image"
                                                itemProp="contentUrl"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="rad-hero-image__content">
                                        <h1 className="rad-hero-image__headline text-light">
                                            De-risk tomorrow by boosting cybersecurity today
                                        </h1>
                                        <h2 className="rad-hero-image__body text-light">
                                            Infuse cybersecurity into your strategy and ecosystem to protect
                                            value, help prevent threats and build trust as you grow.
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="statstakeaway">
                                {/* Stats Takeaway */}
                                <div
                                    id="block-cybersecurity-now"
                                    data-cmp-data-layer='{"block-cybersecurity-now":{"@type":"cio-sites/components/rad/statstakeaway","analytics-module-name":"statstakeaway-1","analytics-template-zone":"block-cybersecurity-now"}}'
                                >
                                    <div className="rad-absorb-stats rad-spacing-vertical-md">
                                        <h3 className="rad-absorb-stats__title text-light">Cybersecurity now</h3>
                                        <div className="rad-absorb-stats__cards-container">
                                            <div className="rad-absorb-stats__card">
                                                <div className="rad-absorb-stats__card-text">
                                                    <p className="rad-absorb-stats__card-stat text-light">99.8%</p>
                                                    <div className="rad-absorb-stats__card-detail text-light">
                                                        <p> Industries such as finance, healthcare, and energy are primary targets, contributing to the exponential growth in associated costs.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rad-absorb-stats__card">
                                                <div className="rad-absorb-stats__card-text">
                                                    <p className="rad-absorb-stats__card-stat text-light">86%</p>
                                                    <div className="rad-absorb-stats__card-detail text-light">
                                                        <p>
                                                            of Business Leaders Believe Global Geopolitical Instability Will Lead to a Catastrophic Cyber Event in the Next Two Years
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rad-absorb-stats__card">
                                                <div className="rad-absorb-stats__card-text">
                                                    <p className="rad-absorb-stats__card-stat text-light">200%</p>
                                                    <div className="rad-absorb-stats__card-detail text-light">
                                                        <p>levels of disruption increased from 2017 to 2025</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mosaicv2">
                                <div
                                    data-cmp-data-layer='{"block-reinvent-with-cybersecurity":{"@type":"cio-sites/components/rad/mosaicv2","analytics-module-name":"mosaicv2-1","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                    id="block-reinvent-with-cybersecurity"
                                    className="rad-mosaic-2 rad-mosaic-2--7-card"
                                >
                                    <div className="rad-header rad-header__headline-medium">
                                        <div
                                            className="rad-header__wrapper"
                                            data-analytics-template-zone="block-reinvent-with-cybersecurity"
                                        >
                                            <div className="rad-header__text-container">
                                                <h2 className="rad-header__headline text-light">
                                                    Reinvent with cybersecurity
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rad-mosaic-2__grid">
                                        {/* horizontal with image or stat-card */}
                                        <div
                                            className="rad-mosaic-2__card rad-mosaic-2__card--mobile rad-mosaic-2__card--horizontal rad-mosaic-2__card--image"
                                            data-analytics-asset-id="card-a2e851b4c1"
                                            data-analytics-asset-pos={1}
                                            data-analytics-skim-click=""
                                        >
                                            {/* DEV NOTE: aria-label text is copied from __eyebrow, __title, and __card-image's `alt` below */}
                                            <button
                                                aria-controls="mosaic-card-item0-49699ede14ba24f991b7f30eb5e135d0e482c7bdb6065277c6fdc74a101a3821"
                                                aria-expanded="false"
                                                aria-label="Learn more: Design cyber risk strategies, aligned to business priorities"
                                                className="rad-mosaic-2__card-front-toggle"
                                            />
                                            {/* DEV NOTE: aria-label text is copied from __card-body and rad-button--tertiary below */}
                                            <div
                                                c className="rad-mosaic-2__card-cta-cover"
                                                tabIndex={-1}
                                                aria-label="Learn more: Design cyber risk strategies, aligned to business priorities"
                                                target="_self"
                                                data-analytics-asset-id="card-a2e851b4c1"
                                                data-analytics-asset-pos={1}
                                                data-barba-transition=""
                                                data-cmp-clickable=""
                                                data-cmp-data-layer='{"mosaic-card-item0-49699ede14ba24f991b7f30eb5e135d0e482c7bdb6065277c6fdc74a101a3821":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-strategy","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Strategy","analytics-module-name":"mosaicv2card-1","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                            />
                                            <div
                                                aria-hidden="true"
                                                className="rad-icon-button rad-mosaic-2__card-close-button"
                                                tabIndex={-1}
                                            >
                                                <div className="rad-icon-button__text">Close</div>
                                                <div className="rad-icon rad-icon__close" />
                                            </div>
                                            <div aria-hidden="true" className="rad-mosaic-2__card-eyebrow text-light">
                                                Cyber Strategy
                                            </div>
                                            <div
                                                aria-hidden="true"
                                                className="rad-mosaic-2__card-sliding-content"
                                            >
                                                <div className="rad-mosaic-2__card-front-content">
                                                    <h3 className="rad-mosaic-2__card-title text-light">
                                                        Design and operationalize a secure business strategy to
                                                        protect value 
                                                    </h3>
                                                    <div className="rad-mosaic-2__card-image">
                                                        <div
                                                            data-cmp-is="image"
                                                            data-cmp-dmimage=""
                                                            data-cmp-src="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-security-index-mosaic-image1?ts=1726844953023&wid=625&dpr=off"
                                                            data-asset-id="e6bc36b7-72cd-4e67-b3f3-35046d6c776a"
                                                            data-cmp-filereference="/content/dam/accenture/final/accenture-com/imagery-2/Accenture-security-index-mosaic-image1.jpg"
                                                            id="image-18e7585186"
                                                            data-cmp-data-layer='{"radimage-18e7585186":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-2"}}'
                                                            data-cmp-hook-image="imageV3"
                                                            className="cmp-image
     "
                                                            itemScope=""
                                                            itemType="http://schema.org/ImageObject"
                                                        >
                                                            <img
                                                                src="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-security-index-mosaic-image1?ts=1726844953023&wid=625&dpr=off"
                                                                loading="lazy"
                                                                className="cmp-image__image"
                                                                itemProp="contentUrl"
                                                                width={3841}
                                                                height={2160}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="rad-mosaic-2__card-back-content">
                                                    <div className="rad-mosaic-2__card-body">
                                                        Cybersecurity isn’t just an IT issue – it's a critical
                                                        business priority. Integrating your cybersecurity strategy
                                                        with business goals is essential for driving reinvention
                                                        and growth.
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="rad-mosaic-2__card rad-mosaic-2__card--desktop rad-mosaic-2__card--horizontal rad-mosaic-2__card--image"
                                            c target="_self"
                                            data-analytics-asset-id="card-a2e851b4c1"
                                            data-analytics-asset-pos={1}
                                            data-barba-transition=""
                                            data-cmp-clickable=""
                                            data-analytics-skim-hover=""
                                            data-cmp-data-layer='{"mosaic-card-item0-49699ede14ba24f991b7f30eb5e135d0e482c7bdb6065277c6fdc74a101a3821":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-strategy","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Strategy","analytics-module-name":"mosaicv2card-1","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                        >
                                            <div
                                                className="rad-mosaic-2__card-description-content"
                                                data-analytics-asset-id="card-a2e851b4c1"
                                                data-analytics-asset-pos={1}
                                            >
                                                <div className="rad-mosaic-2__card-eyebrow text-light">
                                                    Cyber Strategy
                                                </div>
                                                <div className="rad-mosaic-2__card-sliding-title">
                                                    <h3 className="rad-mosaic-2__card-title text-light">
                                                        Design and operationalize a secure business strategy to
                                                        protect value 
                                                    </h3>
                                                    <div className="rad-mosaic-2__card-body">
                                                        Cybersecurity isn’t just an IT issue – it's a critical
                                                        business priority. Integrating your cybersecurity strategy
                                                        with business goals is essential for driving reinvention
                                                        and growth.
                                                    </div>
                                                </div>

                                            </div>
                                            <div
                                                className="rad-mosaic-2__card-figure"
                                                data-analytics-asset-id="card-a2e851b4c1"
                                                data-analytics-asset-pos={1}
                                            >
                                                <div className="rad-mosaic-2__card-image">
                                                    <div
                                                        data-cmp-is="image"
                                                        data-cmp-dmimage=""
                                                        data-cmp-src="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-security-index-mosaic-image1?ts=1726844953023&wid=625&dpr=off"
                                                        data-asset-id="e6bc36b7-72cd-4e67-b3f3-35046d6c776a"
                                                        data-cmp-filereference="/content/dam/accenture/final/accenture-com/imagery-2/Accenture-security-index-mosaic-image1.jpg"
                                                        id="image-18e7585186"
                                                        data-cmp-data-layer='{"radimage-18e7585186":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-2"}}'
                                                        data-cmp-hook-image="imageV3"
                                                        className="cmp-image
     "
                                                        itemScope=""
                                                        itemType="http://schema.org/ImageObject"
                                                    >
                                                        <img
                                                               src="/cyber27.png"
                                                            loading="lazy"
                                                            className="cmp-image__image"
                                                            itemProp="contentUrl"
                                                            width={3841}
                                                            height={2160}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* vertical with image or stat card */}
                                        {/* no image*/}
                                        {/* vertical with image or stat card */}
                                        {/* no image*/}
                                        {/* no image*/}
                                        {/* no image*/}
                                        {/* horizontal with image or stat-card */}
                                        {/* vertical with image or stat card */}
                                        <div
                                            className="rad-mosaic-2__card rad-mosaic-2__card--mobile rad-mosaic-2__card--vertical rad-mosaic-2__card--image"
                                            data-analytics-asset-id="card-2cdbc66562"
                                            data-analytics-asset-pos={2}
                                            data-analytics-skim-click=""
                                        >
                                            {/* DEV NOTE: aria-label text is copied from __eyebrow, __title, and __card-image's `alt` below */}
                                            <button
                                                className="rad-mosaic-2__card-front-toggle"
                                                aria-expanded="false"
                                                aria-controls="mosaic-card-item1-9b2845c8f7209ebd347f0888b97301b549cb03cf259d94bc4932793ec3c381c7"
                                                aria-label="Learn more: Understand new threats, prepare and respond quickly to attacks"
                                            />
                                            {/* DEV NOTE: aria-label text is copied from __card-body and rad-button--tertiary below */}
                                            <div
                                                cy className="rad-mosaic-2__card-cta-cover"
                                                tabIndex={-1}
                                                aria-label="Learn more: Understand new threats, prepare and respond quickly to attacks"
                                                target="_self"
                                                data-barba-transition=""
                                                data-cmp-clickable=""
                                                data-analytics-asset-id="card-2cdbc66562"
                                                data-analytics-asset-pos={2}
                                                data-cmp-data-layer='{"mosaic-card-item1-9b2845c8f7209ebd347f0888b97301b549cb03cf259d94bc4932793ec3c381c7":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-resilience","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Resilience","analytics-module-name":"mosaicv2card-2","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                            />
                                            <div
                                                className="rad-icon-button rad-mosaic-2__card-close-button"
                                                tabIndex={-1}
                                                aria-hidden="true"
                                            >
                                                <div className="rad-icon-button__text">Close</div>
                                                <div className="rad-icon rad-icon__close" />
                                            </div>
                                            <div className="rad-mosaic-2__card-eyebrow text-light" aria-hidden="true">
                                                Cyber Resilience
                                            </div>
                                            <div
                                                className="rad-mosaic-2__card-sliding-content"
                                                aria-hidden="true"
                                            >
                                                <div className="rad-mosaic-2__card-front-content">
                                                    <h3 className="rad-mosaic-2__card-title text-light">
                                                        Ready to respond quickly to unseen security threats and
                                                        new regulations?
                                                    </h3>
                                                    <div className="rad-mosaic-2__card-image">
                                                        <div
                                                            data-cmp-is="image"
                                                            data-cmp-dmimage=""
                                                            data-cmp-src="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-afs-index-mosaic-image1?ts=1721844847908&wid=625&dpr=off"
                                                            data-asset-id="a0f4780f-06bf-48a7-a917-91808fd9bb5a"
                                                            data-cmp-filereference="/content/dam/accenture/final/accenture-com/imagery-2/Accenture-afs-index-mosaic-image1.jpg"
                                                            id="image-fbc5260e11"
                                                            data-cmp-data-layer='{"radimage-fbc5260e11":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-3"}}'
                                                            data-cmp-hook-image="imageV3"
                                                            className="cmp-image
     "
                                                            itemScope=""
                                                            itemType="http://schema.org/ImageObject"
                                                        >
                                                            <img
                                                                src="/cyber26.png"
                                                                loading="lazy"
                                                                className="cmp-image__image"
                                                                itemProp="contentUrl"
                                                                width={3840}
                                                                height={2160}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="rad-mosaic-2__card-back-content">
                                                    <div className="rad-mosaic-2__card-body">
                                                        74% of CEOs worry about their ability to minimize
                                                        cyberattacks. Organizations must adapt swiftly and scale
                                                        operations with real-time insights. Discover how to
                                                        identify threats and respond fast.
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            cy className="rad-mosaic-2__card rad-mosaic-2__card--desktop rad-mosaic-2__card--vertical rad-mosaic-2__card--image"
                                            target="_self"
                                            data-barba-transition=""
                                            data-cmp-clickable=""
                                            data-analytics-asset-id="card-2cdbc66562"
                                            data-analytics-asset-pos={2}
                                            data-analytics-skim-hover=""
                                            data-cmp-data-layer='{"mosaic-card-item1-9b2845c8f7209ebd347f0888b97301b549cb03cf259d94bc4932793ec3c381c7":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-resilience","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Resilience","analytics-module-name":"mosaicv2card-2","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                        >
                                            <div
                                                className="rad-mosaic-2__card-description-content"
                                                data-analytics-asset-id="card-2cdbc66562"
                                                data-analytics-asset-pos={2}
                                            >
                                                <div className="rad-mosaic-2__card-eyebrow text-light">
                                                    Cyber Resilience
                                                </div>
                                                <div className="rad-mosaic-2__card-sliding-title">
                                                    <h3 className="rad-mosaic-2__card-title text-light">
                                                        Ready to respond quickly to unseen security threats and
                                                        new regulations?
                                                    </h3>
                                                    <div className="rad-mosaic-2__card-body">
                                                        74% of CEOs worry about their ability to minimize
                                                        cyberattacks. Organizations must adapt swiftly and scale
                                                        operations with real-time insights. Discover how to
                                                        identify threats and respond fast.
                                                    </div>
                                                </div>

                                            </div>
                                            <div
                                                className="rad-mosaic-2__card-figure"
                                                data-analytics-asset-id="card-2cdbc66562"
                                                data-analytics-asset-pos={2}
                                            >
                                                <div className="rad-mosaic-2__card-image">
                                                    <div
                                                        data-cmp-is="image"
                                                        data-cmp-dmimage=""
                                                        data-cmp-src="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-afs-index-mosaic-image1?ts=1721844847908&wid=625&dpr=off"
                                                        data-asset-id="a0f4780f-06bf-48a7-a917-91808fd9bb5a"
                                                        data-cmp-filereference="/content/dam/accenture/final/accenture-com/imagery-2/Accenture-afs-index-mosaic-image1.jpg"
                                                        id="image-fbc5260e11"
                                                        data-cmp-data-layer='{"radimage-fbc5260e11":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-3"}}'
                                                        data-cmp-hook-image="imageV3"
                                                        className="cmp-image
     "
                                                        itemScope=""
                                                        itemType="http://schema.org/ImageObject"
                                                    >
                                                        <img
                                                           src="/cyber26.png"
                                                            loading="lazy"
                                                            className="cmp-image__image"
                                                            itemProp="contentUrl"
                                                            width={3840}
                                                            height={2160}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* no image*/}
                                        {/* vertical with image or stat card */}
                                        {/* no image*/}
                                        {/* no image*/}
                                        {/* no image*/}
                                        {/* horizontal with image or stat-card */}
                                        {/* vertical with image or stat card */}
                                        {/* no image*/}
                                        <div
                                            className="rad-mosaic-2__card rad-mosaic-2__card--mobile"
                                            data-analytics-asset-id="card-3a77aa9043"
                                            data-analytics-asset-pos={3}
                                            data-analytics-skim-click=""
                                        >
                                            {/* DEV NOTE: aria-label text is copied from __eyebrow and __title below */}
                                            <button
                                                className="rad-mosaic-2__card-front-toggle"
                                                aria-expanded="false"
                                                aria-controls="mosaic-card-item2-871e19660a9c9ad3aa38f478b7e8566be582e001f46ef2385a3f03423a30d139"
                                                aria-label="Learn more: Secure the digital core as you transform the business"
                                            />
                                            {/* DEV NOTE: aria-label text is copied from __card-body and rad-button--tertiary below */}
                                            <div
                                                cy className="rad-mosaic-2__card-cta-cover"
                                                tabIndex={-1}
                                                target="_self"
                                                aria-label="Learn more: Secure the digital core as you transform the business"
                                                data-barba-transition=""
                                                data-cmp-clickable=""
                                                data-analytics-asset-id="card-3a77aa9043"
                                                data-analytics-asset-pos={3}
                                                data-cmp-data-layer='{"mosaic-card-item2-871e19660a9c9ad3aa38f478b7e8566be582e001f46ef2385a3f03423a30d139":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-protection","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Protection","analytics-module-name":"mosaicv2card-3","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                            />
                                            <div
                                                className="rad-icon-button rad-mosaic-2__card-close-button"
                                                tabIndex={-1}
                                                aria-hidden="true"
                                            >
                                                <div className="rad-icon-button__text">Close</div>
                                                <div className="rad-icon rad-icon__close" />
                                            </div>
                                            <div className="rad-mosaic-2__card-eyebrow text-light" aria-hidden="true">
                                                Cyber Protection
                                            </div>
                                            <div
                                                className="rad-mosaic-2__card-sliding-content"
                                                aria-hidden="true"
                                            >
                                                <div className="rad-mosaic-2__card-front-content">
                                                    <h3 className="rad-mosaic-2__card-title text-light">
                                                        Rapidly modernize security to safeguard your digital core
                                                    </h3>

                                                </div>
                                                <div className="rad-mosaic-2__card-back-content">
                                                    <div className="rad-mosaic-2__card-body">
                                                        Companies are most vulnerable to cyber-attacks during
                                                        digital transformation. Learn how to adopt zero trust
                                                        principles and protect your business.
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            cy className="rad-mosaic-2__card rad-mosaic-2__card--desktop"
                                            target="_self"
                                            aria-label="Learn more: Secure the digital core as you transform the business"
                                            data-barba-transition=""
                                            data-cmp-clickable=""
                                            data-analytics-asset-id="card-3a77aa9043"
                                            data-analytics-asset-pos={3}
                                            data-analytics-skim-hover=""
                                            data-cmp-data-layer='{"mosaic-card-item2-871e19660a9c9ad3aa38f478b7e8566be582e001f46ef2385a3f03423a30d139":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-protection","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Protection","analytics-module-name":"mosaicv2card-3","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                        >
                                            <div
                                                className="rad-mosaic-2__card-description-content"
                                                data-analytics-asset-id="card-3a77aa9043"
                                                data-analytics-asset-pos={3}
                                            >
                                                <div className="rad-mosaic-2__card-eyebrow text-light">
                                                    Cyber Protection
                                                </div>
                                                <div className="rad-mosaic-2__card-sliding-title">
                                                    <h3 className="rad-mosaic-2__card-title text-light">
                                                        Rapidly modernize security to safeguard your digital core
                                                    </h3>
                                                    <div className="rad-mosaic-2__card-body">
                                                        Companies are most vulnerable to cyber-attacks during
                                                        digital transformation. Learn how to adopt zero trust
                                                        principles and protect your business.
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {/* vertical with image or stat card */}
                                        {/* no image*/}
                                        {/* no image*/}
                                        {/* no image*/}
                                        {/* horizontal with image or stat-card */}
                                        {/* vertical with image or stat card */}
                                        {/* no image*/}
                                        {/* vertical with image or stat card */}


                                        <div
                                            className="rad-mosaic-2__card rad-mosaic-2__card--mobile"
                                            data-analytics-asset-id="card-523956962c"
                                            data-analytics-asset-pos={5}
                                            data-analytics-skim-click=""
                                        >
                                            {/* DEV NOTE: aria-label text is copied from __eyebrow and __title below */}
                                            <button
                                                className="rad-mosaic-2__card-front-toggle"
                                                aria-expanded="false"
                                                aria-controls="mosaic-card-item4-206aa89afe176c740a5f5d0a71763597ccc84018b84175401528aeb8fd56ead0"
                                                aria-label="Learn more: Operational technology and product security"
                                            />
                                            {/* DEV NOTE: aria-label text is copied from __card-body and rad-button--tertiary below */}
                                            <div
                                                ph
                                                className="rad-mosaic-2__card-cta-cover"
                                                tabIndex={-1}
                                                target="_self"
                                                aria-label="Learn more: Operational technology and product security"
                                                data-cmp-clickable=""
                                                data-analytics-asset-id="card-523956962c"
                                                data-analytics-asset-pos={5}
                                                data-cmp-data-layer='{"mosaic-card-item4-206aa89afe176c740a5f5d0a71763597ccc84018b84175401528aeb8fd56ead0":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/physical-security","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber-Physical Security","analytics-module-name":"mosaicv2card-5","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                            />
                                            <div
                                                className="rad-icon-button rad-mosaic-2__card-close-button"
                                                tabIndex={-1}
                                                aria-hidden="true"
                                            >
                                                <div className="rad-icon-button__text">Close</div>
                                                <div className="rad-icon rad-icon__close" />
                                            </div>
                                            <div className="rad-mosaic-2__card-eyebrow text-light" aria-hidden="true">
                                                Cyber-Physical Security
                                            </div>
                                            <div
                                                className="rad-mosaic-2__card-sliding-content"
                                                aria-hidden="true"
                                            >
                                                <div className="rad-mosaic-2__card-front-content">
                                                    <h3 className="rad-mosaic-2__card-title text-light">
                                                        Protect your operational technology and connected products
                                                        confidently
                                                    </h3>

                                                </div>
                                                <div className="rad-mosaic-2__card-back-content">
                                                    <div className="rad-mosaic-2__card-body">
                                                        With greater connectivity comes more operational and smart
                                                        product vulnerability. Turn infrastructure security into a
                                                        business enabler with seamless cyber-physical protection
                                                        for a safer, smarter future.
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            ph
                                            className="rad-mosaic-2__card rad-mosaic-2__card--desktop"
                                            target="_self"
                                            aria-label="Learn more: Operational technology and product security"
                                            data-cmp-clickable=""
                                            data-analytics-asset-id="card-523956962c"
                                            data-analytics-asset-pos={5}
                                            data-analytics-skim-hover=""
                                            data-cmp-data-layer='{"mosaic-card-item4-206aa89afe176c740a5f5d0a71763597ccc84018b84175401528aeb8fd56ead0":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/physical-security","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber-Physical Security","analytics-module-name":"mosaicv2card-5","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                        >
                                            <div
                                                className="rad-mosaic-2__card-description-content"
                                                data-analytics-asset-id="card-523956962c"
                                                data-analytics-asset-pos={5}
                                            >
                                                <div className="rad-mosaic-2__card-eyebrow text-light">
                                                    Cyber-Physical Security
                                                </div>
                                                <div className="rad-mosaic-2__card-sliding-title">
                                                    <h3 className="rad-mosaic-2__card-title text-light">
                                                        Protect your operational technology and connected products
                                                        confidently
                                                    </h3>
                                                    <div className="rad-mosaic-2__card-body">
                                                        With greater connectivity comes more operational and smart
                                                        product vulnerability. Turn infrastructure security into a
                                                        business enabler with seamless cyber-physical protection
                                                        for a safer, smarter future.
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {/* no image*/}
                                        {/* no image*/}
                                        {/* horizontal with image or stat-card */}
                                        {/* vertical with image or stat card */}
                                        {/* no image*/}
                                        {/* vertical with image or stat card */}
                                        {/* no image*/}
                                        {/* no image*/}
                                        <div
                                            className="rad-mosaic-2__card rad-mosaic-2__card--mobile"
                                            data-analytics-asset-id="card-66cc4bbc58"
                                            data-analytics-asset-pos={6}
                                            data-analytics-skim-click=""
                                        >
                                            {/* DEV NOTE: aria-label text is copied from __eyebrow and __title below */}
                                            <button
                                                className="rad-mosaic-2__card-front-toggle"
                                                aria-expanded="false"
                                                aria-controls="mosaic-card-item5-dbfed1b6030d35e5d61d5d414713747cc498d5e036484e28018e320a8fb2600c"
                                                aria-label="Learn more: Embed security across your value chain to enhance resilience"
                                            />
                                            {/* DEV NOTE: aria-label text is copied from __card-body and rad-button--tertiary below */}
                                            <div
                                                c className="rad-mosaic-2__card-cta-cover"
                                                tabIndex={-1}
                                                target="_self"
                                                aria-label="Learn more: Embed security across your value chain to enhance resilience"
                                                data-barba-transition=""
                                                data-cmp-clickable=""
                                                data-analytics-asset-id="card-66cc4bbc58"
                                                data-analytics-asset-pos={6}
                                                data-cmp-data-layer='{"mosaic-card-item5-dbfed1b6030d35e5d61d5d414713747cc498d5e036484e28018e320a8fb2600c":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-industry","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Industry","analytics-module-name":"mosaicv2card-6","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                            />
                                            <div
                                                className="rad-icon-button rad-mosaic-2__card-close-button"
                                                tabIndex={-1}
                                                aria-hidden="true"
                                            >
                                                <div className="rad-icon-button__text">Close</div>
                                                <div className="rad-icon rad-icon__close" />
                                            </div>
                                            <div className="rad-mosaic-2__card-eyebrow text-light" aria-hidden="true">
                                                Cyber Industry
                                            </div>
                                            <div
                                                className="rad-mosaic-2__card-sliding-content"
                                                aria-hidden="true"
                                            >
                                                <div className="rad-mosaic-2__card-front-content">
                                                    <h3 className="rad-mosaic-2__card-title text-light">
                                                        Is your cyber resilience fit for your industry?
                                                    </h3>

                                                </div>
                                                <div className="rad-mosaic-2__card-back-content">
                                                    <div className="rad-mosaic-2__card-body">
                                                        Cyber resilience must be customized to fit your industry’s
                                                        unique needs. Tailor your solution to effectively secure
                                                        products and organizations. Get industry-specific
                                                        protection now.
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            c className="rad-mosaic-2__card rad-mosaic-2__card--desktop"
                                            target="_self"
                                            aria-label="Learn more: Embed security across your value chain to enhance resilience"
                                            data-barba-transition=""
                                            data-cmp-clickable=""
                                            data-analytics-asset-id="card-66cc4bbc58"
                                            data-analytics-asset-pos={6}
                                            data-analytics-skim-hover=""
                                            data-cmp-data-layer='{"mosaic-card-item5-dbfed1b6030d35e5d61d5d414713747cc498d5e036484e28018e320a8fb2600c":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-industry","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Industry","analytics-module-name":"mosaicv2card-6","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                        >
                                            <div
                                                className="rad-mosaic-2__card-description-content"
                                                data-analytics-asset-id="card-66cc4bbc58"
                                                data-analytics-asset-pos={6}
                                            >
                                                <div className="rad-mosaic-2__card-eyebrow text-light">
                                                    Cyber Industry
                                                </div>
                                                <div className="rad-mosaic-2__card-sliding-title">
                                                    <h3 className="rad-mosaic-2__card-title text-light">
                                                        Is your cyber resilience fit for your industry?
                                                    </h3>
                                                    <div className="rad-mosaic-2__card-body">
                                                        Cyber resilience must be customized to fit your industry’s
                                                        unique needs. Tailor your solution to effectively secure
                                                        products and organizations. Get industry-specific
                                                        protection now.
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {/* no image*/}
                                        {/* horizontal with image or stat-card */}
                                        {/* vertical with image or stat card */}
                                        {/* no image*/}
                                        {/* vertical with image or stat card */}
                                        {/* no image*/}
                                        {/* no image*/}
                                        {/* no image*/}
                                        <div
                                            className="rad-mosaic-2__card rad-mosaic-2__card--mobile"
                                            data-analytics-asset-id="card-53c8e83f6b"
                                            data-analytics-asset-pos={7}
                                            data-analytics-skim-click=""
                                        >
                                            {/* DEV NOTE: aria-label text is copied from __eyebrow and __title below */}
                                            <button
                                                className="rad-mosaic-2__card-front-toggle"
                                                aria-expanded="false"
                                                aria-controls="mosaic-card-item6-4a9cca291c32f71872d871457bd8d4a7b88bdc3241a843d48242d247faccdb66"
                                                aria-label="Learn more: How will you stay steps ahead of today’s threat actors?"
                                            />
                                            {/* DEV NOTE: aria-label text is copied from __card-body and rad-button--tertiary below */}
                                            <div

                                                className="rad-mosaic-2__card-cta-cover"
                                                tabIndex={-1}
                                                target="_self"
                                                aria-label="Learn more: How will you stay steps ahead of today’s threat actors?"
                                                data-barba-transition=""
                                                data-cmp-clickable=""
                                                data-analytics-asset-id="card-53c8e83f6b"
                                                data-analytics-asset-pos={7}
                                                data-cmp-data-layer='{"mosaic-card-item6-4a9cca291c32f71872d871457bd8d4a7b88bdc3241a843d48242d247faccdb66":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cybersecurity-centers","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cybersecurity Centers","analytics-module-name":"mosaicv2card-7","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                            />
                                            <div
                                                className="rad-icon-button rad-mosaic-2__card-close-button"
                                                tabIndex={-1}
                                                aria-hidden="true"
                                            >
                                                <div className="rad-icon-button__text">Close</div>
                                                <div className="rad-icon rad-icon__close" />
                                            </div>
                                            <div className="rad-mosaic-2__card-eyebrow text-light" aria-hidden="true">
                                                Cybersecurity Centers
                                            </div>
                                            <div
                                                className="rad-mosaic-2__card-sliding-content"
                                                aria-hidden="true"
                                            >
                                                <div className="rad-mosaic-2__card-front-content">
                                                    <h3 className="rad-mosaic-2__card-title text-light">
                                                        How will you outsmart your toughest cybersecurity
                                                        challenges?
                                                    </h3>

                                                </div>
                                                <div className="rad-mosaic-2__card-back-content">
                                                    <div className="rad-mosaic-2__card-body">
                                                        Cyber-attacks are becoming more sophisticated. Leverage
                                                        managed services, advanced analytics, intelligent
                                                        automation and integrated defense to out-innovate
                                                        attackers.
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div

                                            className="rad-mosaic-2__card rad-mosaic-2__card--desktop"
                                            target="_self"
                                            aria-label="Learn more: How will you stay steps ahead of today’s threat actors?"
                                            data-barba-transition=""
                                            data-cmp-clickable=""
                                            data-analytics-asset-id="card-53c8e83f6b"
                                            data-analytics-asset-pos={7}
                                            data-analytics-skim-hover=""
                                            data-cmp-data-layer='{"mosaic-card-item6-4a9cca291c32f71872d871457bd8d4a7b88bdc3241a843d48242d247faccdb66":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cybersecurity-centers","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cybersecurity Centers","analytics-module-name":"mosaicv2card-7","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                                        >
                                            <div
                                                className="rad-mosaic-2__card-description-content"
                                                data-analytics-asset-id="card-53c8e83f6b"
                                                data-analytics-asset-pos={7}
                                            >
                                                <div className="rad-mosaic-2__card-eyebrow text-light">
                                                    Cybersecurity Centers
                                                </div>
                                                <div className="rad-mosaic-2__card-sliding-title">
                                                    <h3 className="rad-mosaic-2__card-title text-light">
                                                        How will you outsmart your toughest cybersecurity
                                                        challenges?
                                                    </h3>
                                                    <div className="rad-mosaic-2__card-body">
                                                        Cyber-attacks are becoming more sophisticated. Leverage
                                                        managed services, advanced analytics, intelligent
                                                        automation and integrated defense to out-innovate
                                                        attackers.
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> <br /><br />
                            <section className='p-5 cyber' data-aos="fade-right" data-aos-delay="500">
                                <h2 className='text-center  para mx-auto  text-light ' data-aos="fade-right" data-aos-delay="500" >
                                    <span className='mx-2' style={{ color: "#7F3E98" }}>   Cyber </span>   Defense Centers
                                </h2><br />
                                <p className='text-center mx-auto para text-light  rad-hero-image__body fs-5' >Spotcomm CyberTransform is our integrated risk and security platform that delivers strategy-first cybersecurity advisory and implementation services. CyberShield is our industry-leading suite of managed services that defend business operations with on-demand cyber resilience management. </p> <br /><br /><br /><br />
                                <img src='/map.png' className='para mx-auto' />
                            </section>
                            <div className='container'>
                                <div className=' row  ' id='cyber'>
                                    <div className='col-lg-6 cyberheading  ' data-aos="fade-right" data-aos-delay="500">
                                        <br /> <br />
                                        <h2 className=' text-light rad-mosaic-2__card-title'>State of <span className='' style={{ color: "#7F3E98" }}>Cybersecurity</span>  Report</h2><br /><br />
                                        <p className=' w-100 text-light rad-hero-image__body fs-5' >Get Your Copy Now! The 5th edition of Spotcomm’s Cybersecurity Report provides the latest trends and insights, offering a comprehensive view of how modern enterprises are striving for cyber resilience. In recent years, significant technological, geopolitical, and economic disruptions have exposed organizations to new and unforeseen risks, compelling security leaders to rethink their approaches to cybersecurity threats and risk management. This report explores how enterprises are adapting to these emerging risks and highlights the critical challenges security leaders are facing today..</p>
                                        {/* <br />   <button className="service-btn">Read More</button> */}
                                    </div>

                                    <div className='col-lg-6 ' data-aos="fade-up" data-aos-delay="500"><br />
                                        <img src='/cyber2.png' className='w-75 mx-auto d-flex justify-content-center' />
                                    </div>
                                    <br /> <br />
                                </div>
                            </div>
                            <div className="rad-icon-text-carousel rad-spacing-vertical-md">
                                {/* /* RAD Icon Text Carousel Start* / */}

                                <div
                                    data-placeholder-text="false"
                                    id="block-carousel-partners-in-change​"
                                    data-cmp-data-layer='{"block-carousel-partners-in-change​":{"@type":"cio-sites/components/rad/blocks/icontextcarousel","analytics-module-name":"icontextcarousel-1","analytics-template-zone":"block-carousel-partners-in-change​"}}'
                                >
                                    {/* /* Tier 1 Start * / */}
                                    {/* /* Tier 1 End* / */}
                                    {/* /* Tier 2 Start * / */}
                                    <div className="rad-icon-text-carousel-tier-2">
                                        <div className="rad-icon-text-carousel-tier-2__headline">
                                            <h2 className="rad-icon-text-carousel-tier-2__header text-light">

                                                Partners in change
                                            </h2>
                                            <div
                                                className="rad-button rad-button--tertiary rad-icon-text-carousel-tier-2__button"
                                                data-cmp-clickable=""
                                                data-cmp-data-layer='{"icontextcarousel_cop-icontextcarousel_cop-2ac76a65c6":{"analytics-engagement":"true","analytics-link-type":"engagement","analytics-module-name":"icontextcarousel-1"}}'
                                                target="_self"
                                                data-barba-transition=""
                                            ></div>
                                        </div>
                                        <div className="rad-icon-text-carousel-tier-2__grid">

                                            <div
                                                className="rad-icon-text-carousel-tier-2__grid-link"
                                                data-cmp-data-layer='{"icontextcarousel_cop-item1-7f8475a22c":{"analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Claroty logo"}}'
                                                target="_self"
                                                data-barba-transition=""
                                            >
                                                <img
                                                    className="rad-icon-text-carousel-tier-2__grid-image"
                                                    src="/partner6.png"
                                                    alt="Claroty logo"
                                                />
                                            </div>
                                            <div
                                                className="rad-icon-text-carousel-tier-2__grid-link"
                                                data-cmp-data-layer='{"icontextcarousel_cop-item2-ece4dc7a9c":{"analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Crowdstrike logo"}}'
                                                target="_self"
                                                data-barba-transition=""
                                            >
                                                <img
                                                    className="rad-icon-text-carousel-tier-2__grid-image"
                                                    src="/partner (1).png"
                                                    alt="Crowdstrike logo"
                                                />
                                            </div>
                                            <div
                                                className="rad-icon-text-carousel-tier-2__grid-link"
                                                data-cmp-data-layer='{"icontextcarousel_cop-item3-6dfc38e3e8":{"analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"CyberArk logo"}}'
                                                target="_self"
                                                data-barba-transition=""
                                            >
                                                <img
                                                    className="rad-icon-text-carousel-tier-2__grid-image"
                                                    src="/partner5.png"
                                                    alt="CyberArk logo"
                                                />
                                            </div>
                                            <div
                                                className="rad-icon-text-carousel-tier-2__grid-link"
                                                data-cmp-data-layer='{"icontextcarousel_cop-item4-d5771203bf":{"analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"ForgeRock logo"}}'
                                                target="_self"
                                                data-barba-transition=""
                                            >
                                                <img
                                                    className="rad-icon-text-carousel-tier-2__grid-image"
                                                    src="/cisco1.png"
                                                    alt="ForgeRock logo"
                                                />
                                            </div>
                                            <div
                                                className="rad-icon-text-carousel-tier-2__grid-link"
                                                data-cmp-data-layer='{"icontextcarousel_cop-item5-f858e65208":{"analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Fortinet logo"}}'
                                                target="_self"
                                                data-barba-transition=""
                                            >
                                                <img
                                                    className="rad-icon-text-carousel-tier-2__grid-image"
                                                    src="/partner7.png"
                                                    alt="Fortinet logo"
                                                />
                                            </div>

                                            <div
                                                className="rad-icon-text-carousel-tier-2__grid-link"
                                                data-cmp-data-layer='{"icontextcarousel_cop-item7-03c4613e33":{"analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Micro Focus logo"}}'
                                                target="_self"
                                                data-barba-transition=""
                                            >
                                                <img
                                                    className="rad-icon-text-carousel-tier-2__grid-image"
                                                    src="/partner8.png"
                                                    alt="Micro Focus logo"
                                                />
                                            </div>
                                            <div
                                                className="rad-icon-text-carousel-tier-2__grid-link"
                                                data-cmp-data-layer='{"icontextcarousel_cop-item8-0cb398d644":{"analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Microsoft logo"}}'
                                                target="_self"
                                                data-barba-transition=""
                                            >
                                                <img
                                                    className="rad-icon-text-carousel-tier-2__grid-image"
                                                    src="/partner9.png"
                                                    alt="Microsoft logo"
                                                />
                                            </div>
                                            <div
                                                className="rad-icon-text-carousel-tier-2__grid-link"
                                                data-cmp-data-layer='{"icontextcarousel_cop-item9-5de060d3ce":{"analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Nozomi logo"}}'
                                                target="_self"
                                                data-barba-transition=""
                                            >
                                                <img
                                                    className="rad-icon-text-carousel-tier-2__grid-image"
                                                    src="/partner10.png"
                                                    alt="Nozomi logo"
                                                />
                                            </div>
                                            <div
                                                className="rad-icon-text-carousel-tier-2__grid-link"
                                                data-cmp-data-layer='{"icontextcarousel_cop-item9-5de060d3ce":{"analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Nozomi logo"}}'
                                                target="_self"
                                                data-barba-transition=""
                                            >
                                                <img
                                                    className="rad-icon-text-carousel-tier-2__grid-image"
                                                    src="/partner11.png"
                                                    alt="Nozomi logo"
                                                />
                                            </div>
                                            <div
                                                className="rad-icon-text-carousel-tier-2__grid-link"
                                                data-cmp-data-layer='{"icontextcarousel_cop-item9-5de060d3ce":{"analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Nozomi logo"}}'
                                                target="_self"
                                                data-barba-transition=""
                                            >
                                                <img
                                                    className="rad-icon-text-carousel-tier-2__grid-image"
                                                    src="/partner12.png"
                                                    alt="Nozomi logo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* /* Tier 2 End* / */}
                                </div>
                                {/* /* RAD Partners End* / */}
                            </div>


                        </div>
                    </main>
                    <Demo />
                </div>
                {/* customfooter */}
                {/* Mirrored from www.accenture.com/us-en/services/cybersecurity by HTTrack Website Copier/3.x [XR&CO'2014], Mon, 20 Jan 2025 12:56:10 GMT */}


                <script defer src="../etc.clientlibs/cio-sites/clientlibs/clientlib-rad.lc-0c0d19a98351335f142a50bdd1990dbb-lc.min.js"></script>







                <script defer src="../etc.clientlibs/cio-sites/clientlibs/clientlib-rad-base.lc-9eb4bd274c571cea81890aaa62060a46-lc.min.js"></script>


            </>

        </div>
    )
}

export default CyberSecurity