import React, { useState } from 'react'
import Header from './components/Header'
import Demo from './components/Navbar';
import HeroSection from './components/D';

const Networkservices = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const services = [
        {
          title: "Proven Track Record",
          description: "Trusted by thousands, our proven record showcases consistent success and unmatched reliability every time.",
          image: "/cloud (2).png", // Replace with your image path
        },
        {
          title: "Cloud Options",
          description: "Flexible cloud options tailored to your needs. Ensure seamless performance, security, and scalability anytime.",
          image: "/cloud (3).png", // Replace with your image path
        },
        {
          title: "One Umbrella Solution",
          description: "Comprehensive one-in-one solutions to address critical requirements, streamline efficiency, and ensure success.",
          image: "/cloud (4).png", // Replace with your image path
        },
        {
          title: "Smooth On-Prem to Cloud Migration Services",
          description: "Inclusive on-prem to cloud migration services ensuring seamless data transfer with minimal downtime and optimized performance.",
          image: "/cloud (6).png", // Replace with your image path
        },
        {
          title: "24/7 Monitoring Services",
          description: "Round-the-clock 24/7 monitoring services ensuring robust security, minimizing downtime, and peace of mind for businesses.",
          image: "/cloud (5).png", // Replace with your image path
        },
        {
          title: "Expert Consultation Services",
          description: "Industry-leading expert consultation services offering tailored strategies, innovative solutions, and actionable insights for success.",
          image: "/cloud (1).png", // Replace with your image path
        },
      ];
      
    const solutions = [
        {

            imgSrc: "/managenet (1).png",
            title: "Proactive Security Measures",
            description: "Managed network service providers can implement advanced security measures",
        },
        {

            imgSrc: "/managenet (2).png",
            title: "Enhanced Network Performance",
            description: "Managed network services can optimize the performance of your IT infrastructure.",
        },
        {

            imgSrc: "/managenet (3).png",
            title: "Expertise and Support",
            description: "Managed network service providers come with specialized expertise in network architecture, cybersecurity and troubleshooting.",
        },
        {

            imgSrc: "/managenet (4).png",
            title: "Focus on Core Competencies",
            description: "Managing a complex network infrastructure can be time-consuming and resource intensive. ",
        },
        {

            imgSrc: "/managenet (5).png",
            title: "Scalability and Flexibility",
            description: "Managed network services offer scalability that can adapt to changing demands. ",
        },
        {

            imgSrc: "/managenet (6).png",
            title: "24/7 Monitoring and Support",
            description: "Managed network services offer around-the-clock monitoring and support.",
        },
        // {

        //     imgSrc: "/managenet (7).png",
        //     title: "IT Staffing",
        //     description: "Outsourcing, Training and certification",
        // },
    ];

    return (
        <div>
            <Header />
            <section id="heros" class="heros1 section dark-background">
                <img src="/manage.png" alt="" />

                <div class="container text-start">
                    <div class="row justify-content-start">
                        <div class="col-lg-8">
                            <h2 className='profh2'>Managed Network
                                Services </h2>
                        </div>
                    </div>
                </div>
            </section>
            <section id="bg2" class="bg3 section dark-background" >


                <div class="container text-center" >
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-sm-12">

                            <p className=' text-center  mx-auto' style={{ border: "2px solid #7F3E98 ", borderRadius: "20px" }}> Spotcomm’s Managed Network Services cover the entire lifecycle of your network infrastructure—from design and deployment to ongoing management and support. We offer a full range of solutions, including network architecture, configuration, security, monitoring, and troubleshooting.
                                If your network performance is lagging, managed services could be the key to optimizing it. A poorly performing network can disrupt employee productivity, increase costs, and cause issues like slow response times or dropped calls.
                                At Spotcomm, we recognize the critical role a reliable network plays in your organization’s success. Our goal is to provide top-tier managed network services that empower your business to thrive in any environment</p>
                        </div>
                    </div>
                </div>

            </section>
            <div className='container'>

               
                <h2 className='fw-bold'>Features of <span style={{color: "#7F3E98"}}>Managed Network Services</span>    </h2>
                <p>When you sign up with Spotcomm, you’ll get access to our critical network management and maintenance features which are essential  <br />to your network performance and its durability.
                </p>
                <br /><br />
                <div className=''>


                    <div className="teaser teaser--full-size teaser--image-left teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                        <div className="cmp-teaser cmp-teaser--971658057  cmp-teaser--show-action  ">
                            <div
                                className="cmp-teaser__content"
                                style={{ background: "#E1E0E0"}}
                            >
                                <div className="cmp-teaser__content-wrapper">
                                    <h2 className="cmp-teaser__title  text-dark ">
                                        <div className="cmp-teaser__title fw-bold fs-3" style={{color: "#7F3E98"}}>
                                            Network Security

                                            <br />
                                        </div>
                                    </h2>
                                    <div className="cmp-teaser__description fs-6">
                                        <p className='text-dark'>
                                            A lack of adequately applied software patches can cause much more than an unreliable network. Many of these patches are designed to fix security holes. Worse, every would-be cyber criminal knows about the exploit once the patch is released. If you don’t apply the patches quickly, your systems become a haven for every cyber attacker wishing to harm you. Should they succeed, your sensitive data, or that of your customers, could be made public.

                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="cmp-teaser__image">
                                <img src="/manage (3).png" />
                            </div>
                        </div>
                        <style
                            dangerouslySetInnerHTML={{
                                __html: '.cmp-teaser--971658057 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                            }}
                            data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1027937634"
                        />
                    </div>
                    <div className="teaser teaser--full-size teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                        <div className="cmp-teaser cmp-teaser--13409574  cmp-teaser--show-action  ">
                            <div
                                className="cmp-teaser__content"
                                style={{ background: "#E1E0E0"}}
                            >
                                <div className="cmp-teaser__content-wrapper">
                                    <h2 className="cmp-teaser__title  text-dark ">
                                        <div className="cmp-teaser__title fw-bold fs-3" style={{color: "#7F3E98"}}>
                                            Network Management

                                        </div>
                                    </h2>
                                    <div className="cmp-teaser__description fs-6">
                                        <p className='text-dark' >
                                            Network management involves daily tasks such as configuration, optimization, monitoring, and troubleshooting. Beyond these routine duties, it also encompasses responding to issues as they arise and handling one-time tasks like setting up new devices or software.



                                        </p>
                                        <p />
                                    </div>
                                    <div className="cmp-teaser__action-">
                                        <div
                                            className="cmp-teaser__action-background"
                                            data-style="background-color:#fff; "
                                            style={{
                                                backgroundColor: '#fff',
                                                color: "#000"
                                            }}
                                        >

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cmp-teaser__image">
                                <img src="/manage (2).png" />
                            </div>
                        </div>
                        <style
                            dangerouslySetInnerHTML={{
                                __html: '.cmp-teaser--13409574 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                            }}
                            data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1621792052"
                        />
                    </div>
                    <div className="teaser teaser--full-size teaser--image-left teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                        <div className="cmp-teaser cmp-teaser--971658057  cmp-teaser--show-action  ">
                            <div
                                className="cmp-teaser__content"
                                style={{ background: "#E1E0E0"}}
                            >
                                <div className="cmp-teaser__content-wrapper">
                                    <h2 className="cmp-teaser__title  text-dark ">
                                        <div className="cmp-teaser__title fw-bold fs-3" style={{color: "#7F3E98"}}>
                                            Network Maintenance

                                            <br />
                                        </div>
                                    </h2>
                                    <div className="cmp-teaser__description fs-6">
                                        <p className='text-dark'>
                                            While daily monitoring is essential for maintaining a healthy network, regular maintenance is equally crucial. Software companies frequently release patches to fix bugs and improve security, which need to be promptly installed. Hardware, too, can fail over time and may require replacement or upgrading to meet modern performance standards. In some cases, outdated equipment simply can’t keep up with current demands. Without proper attention to these updates, a once reliable network can quickly become more of a liability than an asset.


                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="cmp-teaser__image">
                                <img src="/manage (1).png" />
                            </div>
                        </div>
                        <style
                            dangerouslySetInnerHTML={{
                                __html: '.cmp-teaser--971658057 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                            }}
                            data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1027937634"
                        />
                    </div>
                    <div className="teaser teaser--full-size teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                        <div className="cmp-teaser cmp-teaser--13409574  cmp-teaser--show-action  ">
                            <div
                                className="cmp-teaser__content"
                                style={{ background: "#E1E0E0"}}
                            >
                                <div className="cmp-teaser__content-wrapper">
                                    <h2 className="cmp-teaser__title  text-dark ">
                                        <div className="cmp-teaser__title fw-bold fs-3" style={{color: "#7F3E98"}}>
                                            Network Monitoring
                                        </div>
                                    </h2>
                                    <div className="cmp-teaser__description fs-6">
                                        <p className='text-dark' >
                                            Networks are busy. At any given time, important data could be flowing through them. Data that can seriously impede business processes if it doesn’t arrive. Yet, networks aren’t infallible. Sometimes they go down. To minimize the impact of lost data, they need to be monitored constantly. While plenty of advanced tools can automate that process, you’ll need access to those tools. Importantly, you’ll also need access to someone who can be alerted and intervene when there’s a problem. This makes monitoring one of the most essential features of managed network services.


                                        </p>
                                        <p />
                                    </div>
                                    <div className="cmp-teaser__action-">
                                        <div
                                            className="cmp-teaser__action-background"
                                            data-style="background-color:#fff; "
                                            style={{
                                                backgroundColor: '#fff',
                                                color: "#000"
                                            }}
                                        >

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cmp-teaser__image">
                                <img src="/manage (4).png" />
                            </div>
                        </div>
                        <style
                            dangerouslySetInnerHTML={{
                                __html: '.cmp-teaser--13409574 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                            }}
                            data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1621792052"
                        />
                    </div>
                </div>

            </div> <br /> <br />
            <div className="container">
            <h2 className='text-start fw-bold' style={{ color: "#7F3E98 " }}>Benefits</h2>
            {/* <div className="container">
                <div className="row">
                    {solutions.map((service, index) => (
                        <div key={index} className="col-lg-3">
                            <img src={service.imgSrc} alt={service.title} />
                            <div className='p-2'>
                                <h3 >{service.title}</h3>
                                <p>{service.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

            </div> */}
            <section className="services-section1 container">
      {/* <h2>
        Why choose <span className="highlight">Spotcomm Cloud Matrix</span> Services
      </h2> */}
      <div className="services-grid">
      {solutions.map((service, index) => (
          <div className="service-card1" key={index}>
                <img src={service.imgSrc} alt={service.title} /> 
            <h3 className="text-start fw-bold mx-3 mt-2" style={{color: "#7F3E98"}}>{service.title}</h3>
            <p className="text-start mx-3">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
    </div>
            <HeroSection />

            <br /><br />
            <h2 className='text-center fw-bold fs-1 '>YOUR TECH SUPPORT</h2>
            <h4 className='text-center fw-normal'>LET US BE YOUR LARGE AND SMALL BUSINESS IT <br /> SUPPORT
            </h4><br /><br />
            <img src='/manage2.png' className='w-100' />
            <Demo />
        </div>
    )
}

export default Networkservices