import React from 'react'
import Header from '../components/Header'
import Blogcard from '../components/Blogcard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import Partners from '../components/Partner';
import Demo from '../components/Navbar';
import Blog from '../components/Blog';
import { useMediaQuery } from 'react-responsive'
import ServicesSection from '../components/ServicesSection';

const Cloud = () => {
    const services = [
        { title: 'Expert Consultation Services', icon: '/cloud (1).png' },
        { title: 'Proven Track Record', icon: '/cloud (2).png' },
        { title: 'Smooth On-Prem to Cloud Migration Services', icon: '/cloud (3).png' },
        { title: 'Cloud Options', icon: '/cloud (4).png' },
        { title: '24/7 Monitoring Services', icon: '/cloud (5).png' },
        { title: 'One Umbrella Solution', icon: '/cloud (6).png' },
    ];
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
    }
    const Tablet = ({ children }) => {
        const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
        return isTablet ? children : null
    }
    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ minWidth: 20, maxWidth: 991 })
        return isMobile ? children : null
    }
    const Default = ({ children }) => {
        const isNotMobile = useMediaQuery({ minWidth: 768 })
        return isNotMobile ? children : null
    }
    return (
        <div className=''  >
            <Header />
            <Desktop>
                <section id="heros" data-aos="fade-right" data-aos-delay="500" className="heros1 section dark-background">
                    <img src="/cloud.gif" alt="" />
                </section>
            </Desktop>
            <Mobile>
                <section id="heros" class="heros1 section dark-background" data-aos="fade-right" data-aos-delay="100">

                    <img src="/cloudmob.png" alt="" />

                    <div class="container text-center" >
                        <div class="row justify-content-center">
                            <div class="col-lg-8 oncloud">
                                <h2 className="profh2">REVAMP WITH
                                    <br /> CLOUD MATRIX
                                </h2>
                            </div>
                        </div>
                    </div>

                </section>

                {/* <section id="heros" data-aos="fade-right" data-aos-delay="100" className="heros1 section dark-background">
                    <img src="/cloudmob.png" alt="" />
                </section> */}

            </Mobile>
            <section
                id="testimonials-section sect-paddings"
                className="testimonials-section  at-bg-cover d-flex  align-items-center paragraph paragraph--type--quote paragraph--view-mode--default"
                style={{ background: "url(/feature2.png)" }}
            >
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">
                        <div
                            className="col-12 col-lg-10 at-maxw-1075"
                        // style={{ background: "#362358" , fontFamily: "proxima-nova" ,lineHeight: "1.5" }}
                        >
                            <blockquote className="text-start ">
                                <div className="svg-holder">
                                    <svg
                                        width={107}
                                        height={71}
                                        viewBox="0 0 107 71"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M107 51.8969C107 57.2033 104.965 61.7668 100.895 65.5875C97.039 69.1958 92.2192 71 86.4354 71C78.5095 71 71.8689 68.2407 66.5135 62.722C61.3724 57.2033 58.8018 50.0927 58.8018 41.3901C58.8018 25.4709 65.014 14.1151 77.4384 7.32287C86.4354 2.44096 93.7187 0 99.2883 0C101.645 0 102.823 0.84903 102.823 2.54709C102.823 4.03288 101.538 5.09417 98.967 5.73094C81.1872 10.4006 72.2973 19.2093 72.2973 32.157C72.2973 37.8879 73.7968 42.7698 76.7958 46.8027C77.2242 39.5859 81.8298 35.9776 90.6126 35.9776C95.3253 35.9776 99.1812 37.5695 102.18 40.7534C105.393 43.725 107 47.4395 107 51.8969ZM47.8769 51.8969C47.8769 57.2033 45.8418 61.7668 41.7718 65.5875C37.9159 69.1958 33.2032 71 27.6336 71C19.4935 71 12.8529 68.2407 7.71171 62.722C2.57057 56.991 0 49.7743 0 41.0718C0 25.3647 6.21221 14.1151 18.6366 7.32287C27.6336 2.44096 34.8098 0 40.1652 0C42.5215 0 43.6997 0.84903 43.6997 2.54709C43.6997 4.03288 42.4144 5.09417 39.8438 5.73094C22.2783 10.4006 13.4955 19.3154 13.4955 32.4753C13.4955 37.994 14.995 42.7698 17.994 46.8027C18.4224 39.5859 22.9209 35.9776 31.4895 35.9776C36.2022 35.9776 40.0581 37.5695 43.0571 40.7534C46.2703 43.725 47.8769 47.4395 47.8769 51.8969Z"
                                            fill="#161616"
                                        />
                                    </svg>
                                </div>
                                <h4 className="fw-normal mt-md-5 mt-3 mb-4 at-lh-1pt7">
                                    <p className='fs-6'>
                                        Spotcomm Cloud Matrix help build an agile IT ecosystem with hybrid multi-cloud environments,
                                        security and network you need globally. Our world-className Cloud Solution enables you to grow
                                        globally while improving productivity and efficiency, delivering a seamless customer
                                        experience.
                                        <br /> <br />
                                        Our Cloud Managed services make it possible for organizations to reduce their overheads and
                                        better productivity, in addition to lowering costs and reducing time-to-market. Spotcomm
                                        Cloud Matrix solutions have a track record of helping clients become more secure and more
                                        responsive to progressing market trends. Our team of cloud engineers will consult and work
                                        with you to determine the specific needs and design a custom solution that meets your needs
                                        and budget. With our cloud services, you’ll be able to focus on your core business while we take
                                        care of the rest.
                                    </p>
                                </h4>

                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className=" cmp-border-header cmp-border-header-650763923" data-aos="fade-right" data-aos-delay="500">
                <div className="row ">
                    <div className="col-lg-10 col-sm-12">
                        <br /> <br />
                        <p className='fs-6' >Spotcomm Cloud Matrix help build an agile IT ecosystem with hybrid multi-cloud environments,
                            security and network you need globally. Our world-className Cloud Solution enables you to grow
                            globally while improving productivity and efficiency, delivering a seamless customer
                            experience.
                            <br /> <br />
                            Our Cloud Managed services make it possible for organizations to reduce their overheads and
                            better productivity, in addition to lowering costs and reducing time-to-market. Spotcomm
                            Cloud Matrix solutions have a track record of helping clients become more secure and more
                            responsive to progressing market trends. Our team of cloud engineers will consult and work
                            with you to determine the specific needs and design a custom solution that meets your needs
                            and budget. With our cloud services, you’ll be able to focus on your core business while we take
                            care of the rest.</p>
                    </div>
                </div>
            </div> <br /> <br /> */} <br />
            <section className='cmp-border-header cmp-border-header-650763923' data-aos="fade-up" data-aos-delay="500">
                <div className="">
                    <div className="cmp-border-header__top-overlay"> <br />
                        {/* <div className="cmp-border-h2"><p><span>Why choose <span className='' style={{ color: "#7F3E98" }}>Spotcomm Cloud Matrix </span> Services
                        </span></p>
                        </div> */}
                        <div className="cmp-border-h2 "><h2 className='fw-bold'>Why choose <span className='' style={{ color: "#7F3E98" }}>Spotcomm Cloud Matrix </span> Services
                        </h2>
                        </div>
                        <div className="cmp-border-header__top-overlay-line mx-3" style={{ backgroundColor: "#000" }}></div>
                    </div>
                </div>
                {/* <div className="container">
                    <div className="row">
                        {services.map((service, index) => (
                            <div key={index} className="col-lg-4">
                                <img src={service.icon} alt={service.title} />

                            </div>
                        ))}
                    </div>

                </div> */}
            </section>
            <ServicesSection />
            {/* 
                <div className="row d-flex justify-content-center">
                    {services.map((service, index) => (
                        <div className="col-lg-4   mt-5 " data-aos="fade-right" data-aos-delay="500" >
                            <img src={service.icon} className='mx-auto d-flex w-100' />
                            <div className="services-title text-center fs-5 fw-bold mt-3">{service.title} </div>
                        </div>
                    ))}
                </div> */}


            <section className='cmp-border-header cmp-border-header-650763923' data-aos="fade-up" data-aos-delay="500">
                <div className=" responsivegrid --width-wide --full-width aem-GridColumn aem-GridColumn--default--12">
                    <div
                        className="cmp- cmp---1058722595 "
                        data-type="grid"
                        id="-4af8be69f5"
                    >
                        <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 aem-Grid--phone--12 ">
                            <div className=" responsivegrid --width-regular --full-width aem-GridColumn aem-GridColumn--default--12 container">
                                <div className="">
                                    <div className="cmp-border-header__top-overlay"> <br />
                                        <div className="cmp-border-h2 "><h2 className='fw-bold'>Benefits of
                                            <span className='' style={{ color: "#7F3E98" }}>  Cloud Infrastructure </span>
                                        </h2>
                                        </div>
                                        <div className="cmp-border-header__top-overlay-line mx-3" style={{ backgroundColor: "#000" }}></div>
                                    </div>
                                </div>
                                <p className='text-dark ' >
                                    Cloud infrastructure in combination with development tools and API (Application
                                    Programming Interface) exposure of network assets enables developers to innovate and
                                    bring new applications to end users quicker.
                                </p>

                            </div>


                            <div className="teaser teaser--full-size teaser--image-left teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                                <div className="cmp-teaser cmp-teaser--1828042053  cmp-teaser--show-action  ">
                                    <div
                                        className="cmp-teaser__content"
                                        style={{ background: "#E1E0E0" }}
                                    >
                                        <div className="cmp-teaser__content-wrapper">

                                            <div className="cmp-teaser__description">

                                                <div class="title-wrap" >

                                                    <div class="description">
                                                        <p className='text-dark fs-6'> Cloud infrastructure in combination with development tools and API (Application
                                                            Programming Interface) exposure of network assets enables developers to innovate and
                                                            bring new applications to end users quicker.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cmp-teaser__action-">
                                                <div
                                                    className="cmp-teaser__action-background"
                                                    data-style="background-color:#fff; "
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        color: "#000"
                                                    }}
                                                >

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cmp-teaser__image">
                                        <img src="/cloud2 (2).png" />
                                    </div>
                                </div>
                                <style
                                    dangerouslySetInnerHTML={{
                                        __html: '.cmp-teaser--1828042053 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                                    }}
                                    data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_712145262"
                                />
                            </div>
                            <div className="teaser teaser--full-size teaser--image-left teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                                <div className="cmp-teaser cmp-teaser--971658057  cmp-teaser--show-action  ">
                                    <div
                                        className="cmp-teaser__content"
                                        style={{ background: "#E1E0E0" }}
                                    >
                                        <div className="cmp-teaser__content-wrapper">

                                            <div className="cmp-teaser__description">
                                                {/* <p className='text-dark fs-4'>

                                            </p> */}
                                                <div class="title-wrap" >

                                                    <div class="description">
                                                        <p className='text-dark fs-6'>
                                                            Operations and management of one common infrastructure is more efficient than many
                                                            individual application specific systems.

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="cmp-teaser__image">
                                        <img src="/cloud2 (4).png" />
                                    </div>
                                </div>
                                <style
                                    dangerouslySetInnerHTML={{
                                        __html: '.cmp-teaser--971658057 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                                    }}
                                    data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1027937634"
                                />
                            </div>
                            <div className="teaser teaser--full-size teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                                <div className="cmp-teaser cmp-teaser--13409574  cmp-teaser--show-action  ">
                                    <div
                                        className="cmp-teaser__content"
                                        style={{ background: "#E1E0E0" }}
                                    >
                                        <div className="cmp-teaser__content-wrapper">

                                            <div className="cmp-teaser__description">
                                                {/* <p className='text-dark fs-4' >
                                               
                                            </p> */}
                                                <div class="title-wrap" >

                                                    <div class="description">
                                                        <p className='text-dark fs-6'>
                                                            Costs for infrastructure resources will be lower since applications share the resources instead of
                                                            using dedicated capacity.

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cmp-teaser__action-">
                                                <div
                                                    className="cmp-teaser__action-background"
                                                    data-style="background-color:#fff; "
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        color: "#000"
                                                    }}
                                                >

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cmp-teaser__image">
                                        <img src="/cloud2 (3).png" />
                                    </div>
                                </div>
                                <style
                                    dangerouslySetInnerHTML={{
                                        __html: '.cmp-teaser--13409574 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                                    }}
                                    data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1621792052"
                                />
                            </div>
                            <div className="teaser teaser--full-size teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                                <div className="cmp-teaser cmp-teaser--1606096698  cmp-teaser--show-action  ">
                                    <div
                                        className="cmp-teaser__content"
                                        style={{ background: "#E1E0E0" }}
                                    >
                                        <div className="cmp-teaser__content-wrapper">

                                            <div className="cmp-teaser__description">
                                                {/* <p className='text-dark fs-4'>
                                                The time to deploy applications will be shorter since the cloud infrastructure including
                                                its hardware and software components is already in place.
                                            </p> */}
                                                <div class="title-wrap" >

                                                    <div class="description">
                                                        <p className='text-dark fs-6'>
                                                            The time to deploy applications will be shorter since the cloud infrastructure including
                                                            its hardware and software components is already in place.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="cmp-teaser__image">
                                        <img src="/cloud2 (1).png" />
                                    </div>
                                </div>
                                <style
                                    dangerouslySetInnerHTML={{
                                        __html: '.cmp-teaser--1606096698 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                                    }}
                                    data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1404454339"
                                />
                            </div>





                        </div>


                    </div>
                </div> <br />
            </section>

            <section id="cloudSlider" className="workCarousel">
                <div className="cust_container" style={{ sliderpaddingtop: '50px', sliderpaddingbottom: '5px' }}>
                    <div className="wCarousel slick-initialized slick-slider" data-slick="{&quot;slidesToShow&quot;: 3,&quot;slidesToScroll&quot;: 3,&quot;autoplay&quot;: false, &quot;infinite&quot;: false}">
                        <button type="button" data-role="none" className="slick-prev slick-arrow slick-disabled swiper-button-prev text-dark" aria-label="Previous" role="button" aria-disabled="true" style={{}}>Previous</button>
                        <Swiper
                            spaceBetween={30}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            }}
                            modules={[Navigation]}

                            loop={false}
                            autoplay={false}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                        >
                            <SwiperSlide>
                                <div className="card fadeIn animate">
                                    <div className="imageContainer">
                                        <img loading="lazy" src="/blogcard (2).png" alt="AI Integration" />
                                        {/* <div className="playButton" data-speed="-0.8">
                                            <a className='w-50' data-fancybox href="https://www.youtube.com/embed/dgBcSGiouW8?si=XU2ZhqT8UW4IZtHe" width="560" height="315" data-width="100%" rel="nofollow" tabIndex={0}>
                                                <img src="/button.svg" alt="play icon" />
                                            </a>

                                        </div> */}
                                    </div>
                                    <div className="textContainer  p-3">
                                        <p className="text-start   fw-bold fs-5" style={{ color: "#7F3E98" }}>AI Integration in Education</p>
                                        <p className="text-start " style={{ fontSize: "15px" }}>
                                            Artificial Intelligence is revolutionizing the learning process by providing personalized, data-
                                            driven feedback. AI helps create adaptive learning environments that cater to individual
                                            student needs, improving learning outcomes and preparing students for AI-influenced career
                                            landscapes.
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="card fadeIn animate">
                                    <div className="imageContainer">
                                        <img loading="lazy" src="/blogcard (1).png" alt="EdTech" />
                                        {/* <div className="playButton" data-speed="-0.8">
                                            <a data-fancybox href="https://www.youtube.com/embed/dgBcSGiouW8?si=XU2ZhqT8UW4IZtHe" width="560" height="315" data-width="100%" rel="nofollow" tabIndex={0}>
                                                <img src="/button.svg" alt="play icon" />
                                            </a>
                                        </div> */}
                                    </div>
                                    <div className="textContainer  p-3">
                                        <p className="text-start   fw-bold fs-5" style={{ color: "#7F3E98" }}>Focus on Mental Health and Well-being:</p>
                                        <p className="text-start" style={{ fontSize: "15px" }}>
                                            Cloud-based tools are also being deployed to
                                            support mental health in educational settings. With a growing emphasis on student well-being,
                                            platforms like Hazel Health and Panorama Education
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="card fadeIn animate">
                                    <div className="imageContainer">
                                        <img loading="lazy" src="/blogcard (3).png" alt="EdTech" />
                                        {/* <div className="playButton" data-speed="-0.8">
                                            <a data-fancybox href="https://player.vimeo.com/videos/1011992834" width="560" height="315" data-width="100%" rel="nofollow" tabIndex={0}>
                                                <img src="/button.svg" alt="play icon" />
                                            </a>
                                        </div> */}
                                    </div>
                                    <div className="textContainer  p-3">
                                        <p className="text-start fw-bold  fw-normal fs-5" style={{ color: "#7F3E98" }}>EdTech and Workforce Upskilling</p>
                                        <p className="text-start" style={{ fontSize: "15px" }}>
                                            The intersection of AI and cloud services is driving a
                                            surge in platforms focused on upskilling and reskilling. As automation changes the workforce,
                                            education platforms powered by cloud technologies
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="card fadeIn animate">
                                    <div className="imageContainer">
                                        <img loading="lazy" src="/blogcard (4).png" alt="EdTech" />
                                        {/* <div className="playButton" data-speed="-0.8">
                                            <a data-fancybox href="https://player.vimeo.com/videos/1011992834" width="560" height="315" data-width="100%" rel="nofollow" tabIndex={0}>
                                                <img src="/button.svg" alt="play icon" />
                                            </a>
                                        </div> */}
                                    </div>
                                    <div className="textContainer  p-3">
                                        <p className="text-start  fw-bold fs-5" style={{ color: "#7F3E98" }}>Virtual and Augmented Reality:
                                        </p>
                                        <p className="text-start" style={{ fontSize: "15px" }}>
                                            Cloud services are supporting the use of Virtual and Augmented Reality (VR/AR) in education.
                                            Institutions like Arizona State University are adopting VR in classrooms, particularly in science
                                            labs, to provide immersive and interactive learning experiences.
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper><br />
                        <button type="button" data-role="none" className="slick-next slick-arrow swiper-button-next text-dark" aria-label="Next" role="button" style={{}} aria-disabled="false">Next</button>
                    </div>
                </div>
            </section>
            {/* <Blog /> */}
            <br /><br />
            <div className=" responsivegrid aem-GridColumn aem-GridColumn--default--12" data-aos="fade-right" data-aos-delay="500">
                <div id="container-eb9fb998a4" className="cmp-container cmp-container--1563461404 " data-type="simple">
                    <div className="responsive-image-banner">

                        <div className="cmp-responsive-image-banner cmp-responsive-image-banner--1487958357">
                            <div className="cmp-responsive-image-banner__top-overlay">
                                <div className="cmp-responsive-image-banner__title"><p>CONNECTING INDUSTRIES WITH<br />
                                    CLOUD MATRIX</p>
                                </div>

                            </div>
                            <div className="cmp-responsive-image-banner__top-overlay-box" />
                            <div className="cmp-responsive-image-banner__content">
                                <div className="cmp-responsive-image-banner__section">

                                    <img className="cmp-responsive-image-banner__image" src="/cloudmatrix.png" alt="cloud-energy" />
                                    <div className="cmp-responsive-image-banner__text-overlay"><p className='w-100'>ENERGY AND UTILITIES<span style={{ fontSize: '18.0px' }} /></p>
                                    </div>
                                    <div className="cmp-responsive-image-banner__bottom-overlay-box" />

                                </div>
                                <div className="cmp-responsive-image-banner__section" target="_self">

                                    <img className="cmp-responsive-image-banner__image" src="/cloudmatrix1.png" alt="cloud-energy" />
                                    <div className="cmp-responsive-image-banner__text-overlay"><p className='w-100'>ENGINEERING<span style={{ fontSize: '18.0px' }} /></p>
                                    </div>
                                    <div className="cmp-responsive-image-banner__bottom-overlay-box" />

                                </div>
                                <div className="cmp-responsive-image-banner__section" target="_self">

                                    <img className="cmp-responsive-image-banner__image" src="/cloudmatrix2.png" alt="cloud-energy" />
                                    <div className="cmp-responsive-image-banner__text-overlay"><p className='w-100'>MEDICAL SCIENCES<span style={{ fontSize: '18.0px' }} /></p>
                                    </div>
                                    <div className="cmp-responsive-image-banner__bottom-overlay-box" />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cmp-container__button-container">
                    </div>
                    <div className="cmp-container__popup-background" id="popup-background-container-eb9fb998a4" />
                    <div className="cmp-container__data" id="data-container-eb9fb998a4" data-expandbuttonlabel="Read More" data-maxitems={-2} data-maxitemstablet={-2} data-maxitemsmobile={-2} />
                </div>
            </div>

            <Partners />
            <Demo />
        </div >
    )
}

export default Cloud