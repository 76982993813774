import React from 'react'

import Demo2 from './Demo2'

import Demo from '../components/Navbar';
import { useMediaQuery } from 'react-responsive'
import Header from '../components/Header';


const Consulting = () => {
    const services = [
        // "CIO Advisory",
        // "Sustainability Services",
        // "Talent and Change Management",
        // "Digital Transformation",
        // "Banking",
        // "Energy",
        // "Utilities",
        // "Consumer Packaged Goods",
        // "Healthcare",
        // "Life Sciences",
        // "Retail",
        // "Telecom"
        { title: 'Monitoring Services Consultation', icon: '/Monitoring Services 1.png' },
        { title: 'Managed LAN/WAN Services', icon: '/Network Design 1.png' },
        { title: 'Managed WiFi Services', icon: '/NetworkHardware.png' },
        { title: 'Network Security Consultation', icon: '/Network Security 1.png' },
        { title: 'Infrastructure Consultation', icon: '/infrastructure 1.png' },
        { title: 'Cloud Services Consultation', icon: '/Cloud Services 1.png' },
        { title: 'Wireless Network Consultation', icon: '/Wireless Network 1.png' },
        // { title: 'White Label VPN Services', icon: '/service8.png' },
        { title: 'Network Hardware Consultation', icon: '/service9.png' }
    ];
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
    }
    const Tablet = ({ children }) => {
        const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
        return isTablet ? children : null
    }
    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ minWidth: 20, maxWidth: 991 })
        return isMobile ? children : null
    }
    const Default = ({ children }) => {
        const isNotMobile = useMediaQuery({ minWidth: 768 })
        return isNotMobile ? children : null
    }
    return (
        <div className='' >
            <Header />
            <div className="  responsivegrid">
                <div
                    className="cmp- cmp---939597271 "
                    data-type="grid"
                    id="-296a049e61"
                >
                    <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">

                        <div className=" responsivegrid aem-GridColumn aem-GridColumn--default--12">
                            <div
                                className="cmp- cmp---220486293 "
                                data-type="grid"
                                id="-3aab8fef1f"
                            >
                                <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                                    {/* <Desktop> */}
                                    <section id="heros" class="heros1 section dark-background">

                                        <img src="/consulting.svg" alt="" />

                                        <div class="container text-start"  >
                                            <div class="row justify-content-start">
                                                <div class="col-lg-8">
                                                    <h2 className='profh2'>Consulting meet transformation</h2>
                                                </div>
                                            </div>
                                        </div>

                                    </section>
                                    {/* </Desktop>
                                    <Mobile>
                                        <section id="heros" class="heros1 section dark-background">

                                            <img src="/consultingmob.png" alt="" className='' />

                                            <div class="container text-center"  >
                                                <div class="row justify-content-center">
                                                    <div class="col-lg-12 col-sm-12">
                                                        <h2 className='fs-1'>Consulting meet transformation</h2>
                                                    </div>
                                                </div>
                                            </div>

                                        </section>
                                    </Mobile> */}
                                    <div className=" responsivegrid --width-wide --full-width aem-GridColumn aem-GridColumn--default--12">
                                        <div
                                            className="cmp- cmp---1058722595 "
                                            data-type="grid"
                                            id="-4af8be69f5"
                                        >
                                            <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 aem-Grid--phone--12 ">
                                                <section id="bg2" class="bg3 section dark-background" >


                                                    <div class="container text-center" >
                                                        <div class="row justify-content-center">
                                                            <div class="col-lg-12 col-sm-12">

                                                                <p className=' text-center  mx-auto' style={{ border: "2px solid #7F3E98 ", borderRadius: "20px" }}>Spotcomm harnesses cutting-edge technologies and extensive industry expertise to support clients in driving business transformation and developing robust, next-generation operating models. Our consulting practice spans various sectors, including financial services, energy, utilities, healthcare and life sciences, sustainability, talent management and change, CIO advisory, and consumer businesses. Explore some of the latest insights from Spotcomm Consulting, Full Stride Cloud, Engineering Edge, Lab45, Designit, and Capco.</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </section>
                                                <div className="teaser teaser--full-size aem-GridColumn aem-GridColumn--default--12" data-aos-delay="200">
                                                    <div className="cmp-teaser cmp-teaser--1095483950    ">
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{ background: "#E1E0E0" }}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  text-dark ">
                                                                    <div
                                                                        className="cmp-teaser__title-link"

                                                                        rel="nofollow"
                                                                        target="_blank"
                                                                    >
                                                                        HPE (Hewlett Packard Enterprise) and Huawei Named Leaders:
                                                                    </div>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p className='text-dark'>
                                                                        Both HPE and Huawei were recently recognized as leaders in the IDC MarketScape: Worldwide Network Consulting Services 2024 Vendor Assessment. HPE was acknowledged for its excellence in handling complex transformation projects and leveraging cutting-edge AI and automation capabilities. Huawei similarly earned leadership status for its end-to-end network consulting capabilities, catering to evolving business needs in areas like edge computing and data flow management.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/icon (4).png" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="teaser teaser--full-size teaser--image-left aem-GridColumn aem-GridColumn--default--12" data-aos-delay="200">
                                                    <div className="cmp-teaser cmp-teaser--638358011    ">
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{ background: "#E1E0E0" }}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  text-dark ">
                                                                    <div
                                                                        className="cmp-teaser__title-link"
                                                                        href="https://www.capco.com/intelligence/capco-intelligence/will-the-new-virtual-banks-reshape-the-thailand-banking-industry"
                                                                        rel="nofollow"
                                                                        target="_blank"
                                                                    >
                                                                        Growing Importance of AI and Automation:
                                                                    </div>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p className='text-dark'>
                                                                        Companies offering network consulting services are increasingly incorporating AI and automation to manage data centers and wireless networks more efficiently. These technologies allow for better observability and management, making networks more adaptable to dynamic business environments
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/icon (2).png" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="teaser teaser--full-size aem-GridColumn aem-GridColumn--default--12" data-aos-delay="200">
                                                    <div className="cmp-teaser cmp-teaser--718471742    ">
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{ background: "#E1E0E0" }}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  text-dark ">
                                                                    <div
                                                                        className="cmp-teaser__title-link"
                                                                        href="/cloud/joint-report-with-forbes-how-to-master-cloud-economics-and-harness-the-power-of-ai/"
                                                                        target="_blank"
                                                                    >
                                                                        Harness digital to get disruptive results:
                                                                    </div>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p className='text-dark'>
                                                                        Leveraging our digital-led expertise, we devise an agile and flexible approach to help companies unlock new growth opportunities. Our digital consulting services are custom-tailored, enabling clients to experience game-changing outcomes.

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/icon (5).png" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="teaser teaser--full-size teaser--image-left aem-GridColumn aem-GridColumn--default--12" data-aos-delay="200">
                                                    <div
                                                        className="cmp-teaser cmp-teaser-2010868465    "
                                                        id="insights"
                                                    >
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{ background: "#E1E0E0" }}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  text-dark ">
                                                                    <div
                                                                        className="cmp-teaser__title-link"
                                                                        href="https://www.designit.com/stories/point-of-view/why-should-insurance-companies-rethink-their-loyalty-strategies"
                                                                        rel="nofollow"
                                                                        target="_blank"
                                                                    >
                                                                        Future-proof approach to digital strategy:
                                                                    </div>
                                                                </h2>  <br />
                                                                <div className="cmp-teaser__description">
                                                                    <p className='text-dark'>
                                                                        The pace of digital change is rapidly increasing, and so is the number of demanding customers. We help our clients orchestrate all elements of an efficient digital value chain by leveraging the best digital capabilities, empowering them to cultivate long-term growth.

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/icon (3).png" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* next */}





                                            </div>


                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <section className="services3">
                <h2 className='fw-bold fs-2'>

                </h2>
                <div className="border-header">
                    <div className="cmp-border-header cmp-border-header-650763923">
                        {/* <div className="cmp-border-header__top-overlay">
                            <div className="cmp-border-header__title text-center d-flex justify-content-center mx-auto">
                                <h2 className='fs-2 text-center fw-bold mx-auto'>

                                    <span className=" ">Consultation </span>Services
                                </h2>

                            </div>

                        </div> */}
                        <div className="rad-header rad-header__headline-medium">
                            <div
                                className="rad-header__wrapper"
                                data-analytics-template-zone="block-reinvent-with-cybersecurity"
                            >
                                <div className="rad-header__text-container d-flex justify-content-center mx-auto">
                                    <h2 className="rad-header__headline text-dark text-center d-flex justify-content-center mx-auto">

                                        <span className="mx-3">Consultation </span> Services
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="services3-grid">
                            {services.map((service, index) => (
                                <div key={index} className="service3-items d-block p-3 h-100" style={{ height: "auto" }}>
                                    <img src={service.icon} className="mx-auto" />  <br />
                                    {service.title}
                                </div>
                            ))}
                        </div>

                    </div>
                </div> <br />
            </section><br />
            <div className="mosaicv2">
                <div
                    data-cmp-data-layer='{"block-reinvent-with-cybersecurity":{"@type":"cio-sites/components/rad/mosaicv2","analytics-module-name":"mosaicv2-1","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                    id="block-reinvent-with-cybersecurity"
                    className="rad-mosaic-2 rad-mosaic-2--7-card"
                >
                    {/* <div className="rad-header rad-header__headline-medium">
                        <div
                            className="rad-header__wrapper"
                            data-analytics-template-zone="block-reinvent-with-cybersecurity"
                        >
                            <div className="rad-header__text-container">
                                <h2 className="rad-header__headline text-dark text-center">
                                    Consultation 
                                </h2>
                            </div>
                        </div>
                    </div> */}
                    <div className="rad-header rad-header__headline-medium">
                        <div
                            className="rad-header__wrapper"
                            data-analytics-template-zone="block-reinvent-with-cybersecurity"
                        >
                            <div className="rad-header__text-container d-flex justify-content-center mx-auto">
                                <h2 className="rad-header__headline text-dark text-center d-flex justify-content-center mx-auto">

                                    <span className="mx-3"> Consultation  </span> Trends
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="rad-mosaic-2__grid">
                        {/* horizontal with image or stat-card */}
                        <div
                            className="rad-mosaic-2__card rad-mosaic-2__card--mobile rad-mosaic-2__card--horizontal rad-mosaic-2__card--image"
                            data-analytics-asset-id="card-a2e851b4c1"
                            data-analytics-asset-pos={1}
                            data-analytics-skim-click=""
                        >
                            {/* DEV NOTE: aria-label text is copied from __eyebrow, __title, and __card-image's `alt` below */}
                            <button
                                aria-controls="mosaic-card-item0-49699ede14ba24f991b7f30eb5e135d0e482c7bdb6065277c6fdc74a101a3821"
                                aria-expanded="false"
                                aria-label="Learn more: Design cyber risk strategies, aligned to business priorities"
                                className="rad-mosaic-2__card-front-toggle"
                            />
                            {/* DEV NOTE: aria-label text is copied from __card-body and rad-button--tertiary below */}
                            <div
                                c className="rad-mosaic-2__card-cta-cover"
                                tabIndex={-1}
                                aria-label="Learn more: Design cyber risk strategies, aligned to business priorities"
                                target="_self"
                                data-analytics-asset-id="card-a2e851b4c1"
                                data-analytics-asset-pos={1}
                                data-barba-transition=""
                                data-cmp-clickable=""
                                data-cmp-data-layer='{"mosaic-card-item0-49699ede14ba24f991b7f30eb5e135d0e482c7bdb6065277c6fdc74a101a3821":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-strategy","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Strategy","analytics-module-name":"mosaicv2card-1","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                            />
                            <div
                                aria-hidden="true"
                                className="rad-icon-button rad-mosaic-2__card-close-button"
                                tabIndex={-1}
                            >
                                <div className="rad-icon-button__text">Close</div>
                                <div className="rad-icon rad-icon__close" />
                            </div>
                            <div aria-hidden="true" className="rad-mosaic-2__card-eyebrow text-light">
                                The Role of Network Consultation
                            </div>
                            <div
                                aria-hidden="true"
                                className="rad-mosaic-2__card-sliding-content"
                            >
                                <div className="rad-mosaic-2__card-front-content">
                                    <h3 className="rad-mosaic-2__card-title text-light">
                                        The Role of Network Consultation in Digital Transformation for Businesses
                                        network consultation experts guides businesses through digital transformation, ensuring scalable, secure, and efficient network infrastructures.

                                    </h3>
                                    <div className="rad-mosaic-2__card-image">
                                        <div
                                            data-cmp-is="image"
                                            data-cmp-dmimage=""
                                            data-cmp-src="/cons (2).png"
                                            data-asset-id="e6bc36b7-72cd-4e67-b3f3-35046d6c776a"
                                            data-cmp-filereference="/content/dam/accenture/final/accenture-com/imagery-2/Accenture-security-index-mosaic-image1.jpg"
                                            id="image-18e7585186"
                                            data-cmp-data-layer='{"radimage-18e7585186":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-2"}}'
                                            data-cmp-hook-image="imageV3"
                                            className="cmp-image
     "
                                            itemScope=""
                                            itemType="http://schema.org/ImageObject"
                                        >
                                            <img
                                                src="/cons (2).png"
                                                loading="lazy"
                                                className="cmp-image__image"
                                                itemProp="contentUrl"
                                                width={3841}
                                                height={2160}
                                                alt=""
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="rad-mosaic-2__card-back-content">
                                    <div className="rad-mosaic-2__card-body">
                                        Cybersecurity isn’t just an IT issue – it's a critical
                                        business priority. Integrating your cybersecurity strategy
                                        with business goals is essential for driving reinvention
                                        and growth.
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div
                            className="rad-mosaic-2__card rad-mosaic-2__card--desktop rad-mosaic-2__card--horizontal rad-mosaic-2__card--image"
                            c target="_self"
                            data-analytics-asset-id="card-a2e851b4c1"
                            data-analytics-asset-pos={1}
                            data-barba-transition=""
                            data-cmp-clickable=""
                            data-analytics-skim-hover=""
                            data-cmp-data-layer='{"mosaic-card-item0-49699ede14ba24f991b7f30eb5e135d0e482c7bdb6065277c6fdc74a101a3821":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-strategy","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Strategy","analytics-module-name":"mosaicv2card-1","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                        >
                            <div
                                className="rad-mosaic-2__card-description-content"
                                data-analytics-asset-id="card-a2e851b4c1"
                                data-analytics-asset-pos={1}
                            >
                                <div className="rad-mosaic-2__card-eyebrow text-light">
                                    The Role of Network Consultation
                                </div>
                                <div className="rad-mosaic-2__card-sliding-title">
                                    <h3 className="rad-mosaic-2__card-title text-light">
                                        Understanding the Essential Role of Effective Network Consultation Services
                                    </h3>
                                    <div className="rad-mosaic-2__card-body">
                                        The Role of Network Consultation in Digital Transformation for Businesses
                                        network consultation experts guides businesses through digital transformation, ensuring scalable, secure, and efficient network infrastructures.

                                    </div>
                                </div>

                            </div>
                            <div
                                className="rad-mosaic-2__card-figure"
                                data-analytics-asset-id="card-a2e851b4c1"
                                data-analytics-asset-pos={1}
                            >
                                <div className="rad-mosaic-2__card-image">
                                    <div
                                        data-cmp-is="image"
                                        data-cmp-dmimage=""
                                        data-cmp-src="/cons (2).png"
                                        data-asset-id="e6bc36b7-72cd-4e67-b3f3-35046d6c776a"
                                        data-cmp-filereference="/content/dam/accenture/final/accenture-com/imagery-2/Accenture-security-index-mosaic-image1.jpg"
                                        id="image-18e7585186"
                                        data-cmp-data-layer='{"radimage-18e7585186":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-2"}}'
                                        data-cmp-hook-image="imageV3"
                                        className="cmp-image
     "
                                        itemScope=""
                                        itemType="http://schema.org/ImageObject"
                                    >
                                        <img
                                            src="/cons (2).png"
                                            loading="lazy"
                                            className="cmp-image__image"
                                            itemProp="contentUrl"
                                            width={3841}
                                            height={2160}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* vertical with image or stat card */}
                        {/* no image*/}
                        {/* vertical with image or stat card */}
                        {/* no image*/}
                        {/* no image*/}
                        {/* no image*/}
                        {/* horizontal with image or stat-card */}
                        {/* vertical with image or stat card */}
                        <div
                            className="rad-mosaic-2__card rad-mosaic-2__card--mobile rad-mosaic-2__card--vertical rad-mosaic-2__card--image"
                            data-analytics-asset-id="card-2cdbc66562"
                            data-analytics-asset-pos={2}
                            data-analytics-skim-click=""
                        >
                            {/* DEV NOTE: aria-label text is copied from __eyebrow, __title, and __card-image's `alt` below */}
                            <button
                                className="rad-mosaic-2__card-front-toggle"
                                aria-expanded="false"
                                aria-controls="mosaic-card-item1-9b2845c8f7209ebd347f0888b97301b549cb03cf259d94bc4932793ec3c381c7"
                                aria-label="Learn more: Understand new threats, prepare and respond quickly to attacks"
                            />
                            {/* DEV NOTE: aria-label text is copied from __card-body and rad-button--tertiary below */}
                            <div
                                cy className="rad-mosaic-2__card-cta-cover"
                                tabIndex={-1}
                                aria-label="Learn more: Understand new threats, prepare and respond quickly to attacks"
                                target="_self"
                                data-barba-transition=""
                                data-cmp-clickable=""
                                data-analytics-asset-id="card-2cdbc66562"
                                data-analytics-asset-pos={2}
                                data-cmp-data-layer='{"mosaic-card-item1-9b2845c8f7209ebd347f0888b97301b549cb03cf259d94bc4932793ec3c381c7":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-resilience","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Resilience","analytics-module-name":"mosaicv2card-2","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                            />
                            <div
                                className="rad-icon-button rad-mosaic-2__card-close-button"
                                tabIndex={-1}
                                aria-hidden="true"
                            >
                                <div className="rad-icon-button__text">Close</div>
                                <div className="rad-icon rad-icon__close" />
                            </div>
                            <div className="rad-mosaic-2__card-eyebrow text-light" aria-hidden="true">
                                Cyber Resilience
                            </div>
                            <div
                                className="rad-mosaic-2__card-sliding-content"
                                aria-hidden="true"
                            >
                                <div className="rad-mosaic-2__card-front-content">
                                    <h3 className="rad-mosaic-2__card-title text-light">
                                        Ready to respond quickly to unseen security threats and
                                        new regulations?
                                    </h3>
                                    <div className="rad-mosaic-2__card-image">
                                        <div
                                            data-cmp-is="image"
                                            data-cmp-dmimage=""
                                            data-cmp-src="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-afs-index-mosaic-image1?ts=1721844847908&wid=625&dpr=off"
                                            data-asset-id="a0f4780f-06bf-48a7-a917-91808fd9bb5a"
                                            data-cmp-filereference="/content/dam/accenture/final/accenture-com/imagery-2/Accenture-afs-index-mosaic-image1.jpg"
                                            id="image-fbc5260e11"
                                            data-cmp-data-layer='{"radimage-fbc5260e11":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-3"}}'
                                            data-cmp-hook-image="imageV3"
                                            className="cmp-image
     "
                                            itemScope=""
                                            itemType="http://schema.org/ImageObject"
                                        >
                                            <img
                                                src="/cons (1).png"
                                                loading="lazy"
                                                className="cmp-image__image"
                                                itemProp="contentUrl"
                                                width={3840}
                                                height={2160}
                                                alt=""
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="rad-mosaic-2__card-back-content">
                                    <div className="rad-mosaic-2__card-body">
                                        74% of CEOs worry about their ability to minimize
                                        cyberattacks. Organizations must adapt swiftly and scale
                                        operations with real-time insights. Discover how to
                                        identify threats and respond fast.
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div
                            cy className="rad-mosaic-2__card rad-mosaic-2__card--desktop rad-mosaic-2__card--vertical rad-mosaic-2__card--image"
                            target="_self"
                            data-barba-transition=""
                            data-cmp-clickable=""
                            data-analytics-asset-id="card-2cdbc66562"
                            data-analytics-asset-pos={2}
                            data-analytics-skim-hover=""
                            data-cmp-data-layer='{"mosaic-card-item1-9b2845c8f7209ebd347f0888b97301b549cb03cf259d94bc4932793ec3c381c7":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-resilience","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Resilience","analytics-module-name":"mosaicv2card-2","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                        >
                            <div
                                className="rad-mosaic-2__card-description-content"
                                data-analytics-asset-id="card-2cdbc66562"
                                data-analytics-asset-pos={2}
                            >
                                <div className="rad-mosaic-2__card-eyebrow text-light">
                                    Cybersecurity Posture
                                </div>
                                <div className="rad-mosaic-2__card-sliding-title">
                                    <h3 className="rad-mosaic-2__card-title text-light">
                                        Ready to respond quickly to unseen security threats and
                                        new regulations?
                                    </h3>
                                    <div className="rad-mosaic-2__card-body">
                                        How Network Consultation Can Improve Cybersecurity Posture
                                        the role of network consultants in identifying vulnerabilities, implementing security measures, and improving overall network defense against cyber threats.

                                    </div>
                                </div>

                            </div>
                            <div
                                className="rad-mosaic-2__card-figure"
                                data-analytics-asset-id="card-2cdbc66562"
                                data-analytics-asset-pos={2}
                            >
                                <div className="rad-mosaic-2__card-image">
                                    <div
                                        data-cmp-is="image"
                                        data-cmp-dmimage=""
                                        data-cmp-src="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-afs-index-mosaic-image1?ts=1721844847908&wid=625&dpr=off"
                                        data-asset-id="a0f4780f-06bf-48a7-a917-91808fd9bb5a"
                                        data-cmp-filereference="/content/dam/accenture/final/accenture-com/imagery-2/Accenture-afs-index-mosaic-image1.jpg"
                                        id="image-fbc5260e11"
                                        data-cmp-data-layer='{"radimage-fbc5260e11":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-3"}}'
                                        data-cmp-hook-image="imageV3"
                                        className="cmp-image
     "
                                        itemScope=""
                                        itemType="http://schema.org/ImageObject"
                                    >
                                        <img
                                            src="/cons (1).png"
                                            loading="lazy"
                                            className="cmp-image__image"
                                            itemProp="contentUrl"
                                            width={3840}
                                            height={2160}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* no image*/}
                        {/* vertical with image or stat card */}
                        {/* no image*/}
                        {/* no image*/}
                        {/* no image*/}
                        {/* horizontal with image or stat-card */}
                        {/* vertical with image or stat card */}
                        {/* no image*/}
                        <div
                            cy className="rad-mosaic-2__card rad-mosaic-2__card--desktop rad-mosaic-2__card--vertical rad-mosaic-2__card--image"
                            target="_self"
                            data-barba-transition=""
                            data-cmp-clickable=""
                            data-analytics-asset-id="card-2cdbc66562"
                            data-analytics-asset-pos={2}
                            data-analytics-skim-hover=""
                            data-cmp-data-layer='{"mosaic-card-item1-9b2845c8f7209ebd347f0888b97301b549cb03cf259d94bc4932793ec3c381c7":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-resilience","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Resilience","analytics-module-name":"mosaicv2card-2","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                        >
                            <div
                                className="rad-mosaic-2__card-description-content"
                                data-analytics-asset-id="card-2cdbc66562"
                                data-analytics-asset-pos={2}
                            >
                                <div className="rad-mosaic-2__card-eyebrow text-light">
                                    Educational Institutions
                                </div>
                                <div className="rad-mosaic-2__card-sliding-title">
                                    <h3 className="rad-mosaic-2__card-title text-light">
                                        Empowering Educational Institutions with Advanced Technology
                                    </h3>
                                    <div className="rad-mosaic-2__card-body">
                                        Why Educational Institutions Need Network Consultation for Enhanced Learning Experiences
                                        educational institutions can benefit from network consultation to design scalable, secure networks that support online learning and administrative functions.

                                    </div>
                                </div>

                            </div>
                            <div
                                className="rad-mosaic-2__card-figure"
                                data-analytics-asset-id="card-2cdbc66562"
                                data-analytics-asset-pos={2}
                            >
                                <div className="rad-mosaic-2__card-image">
                                    <div
                                        data-cmp-is="image"
                                        data-cmp-dmimage=""
                                        data-cmp-src="https://dynamicmedia.accenture.com/is/image/accenture/Accenture-afs-index-mosaic-image1?ts=1721844847908&wid=625&dpr=off"
                                        data-asset-id="a0f4780f-06bf-48a7-a917-91808fd9bb5a"
                                        data-cmp-filereference="/content/dam/accenture/final/accenture-com/imagery-2/Accenture-afs-index-mosaic-image1.jpg"
                                        id="image-fbc5260e11"
                                        data-cmp-data-layer='{"radimage-fbc5260e11":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-3"}}'
                                        data-cmp-hook-image="imageV3"
                                        className="cmp-image
     "
                                        itemScope=""
                                        itemType="http://schema.org/ImageObject"
                                    >
                                        <img
                                            src="/cons (3).png"
                                            loading="lazy"
                                            className="cmp-image__image"
                                            itemProp="contentUrl"
                                            width={3840}
                                            height={2160}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="rad-mosaic-2__card rad-mosaic-2__card--mobile"
                            data-analytics-asset-id="card-3a77aa9043"
                            data-analytics-asset-pos={3}
                            data-analytics-skim-click=""
                        >
                            {/* DEV NOTE: aria-label text is copied from __eyebrow and __title below */}
                            <button
                                className="rad-mosaic-2__card-front-toggle"
                                aria-expanded="false"
                                aria-controls="mosaic-card-item2-871e19660a9c9ad3aa38f478b7e8566be582e001f46ef2385a3f03423a30d139"
                                aria-label="Learn more: Secure the digital core as you transform the business"
                            />
                            {/* DEV NOTE: aria-label text is copied from __card-body and rad-button--tertiary below */}
                            <div
                                cy className="rad-mosaic-2__card-cta-cover"
                                tabIndex={-1}
                                target="_self"
                                aria-label="Learn more: Secure the digital core as you transform the business"
                                data-barba-transition=""
                                data-cmp-clickable=""
                                data-analytics-asset-id="card-3a77aa9043"
                                data-analytics-asset-pos={3}
                                data-cmp-data-layer='{"mosaic-card-item2-871e19660a9c9ad3aa38f478b7e8566be582e001f46ef2385a3f03423a30d139":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-protection","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Protection","analytics-module-name":"mosaicv2card-3","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                            />
                            <div
                                className="rad-icon-button rad-mosaic-2__card-close-button"
                                tabIndex={-1}
                                aria-hidden="true"
                            >
                                <div className="rad-icon-button__text">Close</div>
                                <div className="rad-icon rad-icon__close" />
                            </div>
                            <div className="rad-mosaic-2__card-eyebrow text-light" aria-hidden="true">
                                Bandwidth Management

                            </div>
                            <div
                                className="rad-mosaic-2__card-sliding-content"
                                aria-hidden="true"
                            >
                                <div className="rad-mosaic-2__card-front-content">
                                    <h3 className="rad-mosaic-2__card-title text-light">
                                        Optimizing Bandwidth Management for Efficient and Reliable Network Performance
                                    </h3>

                                </div>
                                <div className="rad-mosaic-2__card-back-content">
                                    <div className="rad-mosaic-2__card-body">
                                        Optimizing Network Performance: The Role of Network Consultation in Bandwidth Management
                                        network consultants helps businesses optimize bandwidth usage, reduce latency, and improve overall network performance.

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div
                            cy className="rad-mosaic-2__card rad-mosaic-2__card--desktop"
                            target="_self"
                            aria-label="Learn more: Secure the digital core as you transform the business"
                            data-barba-transition=""
                            data-cmp-clickable=""
                            data-analytics-asset-id="card-3a77aa9043"
                            data-analytics-asset-pos={3}
                            data-analytics-skim-hover=""
                            data-cmp-data-layer='{"mosaic-card-item2-871e19660a9c9ad3aa38f478b7e8566be582e001f46ef2385a3f03423a30d139":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-protection","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Protection","analytics-module-name":"mosaicv2card-3","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                        >
                            <div
                                className="rad-mosaic-2__card-description-content"
                                data-analytics-asset-id="card-3a77aa9043"
                                data-analytics-asset-pos={3}
                            >
                                <div className="rad-mosaic-2__card-eyebrow text-light">
                                    Bandwidth Management

                                </div>
                                <div className="rad-mosaic-2__card-sliding-title">
                                    <h3 className="rad-mosaic-2__card-title text-light">
                                        Optimizing Bandwidth Management for Efficient and Reliable Network.
                                    </h3>
                                    <div className="rad-mosaic-2__card-body">
                                        Optimizing Network Performance: The Role of Network Consultation in Bandwidth Management
                                        network consultants helps businesses optimize bandwidth usage, reduce latency, and improve overall network performance.

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* vertical with image or stat card */}
                        {/* no image*/}
                        {/* no image*/}
                        {/* no image*/}
                        {/* horizontal with image or stat-card */}
                        {/* vertical with image or stat card */}
                        {/* no image*/}
                        {/* vertical with image or stat card */}


                        <div
                            className="rad-mosaic-2__card rad-mosaic-2__card--mobile"
                            data-analytics-asset-id="card-523956962c"
                            data-analytics-asset-pos={5}
                            data-analytics-skim-click=""
                        >
                            {/* DEV NOTE: aria-label text is copied from __eyebrow and __title below */}
                            <button
                                className="rad-mosaic-2__card-front-toggle"
                                aria-expanded="false"
                                aria-controls="mosaic-card-item4-206aa89afe176c740a5f5d0a71763597ccc84018b84175401528aeb8fd56ead0"
                                aria-label="Learn more: Operational technology and product security"
                            />
                            {/* DEV NOTE: aria-label text is copied from __card-body and rad-button--tertiary below */}
                            <div
                                ph
                                className="rad-mosaic-2__card-cta-cover"
                                tabIndex={-1}
                                target="_self"
                                aria-label="Learn more: Operational technology and product security"
                                data-cmp-clickable=""
                                data-analytics-asset-id="card-523956962c"
                                data-analytics-asset-pos={5}
                                data-cmp-data-layer='{"mosaic-card-item4-206aa89afe176c740a5f5d0a71763597ccc84018b84175401528aeb8fd56ead0":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/physical-security","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber-Physical Security","analytics-module-name":"mosaicv2card-5","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                            />
                            <div
                                className="rad-icon-button rad-mosaic-2__card-close-button"
                                tabIndex={-1}
                                aria-hidden="true"
                            >
                                <div className="rad-icon-button__text">Close</div>
                                <div className="rad-icon rad-icon__close" />
                            </div>
                            <div className="rad-mosaic-2__card-eyebrow text-light" aria-hidden="true">
                                Cyber-Physical Security
                            </div>
                            <div
                                className="rad-mosaic-2__card-sliding-content"
                                aria-hidden="true"
                            >
                                <div className="rad-mosaic-2__card-front-content">
                                    <h3 className="rad-mosaic-2__card-title text-light">
                                        Protect your operational technology and connected products
                                        confidently
                                    </h3>

                                </div>
                                <div className="rad-mosaic-2__card-back-content">
                                    <div className="rad-mosaic-2__card-body">
                                        With greater connectivity comes more operational and smart
                                        product vulnerability. Turn infrastructure security into a
                                        business enabler with seamless cyber-physical protection
                                        for a safer, smarter future.
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div
                            ph
                            className="rad-mosaic-2__card rad-mosaic-2__card--desktop"
                            target="_self"
                            aria-label="Learn more: Operational technology and product security"
                            data-cmp-clickable=""
                            data-analytics-asset-id="card-523956962c"
                            data-analytics-asset-pos={5}
                            data-analytics-skim-hover=""
                            data-cmp-data-layer='{"mosaic-card-item4-206aa89afe176c740a5f5d0a71763597ccc84018b84175401528aeb8fd56ead0":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/physical-security","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber-Physical Security","analytics-module-name":"mosaicv2card-5","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                        >
                            <div
                                className="rad-mosaic-2__card-description-content"
                                data-analytics-asset-id="card-523956962c"
                                data-analytics-asset-pos={5}
                            >
                                <div className="rad-mosaic-2__card-eyebrow text-light">
                                    Ensuring Business Continuity and Disaster Recovery
                                </div>
                                <div className="rad-mosaic-2__card-sliding-title">
                                    <h3 className="rad-mosaic-2__card-title text-light">
                                        Ensuring Business Continuity with Reliable Disaster Recovery Solutions
                                    </h3>
                                    <div className="rad-mosaic-2__card-body">
                                        The Role of Network Consultation in Ensuring Business Continuity and Disaster Recovery
                                        Experts assist businesses in developing reliable business continuity plans and implementing disaster recovery strategies to minimize downtime.

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            className="rad-mosaic-2__card rad-mosaic-2__card--desktop rad-mosaic-2__card--horizontal rad-mosaic-2__card--image"
                            c target="_self"
                            data-analytics-asset-id="card-a2e851b4c1"
                            data-analytics-asset-pos={1}
                            data-barba-transition=""
                            data-cmp-clickable=""
                            data-analytics-skim-hover=""
                            data-cmp-data-layer='{"mosaic-card-item0-49699ede14ba24f991b7f30eb5e135d0e482c7bdb6065277c6fdc74a101a3821":{"@type":"cio-sites/components/rad/mosaicv2","xdm:linkURL":"/us-en/services/cybersecurity/cyber-strategy","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"Learn more:Cyber Strategy","analytics-module-name":"mosaicv2card-1","analytics-template-zone":"block-reinvent-with-cybersecurity"}}'
                        >
                            <div
                                className="rad-mosaic-2__card-description-content"
                                data-analytics-asset-id="card-a2e851b4c1"
                                data-analytics-asset-pos={1}
                            >
                                <div className="rad-mosaic-2__card-eyebrow text-light">
                                    Cloud Migration Strategies
                                </div>
                                <div className="rad-mosaic-2__card-sliding-title">
                                    <h3 className="rad-mosaic-2__card-title text-light">
                                        Effective Cloud Migration Strategies for Seamless Business Transformation
                                    </h3>
                                    <div className="rad-mosaic-2__card-body">
                                        With greater connectivity comes more operational and smart
                                        product vulnerability. Turn infrastructure security into a
                                        business enabler with seamless cyber-physical protection
                                        for a safer, smarter future.

                                    </div>
                                </div>

                            </div>
                            <div
                                className="rad-mosaic-2__card-figure"
                                data-analytics-asset-id="card-a2e851b4c1"
                                data-analytics-asset-pos={1}
                            >
                                <div className="rad-mosaic-2__card-image">
                                    <div
                                        data-cmp-is="image"
                                        data-cmp-dmimage=""
                                        data-cmp-src="/cons (2).png"
                                        data-asset-id="e6bc36b7-72cd-4e67-b3f3-35046d6c776a"
                                        data-cmp-filereference="/content/dam/accenture/final/accenture-com/imagery-2/Accenture-security-index-mosaic-image1.jpg"
                                        id="image-18e7585186"
                                        data-cmp-data-layer='{"radimage-18e7585186":{"@type":"cio-sites/components/rad/radimage","analytics-engagement":"true","analytics-link-type":"engagement","analytics-link-name":"","analytics-module-name":"radimage-2"}}'
                                        data-cmp-hook-image="imageV3"
                                        className="cmp-image
     "
                                        itemScope=""
                                        itemType="http://schema.org/ImageObject"
                                    >
                                        <img
                                            src="/cons (4).png"
                                            loading="lazy"
                                            className="cmp-image__image"
                                            itemProp="contentUrl"
                                            width={3841}
                                            height={2160}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <br /><br />
            {/* <div className=" responsivegrid --width-wide --full-width aem-GridColumn aem-GridColumn--default--12">
                <div
                    className="cmp- cmp---1058722595 "
                    data-type="grid"
                    id="-4af8be69f5"
                >
                    <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 aem-Grid--phone--12 ">
                        <div className=" responsivegrid --width-regular --full-width aem-GridColumn aem-GridColumn--default--12">
                            <div
                                className="cmp- cmp--1070173647 "
                                data-type="simple"
                                id="-aee7dd742e"
                                style={{
                                    backgroundColor: '#362358',
                                    // border: "1px solid #000"
                                }}
                            >

                                <div
                                    className="cmp-__popup-background"
                                    id="popup-background--aee7dd742e"
                                />
                                <div
                                    className="cmp-__data"
                                    data-expandaspopup="false"
                                    data-expandbuttonlabel="Read More"
                                    data-maxitems="-2"
                                    data-maxitemsmobile="-2"
                                    data-maxitemstablet="-2"
                                    id="data--aee7dd742e"
                                />
                            </div>
                        </div>
                        <div className="teaser teaser--full-size teaser--image-left teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                            <div className="cmp-teaser cmp-teaser--1828042053  cmp-teaser--show-action  ">
                                <div
                                    className="cmp-teaser__content"
                                    style={{ background: "#E1E0E0" }}
                                >
                                    <div className="cmp-teaser__content-wrapper">
                                        <h2 className="cmp-teaser__title  text-dark ">
                                            <div className="cmp-teaser__title fw-bold fs-3" style={{ color: "#7F3E98" }}>
                                                The Role of Network Consultation
                                                <br />
                                            </div>
                                        </h2>
                                        <div className="cmp-teaser__description">
                                            <p className='text-dark fs-6' >
                                                With greater connectivity comes more operational and smart
                                                product vulnerability. Turn infrastructure security into a
                                                business enabler with seamless cyber-physical protection
                                                for a safer, smarter future.
                                            </p>
                                        </div>
                                        <div className="cmp-teaser__action-">
                                            <div
                                                className="cmp-teaser__action-background"
                                                data-style="background-color:#fff; "
                                                style={{
                                                    backgroundColor: '#fff',
                                                    color: "#000"
                                                }}
                                            >

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cmp-teaser__image">
                                    <img src="/icons (5).png" />
                                </div>
                            </div>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: '.cmp-teaser--1828042053 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                                }}
                                data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_712145262"
                            />
                        </div>
                        <div className="teaser teaser--full-size teaser--image-left teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                            <div className="cmp-teaser cmp-teaser--971658057  cmp-teaser--show-action  ">
                                <div
                                    className="cmp-teaser__content"
                                    style={{ background: "#E1E0E0" }}
                                >
                                    <div className="cmp-teaser__content-wrapper">
                                        <h2 className="cmp-teaser__title  text-dark ">
                                            <div className="cmp-teaser__title fw-bold fs-3" style={{ color: "#7F3E98" }}>
                                                Cybersecurity Posture
                                                <br />
                                            </div>
                                        </h2>  <br />
                                        <div className="cmp-teaser__description">
                                            <p className='text-dark'>
                                                How Network Consultation Can Improve Cybersecurity Posture
                                                the role of network consultants in identifying vulnerabilities, implementing security measures, and improving overall network defense against cyber threats.

                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="cmp-teaser__image">
                                    <img src="/icons (1).png" />
                                </div>
                            </div>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: '.cmp-teaser--971658057 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                                }}
                                data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1027937634"
                            />
                        </div>
                        <div className="teaser teaser--full-size teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                            <div className="cmp-teaser cmp-teaser--13409574  cmp-teaser--show-action  ">
                                <div
                                    className="cmp-teaser__content"
                                    style={{ background: "#E1E0E0" }}
                                >
                                    <div className="cmp-teaser__content-wrapper">
                                        <h2 className="cmp-teaser__title  text-dark ">
                                            <div className="cmp-teaser__title fw-bold fs-3" style={{ color: "#7F3E98" }}>
                                                Educational Institutions
                                            </div>
                                        </h2>  <br />
                                        <div className="cmp-teaser__description">
                                            <p className='text-dark' >
                                                Why Educational Institutions Need Network Consultation for Enhanced Learning Experiences
                                                educational institutions can benefit from network consultation to design scalable, secure networks that support online learning and administrative functions.

                                            </p>
                                            <p />
                                        </div>
                                        <div className="cmp-teaser__action-">
                                            <div
                                                className="cmp-teaser__action-background"
                                                data-style="background-color:#fff; "
                                                style={{
                                                    backgroundColor: '#fff',
                                                    color: "#000"
                                                }}
                                            >

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cmp-teaser__image">
                                    <img src="/icons (3).png" />
                                </div>
                            </div>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: '.cmp-teaser--13409574 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                                }}
                                data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1621792052"
                            />
                        </div>
                        <div className="teaser teaser--full-size teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                            <div className="cmp-teaser cmp-teaser--1606096698  cmp-teaser--show-action  ">
                                <div
                                    className="cmp-teaser__content"
                                    style={{ background: "#E1E0E0" }}
                                >
                                    <div className="cmp-teaser__content-wrapper">
                                        <h2 className="cmp-teaser__title  text-dark ">
                                            <div className="cmp-teaser__title fw-bold fs-3" style={{ color: "#7F3E98" }}>
                                                Bandwidth Management

                                                <br />
                                            </div>
                                        </h2>  <br />
                                        <div className="cmp-teaser__description">
                                            <p className='text-dark'>
                                                Optimizing Network Performance: The Role of Network Consultation in Bandwidth Management
                                                network consultants helps businesses optimize bandwidth usage, reduce latency, and improve overall network performance.

                                            </p>
                                            <p>
                                                <br />
                                            </p>
                                            <p />
                                        </div>

                                    </div>
                                </div>
                                <div className="cmp-teaser__image">
                                    <img src="/icons (4).png" />
                                </div>
                            </div>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: '.cmp-teaser--1606096698 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                                }}
                                data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1404454339"
                            />
                        </div>
                        <div className="teaser teaser--full-size teaser--image-left teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                            <div className="cmp-teaser cmp-teaser--786989466  cmp-teaser--show-action  ">
                                <div
                                    className="cmp-teaser__content"
                                    style={{ background: "#E1E0E0" }}
                                >
                                    <div className="cmp-teaser__content-wrapper">
                                        <h2 className="cmp-teaser__title  text-dark ">
                                            <div className="cmp-teaser__title fw-bold fs-3" style={{ color: "#7F3E98" }}>
                                                Ensuring Business Continuity and Disaster Recovery
                                                <br />
                                            </div>
                                        </h2>  <br />
                                        <div className="cmp-teaser__description">
                                            <p className='text-dark'>
                                                The Role of Network Consultation in Ensuring Business Continuity and Disaster Recovery
                                                Experts assist businesses in developing reliable business continuity plans and implementing disaster recovery strategies to minimize downtime.

                                            </p>
                                            <p />
                                        </div>
                                        <div className="cmp-teaser__action-">
                                            <div
                                                className="cmp-teaser__action-background"
                                                data-style="background-color:#fff; "
                                                style={{
                                                    backgroundColor: '#fff',
                                                    color: "#000"
                                                }}
                                            >

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cmp-teaser__image">
                                    <img src="/icons (6).png" />
                                </div>
                            </div>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: '.cmp-teaser--786989466 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                                }}
                                data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284"
                            />
                        </div>
                        <div className="teaser teaser--full-size teaser--image-left teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                            <div className="cmp-teaser cmp-teaser--1182465507  cmp-teaser--show-action  ">
                                <div
                                    className="cmp-teaser__content"
                                    style={{ background: "#E1E0E0" }}
                                >
                                    <div className="cmp-teaser__content-wrapper">
                                        <h2 className="cmp-teaser__title  text-dark ">
                                            <div className="cmp-teaser__title fw-bold fs-3" style={{ color: "#7F3E98" }}>
                                                Cloud Migration Strategies
                                                <br />
                                            </div>
                                        </h2>  <br />
                                        <div className="cmp-teaser__description">
                                            <p className='text-dark'>
                                                Cloud Migration Strategies: How Network Consultants Help Businesses Transition Seamlessly
                                                the effects of organizations in moving their operations to the cloud, ensuring minimal disruption and maximum efficiency.
                                            </p>
                                            <p />
                                        </div>
                                        <div className="cmp-teaser__action-">
                                            <div
                                                className="cmp-teaser__action-background"
                                                data-style="background-color:#fff; "
                                                style={{
                                                    backgroundColor: '#fff',
                                                    color: "#000"
                                                }}
                                            >

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cmp-teaser__image">
                                    <img src="/icons (2).png" />
                                </div>
                            </div>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: '.cmp-teaser--1182465507 .cmp-teaser__content{color:rgb(255,255,255);background-color:#fff;}'
                                }}
                                data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1056046798"
                            />
                        </div>
                    </div>


                </div>
            </div> */}


            <Demo />
            {/*         
            <Demo2 /> */}
        </div >
    )
}

export default Consulting